import { TypedDocumentNode, gql } from '@apollo/client';
import { Navigate, Outlet, generatePath } from 'react-router-dom';

import LoadingIndicator from '@sorare/core/src/atoms/loader/LoadingIndicator';
import {
  MLB_COMPOSE_TEAM_LEADERBOARDSLUG_LINEUPID,
  MLB_PLAY_PRO,
} from '@sorare/core/src/constants/__generated__/routes';
import idFromObject from '@sorare/core/src/gql/idFromObject';
import useQuery from '@sorare/core/src/hooks/graphql/useQuery';
import { useTypedParams } from '@sorare/core/src/lib/routing';

import { RequireAuth } from 'components/RequireAuth';

import {
  MLBComposeTeamLayoutQuery,
  MLBComposeTeamLayoutQueryVariables,
} from './__generated__/layout.graphql';
import { LayoutParams } from './__generated__/routeParams';

const COMPOSE_TEAM_LAYOUT_QUERY = gql`
  query MLBComposeTeamLayoutQuery($leaderboardSlug: String!) {
    so5 {
      so5Leaderboard(slug: $leaderboardSlug) {
        slug
        teamsCap
        canCompose {
          value
        }
        so5League {
          slug
          name
        }
        so5Fixture {
          slug
          mySo5LineupsPaginated(
            so5LeaderboardSlug: $leaderboardSlug
            first: 1
          ) {
            nodes {
              id
            }
          }
        }
        composeBuy
      }
    }
  }
` as TypedDocumentNode<
  MLBComposeTeamLayoutQuery,
  MLBComposeTeamLayoutQueryVariables
>;

const Layout = () => {
  const { leaderboardSlug, lineupId } = useTypedParams<LayoutParams>();
  const { data: leaderboardData, loading } = useQuery(
    COMPOSE_TEAM_LAYOUT_QUERY,
    {
      variables: {
        leaderboardSlug,
      },
      skip: !leaderboardSlug,
    }
  );

  const { so5Leaderboard } = leaderboardData?.so5 || {};
  const { so5Fixture, teamsCap, composeBuy } = so5Leaderboard || {};
  const { mySo5LineupsPaginated } = so5Fixture || {};
  const firstValidLineupId = idFromObject(
    mySo5LineupsPaginated?.nodes?.[0]?.id
  );
  const navigateToValidLineup =
    firstValidLineupId && firstValidLineupId !== lineupId && teamsCap === 1;

  if (!leaderboardData && loading) {
    return <LoadingIndicator fullScreen />;
  }

  if (navigateToValidLineup) {
    return (
      <Navigate
        to={generatePath(MLB_COMPOSE_TEAM_LEADERBOARDSLUG_LINEUPID, {
          leaderboardSlug,
          lineupId: firstValidLineupId,
        })}
        replace
      />
    );
  }

  if (!leaderboardData?.so5.so5Leaderboard.canCompose.value && !composeBuy) {
    return <Navigate to={MLB_PLAY_PRO} replace />;
  }
  return (
    <RequireAuth>
      <Outlet />
    </RequireAuth>
  );
};

export default Layout;
