import { ReactNode, createContext, useContext } from 'react';

export type OnboardingHighlightName =
  | 'RivalsDraftCard'
  | 'RivalsDraftCost'
  | 'RivalsDraftForm'
  | 'RivalsDraftBudget'
  | 'RivalsTactics'
  | 'ProSchedule'
  | 'ProLiveOrPastFixture'
  | 'ProUpcomingFixture'
  | 'HomeRivalsBlock'
  | 'ArenaPlayButton'
  | 'ClaimFirstBoxMission'
  | 'RankDiamond'
  | 'DivDiamond'
  | 'RepDiamond'
  | 'PlayBanners';

export type OnboardingHighlightConfig = {
  circle?: boolean;
  padding?: number;
  borderRadius?: number;
  blur?: number;
  border?: boolean;
  onClick?: () => void;
  onBackdropClick?: () => void;
  renderSibling?: (rect: {
    top: number;
    left: number;
    right: number;
    bottom: number;
  }) => ReactNode;
};
export type MeasureHighlightCallback = () => DOMRect | null;

export type CurrentHighlight = {
  name: OnboardingHighlightName;
  measureCallback?: MeasureHighlightCallback;
  config?: OnboardingHighlightConfig;
};
export type OnboardingHighlightContext = {
  registerHighlight: (
    name: OnboardingHighlightName,
    measureCallback: MeasureHighlightCallback
  ) => void;
  setCurrentHighlights: (
    highlights:
      | { name: OnboardingHighlightName; config?: OnboardingHighlightConfig }[]
      | null
  ) => void;
  currentHighlights: CurrentHighlight[] | null;
  clearHighlights: () => void;
};

export const onboardigHighlightContext =
  createContext<OnboardingHighlightContext | null>(null);

export const useOnboardingHighLightContext = () =>
  useContext(onboardigHighlightContext)!;

export default onboardigHighlightContext;
