import { FormattedDate } from 'react-intl';

type Props = {
  startDate: Date;
  endDate: Date;
};
export const SeasonInterval = ({ startDate, endDate }: Props) => {
  return (
    <>
      <FormattedDate value={startDate} month="short" day="numeric" />
      -
      <FormattedDate value={endDate} day="numeric" />
    </>
  );
};
