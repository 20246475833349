import { useIntl } from 'react-intl';

import { fantasy } from 'lib/glossary';

import { EmptyState, Props as EmptyStateProps } from '..';

export const EmptyGames = (
  props: Partial<EmptyStateProps> & { upcoming?: boolean }
) => {
  const { formatMessage } = useIntl();
  const { upcoming = true, ctaProps } = props;

  const defaultProps = upcoming
    ? {
        title: formatMessage({
          id: 'emptyState.games.title',
          defaultMessage: 'No upcoming games',
        }),
        message: formatMessage({
          id: 'emptyState.games.message',
          defaultMessage:
            "Games will appear here when you've registered lineups in them",
        }),
        ctaProps: { children: formatMessage(fantasy.composeLineup) },
      }
    : {
        title: formatMessage({
          id: 'emptyState.past.games.title',
          defaultMessage: 'No games',
        }),
        message: formatMessage({
          id: 'emptyState.past.games.message',
          defaultMessage: 'Games appear here when you registered lineups',
        }),
      };
  return (
    <EmptyState
      {...defaultProps}
      {...props}
      ctaProps={
        defaultProps.ctaProps || ctaProps
          ? {
              ...(defaultProps.ctaProps || {}),
              ...(ctaProps || {}),
            }
          : undefined
      }
    />
  );
};
