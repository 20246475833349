import { ReactNode, Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import LoadingIndicator from '@sorare/core/src/atoms/loader/LoadingIndicator';
import {
  LANDING_WILDCARD,
  OAUTH_AUTORIZE,
  RIVALS_LANDING,
} from '@sorare/core/src/constants/routes';
import useGetSplat from '@sorare/core/src/hooks/useGetSplat';
import { useIsMobileApp } from '@sorare/core/src/hooks/useIsMobileApp';
import { lazy } from '@sorare/core/src/lib/retry';
import RequireAuth from '@sorare/core/src/routing/RequireAuth';

import { RivalsLanding } from '@sorare/football/src/pages/RivalsLanding';

const WalletDrawer = lazy(
  async () => import('@sorare/core/src/components/wallet/WalletDrawer')
);
const BlockchainProvider = lazy(
  async () => import('@sorare/core/src/contexts/blockchain/Provider')
);

const OAuth = lazy(async () => import('@sorare/shared-pages/src/OAuth'));

const LandingPages = lazy(async () => import('@sorare/landing-pages/src/main'));

export const BlockchainProviders = ({ children }: { children: ReactNode }) => {
  return (
    <Suspense fallback={<LoadingIndicator fullScreen />}>
      <BlockchainProvider>
        {children}
        <WalletDrawer />
      </BlockchainProvider>
    </Suspense>
  );
};

const MobileAppLoadedEventEmiter = () => {
  const { isAndroidApp, postMessage } = useIsMobileApp();

  useEffect(() => {
    if (!isAndroidApp) return;
    postMessage('loaded');
  }, [isAndroidApp, postMessage]);
  return null;
};

const SportsRouter = () => {
  const location = useLocation();
  const getSplat = useGetSplat();

  return (
    <>
      <MobileAppLoadedEventEmiter />
      <Routes>
        {/* Force all Football routes to be prefixed with /football now */}
        <Route
          path={'/*'}
          element={
            <Navigate
              to={getSplat('/*', '/football/*') + location.search}
              replace
            />
          }
        />
      </Routes>
    </>
  );
};

// Here we are trying to include the blockchain providers only when needed, and only once
// It is not needed on the Logged out Landing page
// It is not needed on the Logged in Landing page
// It is needed on the logged out Football Player page (for instance)
export const AppRouter = () => {
  return (
    <Routes>
      <Route path={RIVALS_LANDING} element={<RivalsLanding />} />
      <Route path={LANDING_WILDCARD} element={<LandingPages />} />
      <Route
        path={OAUTH_AUTORIZE}
        element={
          <RequireAuth>
            <OAuth />
          </RequireAuth>
        }
      />
      <Route path={'/*'} element={<SportsRouter />} />
    </Routes>
  );
};
