import { RefObject, useEffect, useRef } from 'react';

const useScrollToIndex = ({
  indexToScroll,
  wrapperWidth,
  scrollContainerRef,
  position,
}: {
  indexToScroll: number | undefined;
  wrapperWidth?: number;
  scrollContainerRef: RefObject<HTMLElement | null>;
  position?: 'start' | 'center';
}) => {
  const isFirstScroll = useRef<boolean>(true);

  useEffect(() => {
    if (scrollContainerRef?.current && typeof indexToScroll === 'number') {
      /**
       * Prevent scrolling & ensure active item is displayed
       * on top on the first render
       */
      const behavior = isFirstScroll.current ? 'instant' : 'smooth';

      const index = Math.max(
        0,
        Math.min(
          indexToScroll,
          scrollContainerRef.current.childNodes.length - 1
        )
      );
      const { offsetLeft: itemOffsetLeft, clientWidth: itemWidth } =
        scrollContainerRef.current.childNodes[index] as HTMLDivElement;
      const left =
        position === 'center' && typeof wrapperWidth === 'number'
          ? itemOffsetLeft - wrapperWidth / 2 + itemWidth / 2
          : itemOffsetLeft;

      scrollContainerRef.current.scrollTo({
        left,
        behavior,
      });
      isFirstScroll.current = false;
    }
  }, [wrapperWidth, indexToScroll, scrollContainerRef, position]);
};

export default useScrollToIndex;
