import { TypedDocumentNode, gql } from '@apollo/client';
import { faClock } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { GameStatus } from '@sorare/core/src/__generated__/globalTypes';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { BodyS, LabelS } from '@sorare/core/src/atoms/typography';
import { NBA_TEAM } from '@sorare/core/src/constants/routes';
import { withFragments } from '@sorare/core/src/gql';
import { playerGameStatusLabels } from '@sorare/core/src/lib/glossary';
import {
  THRESHOLD_COLORS,
  findThreshold,
} from '@sorare/core/src/lib/usSportsThresholds';

import { GenericGame } from '@sorare/gameplay/src/components/seo/Games/Game';

import {
  nbaGameToGenericGame_NBAGame,
  nbaPlayerGameStatsToGenericGame_NBAPlayerGameStats,
} from './__generated__/utils.graphql';

export const nbaGameToGenericGame = withFragments(
  ({
    game,
    onClick,
  }: {
    game: nbaGameToGenericGame_NBAGame;
    onClick?: (id: string) => void;
  }): GenericGame => {
    const matchLiveOrPlayed = [GameStatus.played, GameStatus.playing].includes(
      game.status
    );

    return {
      ...game,
      date: game.startDate,
      // Reverse team orders to comply with US standards
      awayTeam: {
        ...game.homeTeam,
        pictureUrl: game.homeTeam.svgUrl,
        to: generatePath(NBA_TEAM, { slug: game.homeTeam.slug }),
      },
      homeTeam: {
        ...game.awayTeam,
        pictureUrl: game.awayTeam.svgUrl,
        to: generatePath(NBA_TEAM, { slug: game.awayTeam.slug }),
      },
      status: game.status,
      onClick: matchLiveOrPlayed ? () => onClick?.(game.id) : undefined,
    };
  },
  {
    game: gql`
      fragment nbaGameToGenericGame_NBAGame on NBAGame {
        id
        startDate
        status
        homeTeam {
          slug
          name
          svgUrl
        }
        homeScore
        awayTeam {
          slug
          name
          svgUrl
        }
        awayScore
        gameWeek
      }
    ` as TypedDocumentNode<nbaGameToGenericGame_NBAGame>,
  }
);

export const nbaPlayerGameStatsToGenericGame = withFragments(
  ({
    playerGameStats,
    onClick,
  }: {
    playerGameStats: nbaPlayerGameStatsToGenericGame_NBAPlayerGameStats;
    onClick?: (id: string) => void;
  }): GenericGame => {
    const { game, playedInGame, score, detailedStats } = playerGameStats;
    const matchLiveOrPlayed = [GameStatus.played, GameStatus.playing].includes(
      game.status
    );

    return {
      ...nbaGameToGenericGame({ game, onClick }),
      ...(matchLiveOrPlayed
        ? {
            playerScore: (
              <BodyS
                as="p"
                bold
                color={
                  !playedInGame
                    ? 'var(--c-neutral-600)'
                    : THRESHOLD_COLORS[findThreshold(score || 0)]
                }
              >
                {!playedInGame ? (
                  <FormattedMessage
                    {...playerGameStatusLabels.did_not_play_short}
                  />
                ) : (
                  <FormattedNumber
                    value={score || 0}
                    maximumFractionDigits={0}
                  />
                )}
              </BodyS>
            ),
            playerStats: playedInGame && detailedStats && (
              <Horizontal gap={1}>
                <Horizontal gap={0.5}>
                  <FontAwesomeIcon
                    icon={faClock}
                    color="var(--c-neutral-600)"
                    size="xs"
                  />
                  <LabelS as="p">
                    <FormattedNumber
                      value={detailedStats.secondsPlayed / 60}
                      maximumFractionDigits={0}
                    />
                  </LabelS>
                </Horizontal>
              </Horizontal>
            ),
          }
        : {}),
    };
  },
  {
    nbaPlayerGameStats: gql`
      fragment nbaPlayerGameStatsToGenericGame_NBAPlayerGameStats on NBAPlayerGameStats {
        score
        playedInGame
        game {
          id
          ...nbaGameToGenericGame_NBAGame
        }
        detailedStats {
          secondsPlayed
        }
      }
      ${nbaGameToGenericGame.fragments.game}
    ` as TypedDocumentNode<nbaPlayerGameStatsToGenericGame_NBAPlayerGameStats>,
  }
);
