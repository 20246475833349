import { css } from 'styled-components';

export const lineupLayout = css`
  position: relative;
  display: flex;
  isolation: isolate;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  /*
   * Place players according to the following terrain
   * 4_Fw  5_Extra
   *   ::before
   * 2_Df  3_Md
   *    1_Gk
   */

  /** Line break */
  &::before {
    content: '';
    width: 100%;
    order: 1;
  }
  > * {
    &:nth-child(1) {
      order: 3;
      --y: 25%;
      transform: translateY(var(--y));
    }

    &:nth-child(2) {
      order: 2;
    }
    &:nth-child(3) {
      order: 4;
    }
  }
`;
