import { Suspense } from 'react';

import { AppLayout } from '@sorare/core/src/components/navigation/AppLayout';
import useFeatureFlags from '@sorare/core/src/hooks/useFeatureFlags';
import { lazy } from '@sorare/core/src/lib/retry';

const KylianmbappeLanding = lazy(
  async () => import('@sorare/football/src/pages/kylianmbappe/page')
);
const FootballLanding = lazy(
  async () => import('@sorare/football/src/pages/page')
);

const Landing = () => {
  const {
    flags: { useMbappeTakeOver = false },
  } = useFeatureFlags();

  return (
    <AppLayout>
      <Suspense>
        {useMbappeTakeOver ? (
          <KylianmbappeLanding />
        ) : (
          <FootballLanding isRoot />
        )}
      </Suspense>
    </AppLayout>
  );
};

export default Landing;
