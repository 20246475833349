import classNames from 'classnames';
import { Fragment, ReactNode, useLayoutEffect, useRef } from 'react';
import { useDebounce } from 'react-use';
import styled from 'styled-components';

import { WithScrollButtons } from 'atoms/navigation/WithScrollButtons';
import useIsVisibleInViewport from 'hooks/ui/useIsVisibleInViewport';
import usePrevious from 'hooks/usePrevious';
import { hideScrollbar } from 'style/utils';

import { WithSlug } from './types';
import useScrollToItem from './useScrollToItem';

const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  gap: var(--unit);
  ${hideScrollbar}
  & > * {
    flex: 1;
  }
  padding: 0 var(--container-padding);
  margin: 0 calc(-1 * var(--container-padding));

  &.withMask {
    --mask-size: calc(5 * var(--unit));
    padding-inline: var(--mask-size);
    scroll-padding-inline: var(--mask-size);
    margin-inline: calc(-1 * var(--mask-size));
    mask-image: linear-gradient(
      to right,
      transparent,
      white var(--mask-size),
      white calc(100% - var(--mask-size)),
      transparent
    );
  }
`;

type Props<T extends WithSlug> = {
  renderItem: (args: { item: T; index: number }) => ReactNode;
  renderPlaceholder: () => ReactNode | ReactNode[];
  items: T[];
  value: T;
  loadMore?: () => void;
  loadMoreInFuture?: () => void;
  withMask?: boolean;
};

const useKeepScrollOnItemsChange = (items: any[]) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const previousItems = usePrevious(items);

  useLayoutEffect(() => {
    if (items[0] !== previousItems?.[0]) {
      const previousFirstItemIndex = items.findIndex(
        item => item === previousItems?.[0]
      );

      if (previousFirstItemIndex !== -1 && ref.current) {
        const prevFirstItemOffset = (
          ref.current.childNodes[previousFirstItemIndex] as HTMLDivElement
        )?.offsetLeft;
        const currentFirstItemOffset = (
          ref.current.childNodes[0] as HTMLDivElement
        )?.offsetLeft;
        ref.current?.scrollTo({
          left:
            ref.current.scrollLeft +
            prevFirstItemOffset -
            currentFirstItemOffset,
          behavior: 'instant',
        });
      }
    }
  }, [previousItems, items]);
  return ref;
};

const Placeholder = ({
  children,
  onVisible,
}: {
  children: ReactNode;
  onVisible?: () => void;
}) => {
  const { handleRef, isVisible } = useIsVisibleInViewport();
  useDebounce(
    () => {
      if (isVisible) {
        onVisible?.();
      }
    },
    100,
    [isVisible, onVisible]
  );

  return <div ref={handleRef}>{children}</div>;
};

export const DateOrFixturePicker = <T extends WithSlug>({
  loadMore,
  loadMoreInFuture,
  renderPlaceholder,
  renderItem,
  items,
  value,
  withMask,
}: Props<T>) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const scrollContainerRef = useKeepScrollOnItemsChange(items);
  useScrollToItem({
    items,
    value,
    scrollContainerRef,
    wrapperRef,
  });

  return (
    <WithScrollButtons
      wrapperRef={wrapperRef}
      scrollContainerRef={scrollContainerRef}
    >
      <ScrollWrapper
        ref={scrollContainerRef}
        className={classNames({ withMask })}
      >
        <Placeholder onVisible={loadMoreInFuture}>
          {loadMoreInFuture && renderPlaceholder()}
        </Placeholder>
        {items.map((item, index) => (
          <Fragment key={item.slug}>{renderItem({ item, index })}</Fragment>
        ))}
        <Placeholder onVisible={loadMore}>
          {loadMore && renderPlaceholder()}
        </Placeholder>
      </ScrollWrapper>
    </WithScrollButtons>
  );
};
