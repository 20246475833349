import { faUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Rarity } from '@sorare/core/src/__generated__/globalTypes';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { LiveEvent } from '@sorare/core/src/atoms/icons/LiveEvent';
import { NewScarcityIcon } from '@sorare/core/src/atoms/icons/ScarcityIcon';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { BodyS, LabelM, LabelS } from '@sorare/core/src/atoms/typography';
import { useIntlContext } from '@sorare/core/src/contexts/intl';
import { fantasy } from '@sorare/core/src/lib/glossary';
import { shardsIconUrl } from '@sorare/core/src/lib/rewards';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

import { earlyAccessEvents, formatTimeLeft } from 'lib/earlyAccess';

import mbappe from './assets/mbappe.png';
import plus from './assets/plus.svg';

const Root = styled(Vertical).attrs({ center: true, gap: 0 })`
  text-align: center;
  border-radius: var(--double-unit);
  background: linear-gradient(
    84deg,
    rgba(248, 211, 218, 0.12) 0%,
    rgba(179, 169, 244, 0.12) 28.32%,
    rgba(251, 233, 251, 0.12) 54.01%,
    rgba(79, 148, 253, 0.12) 100%
  );
`;

const Header = styled(Horizontal).attrs({ center: true })`
  padding: var(--half-unit) 0;
  color: var(--c-neutral-100);
  width: 100%;
  border-top-left-radius: var(--double-unit);
  border-top-right-radius: var(--double-unit);
  background: linear-gradient(
    84deg,
    #f8d3da 0%,
    #b3a9f4 28.32%,
    #fbe9fb 54.01%,
    #4f94fd 100%
  );
`;

const Content = styled(Horizontal).attrs({ gap: 2 })`
  width: 100%;
  padding: var(--double-unit) var(--unit);

  @media ${tabletAndAbove} {
    padding: var(--double-unit);
  }
`;

const Footer = styled(Horizontal).attrs({ center: true })`
  position: relative;
  width: 100%;
  border-top: 1px solid rgba(var(--c-rgb-neutral-1000), 0.08);
  padding: var(--double-unit) var(--unit);
  flex-wrap: wrap;
  @media ${tabletAndAbove} {
    padding: var(--double-unit);
  }
`;

const Time = styled(Horizontal)`
  padding: 2px var(--unit);
  border-radius: var(--unit);
  background: rgba(16, 16, 16, 0.19);
`;

const ItemRoot = styled(Vertical)`
  flex: 1;
  padding: var(--unit);
  border-radius: var(--unit);
  background: rgba(var(--c-rgb-neutral-1000), 0.04);
  align-items: center;
  align-self: stretch;

  @media ${tabletAndAbove} {
    flex-direction: row;
    text-align: left;
  }
`;

const FooterItem = styled(Horizontal)`
  text-align: left;
  padding: 2px var(--half-unit);
  border-radius: var(--half-unit);
  background: rgba(var(--c-rgb-neutral-1000), 0.04);
`;

const ImgContainer = styled(Horizontal).attrs({ gap: 0 })`
  height: calc(5 * var(--unit));
  width: calc(5 * var(--unit));
  border-radius: var(--unit);
  background: rgba(var(--c-rgb-neutral-100), 0.16);
  justify-content: center;

  img {
    max-width: 70%;
    max-height: 70%;
  }
`;

const PlusIcon = styled.img`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Terms = styled(LabelS)`
  align-self: flex-end;
`;

const BlogLink = styled.a`
  text-decoration: underline;
  color: var(--c-neutral-600);
`;

const Container = styled(Vertical)`
  width: 100%;
`;

const Item = ({
  img,
  title,
  subtitle,
}: {
  img: ReactNode;
  title: ReactNode;
  subtitle: ReactNode;
}) => {
  return (
    <ItemRoot>
      <ImgContainer>{img}</ImgContainer>
      <Vertical gap={0}>
        <LabelM>{title}</LabelM>
        <LabelS color="var(--c-neutral-600)">{subtitle}</LabelS>
      </Vertical>
    </ItemRoot>
  );
};
type Props = {
  event?: (typeof earlyAccessEvents)[number];
  rarities: Rarity[];
};

export const EarlyAccessSpecialOfferBannerContent = ({
  event,
  rarities,
}: Props) => {
  const { formatMessage } = useIntlContext();

  return (
    <Container>
      <Root>
        <Header>
          {event && (
            <Time>
              <LiveEvent />
              <LabelS bold>
                {formatTimeLeft(event.endDate, formatMessage)}
              </LabelS>
            </Time>
          )}
          <LabelS bold>
            <FormattedMessage
              id="EarlyAccessSpecialOfferBanner.specialOffer"
              defaultMessage="For each purchase, win"
            />
          </LabelS>
        </Header>
        <Content>
          <Horizontal grow>
            <Item
              img={rarities.map(rarity => (
                <NewScarcityIcon rarity={rarity} key={rarity} />
              ))}
              title={
                <FormattedMessage
                  id="EarlyAccessSpecialOfferBanner.bonusCard"
                  defaultMessage="1 Bonus Card"
                />
              }
              subtitle={
                <FormattedMessage
                  id="EarlyAccessSpecialOfferBanner.sameClub"
                  defaultMessage="from the same club *"
                />
              }
            />
            <BodyS color="var(--c-neutral-600)" uppercase>
              <FormattedMessage
                id="EarlyAccessSpecialOfferBanner.or"
                defaultMessage="or"
              />
            </BodyS>
            <Item
              img={rarities.map(rarity => (
                <img key={rarity} alt={rarity} src={shardsIconUrl(rarity)} />
              ))}
              title={<FormattedMessage {...fantasy.essenceShort} />}
              subtitle={
                <FormattedMessage
                  id="EarlyAccessSpecialOfferBanner.oneToFive"
                  defaultMessage="1 to 5"
                />
              }
            />
          </Horizontal>
        </Content>
        <Footer>
          <PlusIcon src={plus} />
          <LabelM>
            <FormattedMessage
              id="EarlyAccessSpecialOfferBanner.1of3"
              defaultMessage="A chance to win 1 special"
            />
          </LabelM>
          <Horizontal gap={0}>
            <FooterItem>
              <img
                src={mbappe}
                width="12px"
                height="16"
                alt="mbappe rare card"
              />
              <LabelM>
                <FormattedMessage
                  id="EarlyAccessSpecialOfferBanner.mbappeRareCard"
                  defaultMessage="Mbappé Rare Card"
                />
              </LabelM>
            </FooterItem>
            *
          </Horizontal>
        </Footer>
      </Root>
      <Terms color="var(--c-neutral-600)">
        <BlogLink
          href="/blog/early-access-launch-win-free-cards"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faUpRightFromSquare} size="xs" />{' '}
          <FormattedMessage
            id="EarlyAccessSpecialOfferBanner.moreDetails"
            defaultMessage="More details"
          />
        </BlogLink>
        {' & '}
        <BlogLink
          href="/blog/early-access-celebration-mbappe-event-terms"
          target="_blank"
          rel="noreferrer"
        >
          <FormattedMessage
            id="EarlyAccessSpecialOfferBanner.terms"
            defaultMessage="*T&Cs apply"
          />
        </BlogLink>
      </Terms>
    </Container>
  );
};
