import { TypedDocumentNode, gql } from '@apollo/client';

import { FootballRivalsOutcome } from '@sorare/core/src/__generated__/globalTypes';
import { withFragments } from '@sorare/core/src/gql';
import { partition } from '@sorare/core/src/lib/arrays';
import { fantasy } from '@sorare/core/src/lib/glossary';

import {
  findLeaderboardConfigByDivisionId_footballRivalsDivisionLeaderboardConfig,
  findLeaderboardConfigByDivisionId_footballRivalsDivisionSquadLeaderboardConfig,
  hasJoinedSquad_footballRivalsCurrentManager,
  splitLeaguesByFavorite_footballRivalsLeagueGame,
} from './__generated__/rivals.graphql';

export const splitLeaguesByFavorite = withFragments(
  <T extends splitLeaguesByFavorite_footballRivalsLeagueGame>(
    rivalsLeagueGames: T[]
  ) => {
    return partition(
      rivalsLeagueGames,
      ({ competition }) => !!competition.currentUserSubscription?.slug
    );
  },
  {
    footballRivalsLeagueGame: gql`
      fragment splitLeaguesByFavorite_footballRivalsLeagueGame on FootballRivalsLeagueGame {
        competition {
          slug
          currentUserSubscription {
            slug
          }
        }
      }
    ` as TypedDocumentNode<splitLeaguesByFavorite_footballRivalsLeagueGame>,
  }
);

export const getOutcome = ({
  managerScore = 0,
  opponentScore = 0,
}: {
  managerScore?: number;
  opponentScore?: number;
}) => {
  if (managerScore > opponentScore) {
    return FootballRivalsOutcome.WIN;
  }
  if (managerScore < opponentScore) {
    return FootballRivalsOutcome.LOSE;
  }
  return FootballRivalsOutcome.DRAW;
};

export const getOutcomeMessage = (outcome: FootballRivalsOutcome) => {
  if (outcome === FootballRivalsOutcome.WIN) {
    return fantasy.win;
  }
  if (outcome === FootballRivalsOutcome.LOSE) {
    return fantasy.loss;
  }
  return fantasy.draw;
};

export const findLeaderboardConfigByDivisionId = withFragments(
  <
    T extends
      | findLeaderboardConfigByDivisionId_footballRivalsDivisionLeaderboardConfig
      | findLeaderboardConfigByDivisionId_footballRivalsDivisionSquadLeaderboardConfig,
  >(
    leaderboardsConfig: T[],
    id?: string
  ) => {
    return leaderboardsConfig.find(
      ({ divisionConfig }) => divisionConfig.id === id
    );
  },
  {
    footballRivalsDivisionLeaderboardConfig: gql`
      fragment findLeaderboardConfigByDivisionId_footballRivalsDivisionLeaderboardConfig on FootballRivalsDivisionLeaderboardConfig {
        divisionConfig {
          id
        }
      }
    ` as TypedDocumentNode<findLeaderboardConfigByDivisionId_footballRivalsDivisionLeaderboardConfig>,
    footballRivalsDivisionSquadLeaderboardConfig: gql`
      fragment findLeaderboardConfigByDivisionId_footballRivalsDivisionSquadLeaderboardConfig on FootballRivalsDivisionSquadLeaderboardConfig {
        divisionConfig {
          id
        }
      }
    ` as TypedDocumentNode<findLeaderboardConfigByDivisionId_footballRivalsDivisionSquadLeaderboardConfig>,
  }
);

export const hasJoinedSquad = withFragments(
  (
    footballRivalscurrentManager?: Nullable<hasJoinedSquad_footballRivalsCurrentManager>
  ) => {
    return !!footballRivalscurrentManager?.squad;
  },
  {
    footballRivalsCurrentManager: gql`
      fragment hasJoinedSquad_footballRivalsCurrentManager on FootballRivalsCurrentManager {
        id
        squad {
          slug
        }
      }
    ` as TypedDocumentNode<hasJoinedSquad_footballRivalsCurrentManager>,
  }
);
