import { useMemo } from 'react';
import { matchPath } from 'react-router-dom';

import { Sport } from '__generated__/globalTypes';

import { API_ROOT } from '../config';
import {
  ACCEPT_INVITATION,
  CAREERS,
  CONFIRM_DEVICE,
  CONFIRM_EMAIL,
  COOKIE_POLICY,
  DEBUG_DEVICE,
  FOOTBALL_MARKET_INSTANT_BUY,
  FOOTBALL_MARKET_MANAGER_SALES,
  FOOTBALL_MARKET_MANAGER_SALES_PLAYERSLUG_RARITY,
  FOOTBALL_MARKET_NEW_SIGNINGS,
  FOOTBALL_MY_CLUB_SLUG,
  FOOTBALL_MY_CLUB_SLUG_HISTORY,
  GAME_RULES,
  INVITE,
  LANDING,
  LICENSED_PARTNERS,
  MLB,
  MLB_MARKET_INSTANT_BUY,
  MLB_MARKET_PRIMARY,
  MLB_MARKET_SECONDARY,
  MLB_MARKET_SECONDARY_PLAYERSLUG_RARITY,
  MLB_MY_CLUB_SLUG,
  MLB_MY_CLUB_SLUG_HISTORY,
  MOBILE_SIGN_UP,
  NBA_MY_CLUB_SLUG,
  NBA_MY_CLUB_SLUG_HISTORY,
  NBA_PLAY_PRO,
  PRESS,
  PRIVACY_POLICY,
  R_USERSLUG,
  SETTINGS,
  TERMS_AND_CONDITIONS,
} from './__generated__/routes';

export { INVITE } from './__generated__/routes';

export const FOOTBALL_PATH = '/football';
export const MLB_PATH = '/mlb';
export const NBA_PATH = '/nba';

export const DEEPLINK = '/deeplink';

export const MY_SORARE_HOME = '/my-sorare';
export const MY_SORARE_WILDCARD = `${MY_SORARE_HOME}/*`;
export const MY_SORARE_NEW = `${MY_SORARE_HOME}/new`;
export const MY_SORARE_AUCTIONS = `${MY_SORARE_HOME}/auctions`;
export const MY_SORARE_SALES = `${MY_SORARE_HOME}/sales`;
export const MY_SORARE_PURCHASES = `${MY_SORARE_HOME}/purchases`;
export const MY_SORARE_OFFERS_RECEIVED = `${MY_SORARE_HOME}/offers-received`;
export const MY_SORARE_MY_OFFER_RECEIVED = `${MY_SORARE_OFFERS_RECEIVED}/:id`;
export const MY_SORARE_OFFERS_SENT = `${MY_SORARE_HOME}/offers-sent`;
export const MY_SORARE_MY_OFFER_SENT = `${MY_SORARE_OFFERS_SENT}/:id`;
export const MY_SORARE_FOLLOWS = `${MY_SORARE_HOME}/follows`;
export const MY_SORARE_PLAYERS_NOTIFICATIONS = `${MY_SORARE_HOME}/players-notifications`;
export const MY_SORARE_TRANSACTIONS = `${MY_SORARE_HOME}/transactions`;

export const MY_AUCTIONS = '/my-auctions';
export const BID = '/bid';

export const ANY_SPORT_DRAFT = '/draft';
export const ANY_SPORT_MARKET = '/market';
export const ANY_SPORT_MARKET_NEW_SIGNINGS = `${ANY_SPORT_MARKET}/new-signings`;
export const ANY_SPORT_MARKET_INSTANT_BUY = `${ANY_SPORT_MARKET}/instant-buy`;
export const ANY_SPORT_MARKET_SECONDARY = `${ANY_SPORT_MARKET}/secondary`;
export const ANY_SPORT_CARD_SHOW = `/cards/:slug`;
export const ANY_SPORT_MY_CLUB = `/my-club/:slug`;
export const ANY_US_SPORTS_TEAM_SHOW = `/teams/:slug`;
export const ANY_SPORT_MY_CLUB_CHALLENGES = `/my-club/:slug/challenges`;
export const ANY_SPORT_PLAY = '/play';
export const ANY_SPORT_PLAY_REWARDS_FIXTURE = `${ANY_SPORT_PLAY}/rewards/:fixture`;
export const ANY_SPORT_PLAY_PRO = '/play/pro';
export const ANY_SPORT_PLAY_PRO_LINEUPS_ID = `${ANY_SPORT_PLAY_PRO}/lineups/:id`;
export const ANY_SPORT_MY_CLUB_COLLECTIONS = `/my-club/:slug/collections`;
export const ANY_SPORT_MY_CLUB_CARDS = `/my-club/:slug/cards`;

export const ANY_SPORT_MY_CLUB_SLUG_COLLECTIONS = `${ANY_SPORT_MY_CLUB}/collections`;
export const ANY_SPORT_MY_CLUB_SLUG_COLLECTIONS_COLLECTION = `${ANY_SPORT_MY_CLUB}/collections/:collectionSlug`;
export const ANY_SPORT_MY_CLUB_SLUG_COLLECTIONS_COLLECTION_LEADERBOARD = `${ANY_SPORT_MY_CLUB}/collections/:collectionSlug/leaderboard`;

export const ANY_SPORT_CLUB_SHOP = '/club-shop';
export const ANY_SPORT_CLUB_SHOP_INVENTORY = `${ANY_SPORT_CLUB_SHOP}/inventory`;
export const ANY_SPORT_REWARDS = '/rewards/:fixture/:league/:quality/:rarity';

export const ANY_SPORT_PLAY_PRO_FIXTURE = `${ANY_SPORT_PLAY_PRO}/:fixture`;
export const ANY_SPORT_PLAY_PRO_FIXTURE_ENTER = `${ANY_SPORT_PLAY_PRO_FIXTURE}/enter`;
export const ANY_SPORT_PLAY_PRO_FIXTURE_ENTER_SEASONALITY_COMPETITION = `${ANY_SPORT_PLAY_PRO_FIXTURE_ENTER}/:seasonality/:competition`;
export const ANY_SPORT_PLAY_PRO_FIXTURE_ENTER_SEASONALITY_COMPETITION_TRCK = `${ANY_SPORT_PLAY_PRO_FIXTURE_ENTER_SEASONALITY_COMPETITION}/:trck`;
export const ANY_SPORT_PLAY_PRO_FIXTURE_ENTER_SEASONALITY_COMPETITION_TRCK_GAMES = `${ANY_SPORT_PLAY_PRO_FIXTURE_ENTER_SEASONALITY_COMPETITION_TRCK}/games`;
export const ANY_SPORT_PLAY_PRO_FIXTURE_COMPETITION = `${ANY_SPORT_PLAY_PRO_FIXTURE}/:competition`;
export const ANY_SPORT_PLAY_PRO_FIXTURE_COMPETITION_CONTENDER_CONTENDER = `${ANY_SPORT_PLAY_PRO_FIXTURE_COMPETITION}/contender/:contender`;
export const ANY_SPORT_PLAY_PRO_FIXTURE_USERGROUP_USER = `${ANY_SPORT_PLAY_PRO_FIXTURE}/:userGroup/:user`;
export const ANY_SPORT_PLAY_PRO_MY_LINEUPS_LINEUP = `${ANY_SPORT_PLAY_PRO}/my-lineups/:lineup`;
export const ANY_SPORT_PLAY_PRO_LEADERBOARD_LEADERBOARD_PRIZE_POOL = `${ANY_SPORT_PLAY_PRO}/leaderboard/:leaderboard/prize-pool`;
export const ANY_SPORT_PLAY_PRO_LEADERBOARD_LEADERBOARD_PRIZE_POOL_CONTENDER = `${ANY_SPORT_PLAY_PRO_LEADERBOARD_LEADERBOARD_PRIZE_POOL}/:contender`;
export const ANY_SPORT_PLAY_PRO_LEADERBOARD_LEADERBOARD_RANKINGS = `${ANY_SPORT_PLAY_PRO}/leaderboard/:leaderboard/rankings`;
export const ANY_SPORT_PLAY_PRO_LEADERBOARD_LEADERBOARD_RANKINGS_CONTENDER = `${ANY_SPORT_PLAY_PRO_LEADERBOARD_LEADERBOARD_RANKINGS}/:contender`;
export const ANY_SPORT_COMPOSE_TEAM_LEADERBOARDSLUG =
  '/compose-team/:leaderboardSlug';
export const ANY_SPORT_COMPOSE_TEAM_LEADERBOARDSLUG_LINEUP_LINEUPID = `${ANY_SPORT_COMPOSE_TEAM_LEADERBOARDSLUG}/lineup/:lineupId`;
export const ANY_SPORT_COMPOSE_TEAM_LEADERBOARDSLUG_MANAGERTEAMID = `${ANY_SPORT_COMPOSE_TEAM_LEADERBOARDSLUG}/:managerTeamId`;

export const ANY_SPORT_PLAYERS_SLUG = '/players/:slug';
export const ANY_SPORT_PLAYERS_SLUG_CARDS = `${ANY_SPORT_PLAYERS_SLUG}/cards`;

export const ANY_SPORT_TEAMS_SLUG = '/teams/:slug';

export const LEGACY_PLAYER_SHOW = `/players/:slug`;
export const LEGACY_PLAYER_SHOW_CARDS = `/players/:slug/cards`;
export const LEGACY_BUNDLE_PAGE = '/starter-packs/:id';
export const LEGACY_LEAGUE_SHOW_CARDS = '/leagues/:slug/cards';
export const LEGACY_COUNTRY_SHOW = `/countries/:slug`;
export const LEGACY_BUNDLED_AUCTION = `/bundled-auctions/:id`;

export const BLOG_WILDCARD = '/blog/*';

export const HELP = '/help';
export const HELP_NEW_REQUEST = '/help#new-request';
export const HELP_WILDCARD = '/help/*';

export const FOOTBALL_CLUB_SHOW = `${FOOTBALL_PATH}/clubs/:slug`;
export const FOOTBALL_CLUB_SHOW_WILDCARD = `${FOOTBALL_CLUB_SHOW}/*`;
export const FOOTBALL_CLUB_SHOW_STANDINGS = `${FOOTBALL_CLUB_SHOW}/standings`;
export const FOOTBALL_CLUB_SHOW_FIXTURES = `${FOOTBALL_CLUB_SHOW}/fixtures`;
export const FOOTBALL_CLUB_SHOW_TEAM = `${FOOTBALL_CLUB_SHOW}/team`;
export const FOOTBALL_CLUB_SHOW_CARDS = `${FOOTBALL_CLUB_SHOW}/cards`;
export const FOOTBALL_CLUB_SHOW_SQUAD = `${FOOTBALL_CLUB_SHOW}/squad`;

export const FOOTBALL_LEAGUE_SHOW = `${FOOTBALL_PATH}/leagues/:slug`;
export const FOOTBALL_LEAGUE_SHOW_WILDCARD = `${FOOTBALL_LEAGUE_SHOW}/*`;
export const FOOTBALL_LEAGUE_SHOW_HOME = `${FOOTBALL_LEAGUE_SHOW}/home`;
export const FOOTBALL_LEAGUE_SHOW_FIXTURES = `${FOOTBALL_LEAGUE_SHOW}/fixtures`;
export const FOOTBALL_LEAGUE_SHOW_CARDS = `${FOOTBALL_LEAGUE_SHOW}/cards`;

export const FOOTBALL_COUNTRY_SHOW = `${FOOTBALL_PATH}/countries/:countryCode`;
export const FOOTBALL_COUNTRY_SHOW_WILDCARD = `${FOOTBALL_COUNTRY_SHOW}/*`;
export const FOOTBALL_COUNTRY_SHOW_CARDS = `${FOOTBALL_COUNTRY_SHOW}/cards`;
export const FOOTBALL_COUNTRY_SHOW_FIXTURES = `${FOOTBALL_COUNTRY_SHOW}/fixtures`;
export const FOOTBALL_COUNTRY_SHOW_TEAM = `${FOOTBALL_COUNTRY_SHOW}/team`;
export const FOOTBALL_CUSTOM_DECK_EDIT = `${FOOTBALL_PATH}/squads/:name/edit`;

export const PASSWORD_FORGOTTEN = '/password-forgotten';

export const FOOTBALL_PLAYER_SHOW = `${FOOTBALL_PATH}${LEGACY_PLAYER_SHOW}`;
export const FOOTBALL_PLAYER_SHOW_WILDCARD = `${FOOTBALL_PLAYER_SHOW}/*`;
export const FOOTBALL_PLAYER_SHOW_FIXTURES = `${FOOTBALL_PATH}${LEGACY_PLAYER_SHOW}/fixtures`;
export const FOOTBALL_PLAYER_SHOW_SUPPLY = `${FOOTBALL_PATH}${LEGACY_PLAYER_SHOW}/supply`;
export const FOOTBALL_PLAYER_SHOW_TRANSACTIONS = `${FOOTBALL_PATH}${LEGACY_PLAYER_SHOW}/transactions`;
export const FOOTBALL_PLAYER_SHOW_DESCRIPTION = `${FOOTBALL_PLAYER_SHOW}/description`;
export const FOOTBALL_PLAYER_SHOW_STATS = `${FOOTBALL_PLAYER_SHOW}/stats`;
export const FOOTBALL_PLAYER_SHOW_CARDS = `${FOOTBALL_PLAYER_SHOW}/cards`;

export const LEGACY_TOKEN_AUCTION = `/auctions/:id`;
export const TOKEN_AUCTION = `${FOOTBALL_PATH}/auctions/:id`;
export const ACTIVITY_WILDCARD = '/activity/*';
export const OAUTH_AUTORIZE = '/oauth/authorize';
export const PROMO_CLAIM = '/promo/claim/:code';

export const LANDING_WILDCARD = '/lp/*';
export const LANDING_PREVIEW_ROOT = '/lp/preview';

export const RENDERING_CARD_FACTORY_CARD_SAMPLE_PICTURE = `/rendering/card-factory/card-sample-pictures/:id/:serialNumber`;
export const RENDERING_CARD_FACTORY_CARDS_PICTURE_NORMAL = `/rendering/card-factory/cards/:id/normal`;
export const RENDERING_CARD_FACTORY_CARD_SAMPLE_PICTURE_NORMAL = `/rendering/card-factory/card-sample-pictures/:id/:serialNumber/normal`;
export const RENDERING_CARD_FACTORY_CARD_PICTURE = `/rendering/card-factory/cards/:id`;
export const RENDERING_FOOTBALL_SO5_LINEUP = '/rendering/football/lineups/:id';
export const RENDERING_BASEBALL_SO5_LINEUP = '/rendering/baseball/lineups/:id';
export const RENDERING_NBA_SHARE_LINEUP = '/rendering/nba-lineups/:lineupId';
export const RENDERING_NBA_LINEUPS = '/rendering/nba/lineups/:lineupIds';
export const RENDERING_CARD_COLLECTION = `/rendering/card-collections/:slug/:userSlug`;
export const RENDERING_FOOTBALL_MANAGER_ID = `/rendering/football/manager-id/:slug`;
export const RENDERING_NBA_MANAGER_PROFILE = `/rendering/nba/manager-profile/:slug`;
export const RENDERING_BASEBALL_MANAGER_PROFILE = `/rendering/baseball/manager-profile/:slug`;
export const RENDERING_FOOTBALL_GALLERY = `/rendering/football/gallery/:slug`;
export const RENDERING_NBA_GALLERY = `/rendering/nba/gallery/:slug`;
export const RENDERING_BASEBALL_GALLERY = `/rendering/baseball/gallery/:slug`;
export const RENDERING_FOOTBALL_DECK = `/rendering/football/:slug/decks/:deckSlug`;
export const RENDERING_NBA_DECK = `/rendering/nba/:slug/decks/:deckSlug`;
export const RENDERING_BASEBALL_DECK = `/rendering/baseball/:slug/decks/:deckSlug`;
export const RENDERING_FOOTBALL_RIVALS_INVITE = `/rendering/football/rivals/:userSlug/invites/:slug`;
export const RENDERING_FOOTBALL_RIVALS_CHALLENGE = `/rendering/football/rivals/challenge/:id/:userSlug`;
export const RENDERING_FOOTBALL_RIVALS_SQUAD_INVITE = `/rendering/football/rivals/squad/:joinSecret`;

export const FOOTBALL_HOME = FOOTBALL_PATH;
export const FOOTBALL_WILDCARD = `${FOOTBALL_PATH}/*`;

export const FOOTBALL_RIVALS = `${FOOTBALL_PATH}/rivals`;
export const FOOTBALL_RIVALS_WILDCARD = `${FOOTBALL_RIVALS}/*`;

const FOOTBALL_PLAY_RIVALS = `${FOOTBALL_PATH}/play/rivals`;
export const FOOTBALL_PLAY_RIVALS_WILDCARD = `${FOOTBALL_PLAY_RIVALS}/*`;
export const FOOTBALL_PLAY_RIVALS_MY_LINEUPS_LINEUP = `${FOOTBALL_PLAY_RIVALS}/my-lineups/:slug`;

export enum PrivateLeaguesStep {
  CREATE = 'create',
  CREATE_FORM = 'create-form',
  CONGRATS = 'congrats',
}
export enum PrivateLeaguesTab {
  TEAMS = 'teams',
  LEADERBOARD = 'leaderboard',
  LEAGUE = 'league',
  MEMBERS = 'members',
  PRIZE_POOL = 'prize-pool',
}

export const RIVALS_LANDING = '/rivals';

export const FOOTBALL_STARTER_BUNDLE_WILDCARD = `${FOOTBALL_PATH}/starter-packs/*`;

export const MLB_HOW_TO_PLAY_EXTERNAL = `https://mlbguide.sorare.com`;

export const NBA_HOME = '/nba';
export const NBA_WILDCARD = `${NBA_PATH}/*`;
export const NBA_LANDING = NBA_HOME;
export const NBA_MARKET = `${NBA_PATH}/market`;

export const NBA_PRIMARY_MARKET = `${NBA_MARKET}/primary`;
export const NBA_INSTANT_BUY_MARKET = `${NBA_MARKET}/instant-buy`;
export const NBA_STARTER_BUNDLES = `${NBA_MARKET}/starter-packs`;
export const NBA_SECONDARY_MARKET = `${NBA_MARKET}/secondary`;
export const NBA_SECONDARY_MARKET_STACK_SHOW = `${NBA_SECONDARY_MARKET}/:playerSlug/:rarity`;
export const NBA_CARD_SHOW = `${NBA_PATH}${ANY_SPORT_CARD_SHOW}`;
export const NBA_HOW_TO_PLAY_EXTERNAL = `https://nbaguide.sorare.com`;

export const NBA_TEAM = `${NBA_PATH}${ANY_US_SPORTS_TEAM_SHOW}`;
export const NBA_TEAM_PLAYERS = `${NBA_TEAM}/players`;

export const NBA_ONBOARDING = '/nba/onboarding';
export const NBA_COMPOSE_TEAM_WILDCARD = '/nba/compose-team/*';

export const NBA_LOBBY = `${NBA_PATH}/lobby`;

export const NBA_LOBBY_WILDCARD = `${NBA_LOBBY}/*`;
export const NBA_LOBBY_UPCOMING = `${NBA_LOBBY}/upcoming`;
export const NBA_LOBBY_UPCOMING_TOURNAMENTS = NBA_LOBBY_UPCOMING;
export const NBA_LOBBY_UPCOMING_MY_TEAMS = `${NBA_LOBBY_UPCOMING}/my-teams`;
export const NBA_LOBBY_UPCOMING_LEAGUE = `${NBA_LOBBY_UPCOMING}/league/:leagueSlug`;
export const NBA_LOBBY_UPCOMING_TRAINING_TEAMS = `${NBA_LOBBY_UPCOMING}/training-teams`;
export const NBA_LOBBY_UPCOMING_REWARDS = `${NBA_LOBBY_UPCOMING}/rewards`;
export const NBA_LOBBY_UPCOMING_SCHEDULE = `${NBA_LOBBY_UPCOMING}/schedule`;
export const NBA_LOBBY_UPCOMING_GAME = `${NBA_LOBBY_UPCOMING}/game/:gameId`;

export const NBA_LOBBY_LIVE = `${NBA_LOBBY}/live`;
export const NBA_LOBBY_LIVE_MY_TEAMS = NBA_LOBBY_LIVE;
export const NBA_LOBBY_LIVE_LEAGUE_DEPRECATED = `${NBA_LOBBY_LIVE}/group/:leagueSlug`;
export const NBA_LOBBY_LIVE_LEAGUE = `${NBA_LOBBY_LIVE}/league/:leagueSlug`;
export const NBA_LOBBY_LIVE_TRAINING_TEAMS = `${NBA_LOBBY_LIVE}/training-teams`;
export const NBA_LOBBY_LIVE_LEADERBOARD = `${NBA_LOBBY_LIVE}/leaderboard`;
export const NBA_LOBBY_LIVE_REWARDS = `${NBA_LOBBY_LIVE}/rewards`;
export const NBA_LOBBY_LIVE_SCHEDULE = `${NBA_LOBBY_LIVE}/schedule`;
export const NBA_LOBBY_LIVE_GAME = `${NBA_LOBBY_LIVE}/game/:gameId`;

export const NBA_LOBBY_PAST = `${NBA_LOBBY}/past`;
export const NBA_LOBBY_PAST_LEAGUE = `${NBA_LOBBY_PAST}/league/:leagueSlug`;
export const NBA_LOBBY_SLUG = `${NBA_LOBBY}/:slug`;
export const NBA_LOBBY_MY_TEAMS = `${NBA_LOBBY_SLUG}/my-teams`;
export const NBA_LOBBY_LEAGUE_DEPRECATED = `${NBA_LOBBY_SLUG}/group/:leagueSlug`;
export const NBA_LOBBY_LEAGUE = `${NBA_LOBBY_SLUG}/league/:leagueSlug`;
export const NBA_LOBBY_SCHEDULE = `${NBA_LOBBY_SLUG}/schedule`;
export const NBA_LOBBY_TRAINING_TEAMS = `${NBA_LOBBY_SLUG}/training-teams`;
export const NBA_LOBBY_LEADERBOARD = `${NBA_LOBBY_SLUG}/leaderboard`;
export const NBA_LOBBY_REWARDS = `${NBA_LOBBY_SLUG}/rewards`;
export const NBA_LOBBY_GAME = `${NBA_LOBBY_SLUG}/game/:gameId`;

export const NBA_ALL_STAR_2024 = `${NBA_PATH}/all-star-2024`;

export const NBA_PLAY = `${NBA_PATH}/play`;

export const NBA_PLAY_PRO_COMPETITIONS = `${NBA_PLAY_PRO}/competitions`;
export const NBA_PLAY_PRO_COMPETITION = `${NBA_PLAY_PRO_COMPETITIONS}/:leaderboardSlug`;
export const NBA_PLAY_PRO_COMPETITION_TEAM = `${NBA_PLAY_PRO_COMPETITION}/team`;
export const NBA_PLAY_PRO_COMPETITION_LEADERBOARD = `${NBA_PLAY_PRO_COMPETITION}/leaderboard`;
export const NBA_PLAY_PRO_COMPETITION_LEADERBOARD_FULL = `${NBA_PLAY_PRO_COMPETITION_LEADERBOARD}/full`;

export const DEFAULT_SEASON_SLUG = 'current';

export const LANDING_BY_SPORT: Record<Sport, string> = {
  [Sport.FOOTBALL]: FOOTBALL_PATH,
  [Sport.NBA]: NBA_LANDING,
  [Sport.BASEBALL]: MLB,
};

export const SECONDARY_MARKET_STACK_SHOW_BY_SPORT: Record<Sport, string> = {
  [Sport.FOOTBALL]: FOOTBALL_MARKET_MANAGER_SALES_PLAYERSLUG_RARITY,
  [Sport.NBA]: NBA_SECONDARY_MARKET_STACK_SHOW,
  [Sport.BASEBALL]: MLB_MARKET_SECONDARY_PLAYERSLUG_RARITY,
};

export const AUCTION_MARKET_URL: Record<Sport, string> = {
  [Sport.FOOTBALL]: FOOTBALL_MARKET_NEW_SIGNINGS,
  [Sport.BASEBALL]: MLB_MARKET_PRIMARY,
  [Sport.NBA]: NBA_PRIMARY_MARKET,
};

export const SECONDARY_MARKET_URL_BY_SPORT: Record<Sport, string> = {
  [Sport.FOOTBALL]: FOOTBALL_MARKET_MANAGER_SALES,
  [Sport.BASEBALL]: MLB_MARKET_SECONDARY,
  [Sport.NBA]: NBA_SECONDARY_MARKET,
};

export const INSTANT_BUY_URL: Record<Sport, string> = {
  [Sport.BASEBALL]: MLB_MARKET_INSTANT_BUY,
  [Sport.NBA]: NBA_INSTANT_BUY_MARKET,
  [Sport.FOOTBALL]: FOOTBALL_MARKET_INSTANT_BUY,
};

export const MY_CLUB_BY_SPORT: Record<Sport, string> = {
  [Sport.FOOTBALL]: FOOTBALL_MY_CLUB_SLUG,
  [Sport.BASEBALL]: MLB_MY_CLUB_SLUG,
  [Sport.NBA]: NBA_MY_CLUB_SLUG,
};

export const MY_CLUB_HISTORY_BY_SPORT: Record<Sport, string> = {
  [Sport.FOOTBALL]: FOOTBALL_MY_CLUB_SLUG_HISTORY,
  [Sport.BASEBALL]: MLB_MY_CLUB_SLUG_HISTORY,
  [Sport.NBA]: NBA_MY_CLUB_SLUG_HISTORY,
};

export function catchAll(path: string) {
  return `${path.replace(/\/$/, '')}/*`;
}

const SHARED_PAGES = [
  LANDING,
  MLB,
  catchAll(SETTINGS),
  MY_SORARE_WILDCARD,
  CAREERS,
  LANDING_WILDCARD,
  PRESS,
  TERMS_AND_CONDITIONS,
  LICENSED_PARTNERS,
  GAME_RULES,
  PRIVACY_POLICY,
  COOKIE_POLICY,
  CONFIRM_EMAIL,
  CONFIRM_DEVICE,
  DEBUG_DEVICE,
  catchAll(INVITE),
  ACCEPT_INVITATION,
  R_USERSLUG,
  MOBILE_SIGN_UP,
  OAUTH_AUTORIZE,
  ACTIVITY_WILDCARD,
  BLOG_WILDCARD,
  HELP_WILDCARD,
];

export function sharedAcrossSportsPage(pathname: string) {
  return !!SHARED_PAGES.find(page => matchPath(page, pathname));
}

export function isLanding(pathname: string) {
  return matchPath(LANDING, pathname);
}

export function isMlbPage(pathname: string) {
  return !!matchPath(catchAll(MLB), pathname);
}

export function isNBAPage(pathname: string) {
  return !!matchPath(NBA_WILDCARD, pathname);
}

export function isMarket(pathname: string) {
  return !!(
    matchPath(FOOTBALL_MARKET_NEW_SIGNINGS, pathname) ||
    matchPath(MLB_MARKET_PRIMARY, pathname) ||
    matchPath(NBA_PRIMARY_MARKET, pathname) ||
    matchPath(FOOTBALL_MARKET_MANAGER_SALES, pathname) ||
    matchPath(MLB_MARKET_SECONDARY, pathname) ||
    matchPath(NBA_SECONDARY_MARKET, pathname)
  );
}

// this should stay a hook for future feature flags integration
export const useDefaultSportPages = (): { [key in Sport]: string } => {
  return useMemo(
    () => ({
      FOOTBALL: FOOTBALL_HOME,
      BASEBALL: MLB,
      NBA: NBA_HOME,
    }),
    []
  );
};

export const RAMP_TRANSACTION_WEBHOOK = `${API_ROOT}/webhooks/ramp/transaction_state_updated`;
