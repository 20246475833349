import { RefObject, useEffect, useRef } from 'react';

import { WithSlug } from './types';

const useScrollToItem = <T extends WithSlug>({
  value,
  items,
  wrapperRef,
  scrollContainerRef,
}: {
  value: T;
  items: T[];
  wrapperRef: RefObject<HTMLDivElement | null>;
  scrollContainerRef: RefObject<HTMLDivElement | null>;
}) => {
  const isFirstScroll = useRef<boolean>(true);

  useEffect(() => {
    const indexToScroll =
      items.findIndex(item => item.slug === value.slug) +
      // accounts for load more in future placeholder
      1;
    if (
      scrollContainerRef?.current &&
      wrapperRef.current &&
      indexToScroll > 0
    ) {
      /**
       * Prevent scrolling & ensure active item is displayed
       * on top on the first render
       */
      const behavior = isFirstScroll.current ? 'instant' : 'smooth';

      const index = Math.max(
        0,
        Math.min(
          indexToScroll,
          scrollContainerRef.current.childNodes.length - 1
        )
      );
      const { offsetLeft: itemOffsetLeft, clientWidth: itemWidth } =
        scrollContainerRef.current.childNodes[index] as HTMLDivElement;
      const left =
        itemOffsetLeft - wrapperRef.current.clientWidth / 2 + itemWidth / 2;

      scrollContainerRef.current.scrollTo({
        left,
        behavior,
      });
      isFirstScroll.current = false;
    }
    // don't react on items change as we don't want to scroll on loadMore result update
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef, scrollContainerRef, value]);
};

export default useScrollToItem;
