import { Navigate, generatePath, useParams } from 'react-router-dom';

import { NBA_S3_PLAY_PRO_LEADERBOARD_LEADERBOARD_PRIZE_POOL } from '@sorare/core/src/constants/__generated__/routes';

const LeaderboardPage = () => {
  const { leaderboard = '' } = useParams();
  return (
    <Navigate
      to={generatePath(NBA_S3_PLAY_PRO_LEADERBOARD_LEADERBOARD_PRIZE_POOL, {
        leaderboard,
      })}
      replace
    />
  );
};

export default LeaderboardPage;
