import { TypedDocumentNode, gql } from '@apollo/client';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { FixtureState } from '@sorare/core/src/__generated__/globalTypes';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { HeadlineS } from '@sorare/core/src/atoms/typography';
import { FixtureChart as DumbFixtureChart } from '@sorare/core/src/components/player/FixtureChart';
import { glossary, playerDetails } from '@sorare/core/src/lib/glossary';

import { TeamLogo } from '@sorare/us-sports/src/components/team/TeamLogo';

import { FixtureChart_NBAPlayer } from './__generated__/index.graphql';

type Props = {
  player: FixtureChart_NBAPlayer;
  onClick?: (gameId: string) => void;
};

const SmallTeamLogo = styled(TeamLogo)`
  --size: 24px;
`;

export const FixtureChart = ({ player, onClick }: Props) => {
  // fixture are returned most recent first
  const fixturesStats = useMemo(
    () =>
      player.latestFixturesStats
        .filter(
          fixtureStat =>
            fixtureStat.fixture.fixtureState !== FixtureState.opened
        )
        .reverse(),
    [player.latestFixturesStats]
  );

  const playerTeamSlug = player?.activeClub?.slug;

  return (
    <Vertical gap={1.5}>
      <HeadlineS>
        <FormattedMessage {...playerDetails.performance} />
      </HeadlineS>
      <DumbFixtureChart
        fixturesStats={fixturesStats.map(fixtureStats => {
          const bestGame = fixtureStats.status.gameStats.reduce<
            (typeof fixtureStats)['status']['gameStats'][number] | undefined
          >((acc, game) => {
            const accScore =
              acc?.scoreForScoringStrategy ?? Number.NEGATIVE_INFINITY;
            const gameScore =
              game?.scoreForScoringStrategy ?? Number.NEGATIVE_INFINITY;
            return gameScore > accScore ? game : acc;
          }, undefined);

          return {
            label: `GW${fixtureStats.fixture.gameWeek}`,
            games: [
              ...fixtureStats.status.gameStats.map(gs => {
                const score = gs.scoreForScoringStrategy;
                return {
                  id: gs.game.id as string,
                  playerScore: gs.playedInGame ? score : undefined,
                  gameLabel: <SmallTeamLogo team={gs.againstTeam} />,
                  startDate: gs.game.startDate,
                  ...(onClick ? { onClick: () => onClick?.(gs.game.id) } : {}),
                  barLabel:
                    bestGame && bestGame.game.id === gs.game.id
                      ? glossary.bestGame
                      : undefined,
                  color:
                    bestGame && bestGame.game.id === gs.game.id
                      ? 'var(--c-score-high)'
                      : undefined,
                };
              }),
              ...fixtureStats.status.upcomingGames.map(ug => {
                return {
                  id: ug.id as string,
                  playerScore: undefined,
                  upcoming: true,
                  startDate: ug.startDate,
                  color:
                    bestGame && bestGame.game.id === ug.id
                      ? 'var(--c-score-high)'
                      : undefined,
                  gameLabel: (
                    <SmallTeamLogo
                      team={
                        ug.homeTeam.slug !== playerTeamSlug
                          ? ug.homeTeam
                          : ug.awayTeam
                      }
                    />
                  ),
                };
              }),
            ],
          };
        })}
      />
    </Vertical>
  );
};

FixtureChart.fragments = {
  NBAPlayer: gql`
    fragment FixtureChart_NBAPlayer on NBAPlayer {
      slug
      activeClub {
        slug
      }
      # eslint-disable-next-line @graphql-eslint/no-deprecated
      latestFixturesStats: latestFixtureStats(last: 40) {
        fixture {
          id
          slug
          gameWeek
          startDate
          endDate
          fixtureState
        }

        score
        tenGameAverage
        status {
          gameStats {
            scoreForScoringStrategy(strategy: $scoringStrategy)
            playedInGame
            game {
              id
              startDate
            }
            againstTeam {
              slug
              ...TeamLogo_SportsTeamInterface
            }
          }
          upcomingGames {
            id
            startDate
            homeTeam {
              slug
              ...TeamLogo_SportsTeamInterface
            }
            awayTeam {
              slug
              ...TeamLogo_SportsTeamInterface
            }
          }
        }
      }
    }
    ${TeamLogo.fragments.SportsTeamInterface}
  ` as TypedDocumentNode<FixtureChart_NBAPlayer>,
};
