// TODO: ultimately this should be in SO5 instead of @sorare-core
//       but for the sake of simplicity, let's have it here for now

import { TypedDocumentNode, gql } from '@apollo/client';
import { MessageDescriptor, defineMessages, useIntl } from 'react-intl';

import {
  AveragePlayerScore,
  CardQuality,
  PlayerPlayingStatus,
  Position,
  ScoreStatus,
  Sport,
} from '__generated__/globalTypes';
import {
  fixturePositionsNames as baseballFixturePositionNames,
  positionNames as baseballPositionNames,
} from 'lib/baseball';
import { positionNames as nbaPositionNames } from 'lib/nba';

import {
  AverageScores_anyCard,
  AverageScores_anyPlayer,
  getAppearancePercentage_anyPlayerInterface,
  getSortedAppearancesFromRules_so5Leaderboard,
  getSortedAppearancesFromRules_so5Lineup,
} from './__generated__/players.graphql';
import {
  LenientPolicy,
  lenientArrayIndexComparator,
  sortByArrayIndex,
} from './arrays';
import { fantasy, playerGameStatusLabels } from './glossary';
import { withFragments } from './gql';

export { LenientPolicy } from './arrays';

export const formatFilterOption = (name: string) => {
  const [firstName, ...lastName] = name.split(' ');
  if (lastName.length > 0) {
    return `${firstName[0]}. ${lastName.join(' ')}`;
  }
  return firstName;
};

export const splitName = (name: string) => {
  let [firstName, ...lastNames] = name.split(' ');
  if (lastNames.length === 0) {
    [firstName, lastNames] = ['', [firstName]];
  }
  const lastLastName = lastNames[lastNames.length - 1];
  const lastName = lastNames.join(' ');

  return [firstName, lastName, lastLastName];
};

export const getShortPlayerName = (fullName: string) => {
  const splitPlayerName = splitName(fullName);

  return `${splitPlayerName[0] ? `${splitPlayerName[0][0]}. ` : ``}${
    splitPlayerName[1]
  }`;
};

export const footballPositions = [
  Position.Forward,
  Position.Midfielder,
  Position.Defender,
  Position.Goalkeeper,
  Position.Coach,
  Position.Unknown,
] as const;

export type FootballPosition = (typeof footballPositions)[number];

export function isFootballPosition(
  position: Position | undefined | null
): position is FootballPosition {
  return footballPositions.includes(position as any);
}

export const toFootballPosition = (
  position: Position | undefined | null
): FootballPosition => {
  if (isFootballPosition(position)) {
    return position;
  }

  return Position.Unknown;
};

export const lineupPositions = [
  Position.Goalkeeper,
  Position.Defender,
  Position.Midfielder,
  Position.Forward,
  'Extra Player',
] as const;

export type LineupPosition = (typeof lineupPositions)[number];

// Changing how this array is sorted will change how the compose team go to the next available slot
export const playablePositions = [
  Position.Forward,
  Position.Midfielder,
  Position.Defender,
  Position.Goalkeeper,
] as const;
export type PlayablePosition = (typeof playablePositions)[number];

export const allPositions = [...playablePositions, Position.Coach] as const;

export const positionComparator = (
  lenientPolicy: LenientPolicy = LenientPolicy.UNKNOWN_AT_HEAD
) =>
  lenientArrayIndexComparator<PlayablePosition>(
    playablePositions,
    lenientPolicy
  );

export const sortByPosition = (
  a: PlayablePosition,
  b: PlayablePosition
): number => sortByArrayIndex(playablePositions, a, b);

export const getSortedAppearancesFromRules = withFragments(
  <T extends getSortedAppearancesFromRules_so5Lineup>({
    so5Lineup,
    so5Leaderboard,
  }: {
    so5Lineup?: T;
    so5Leaderboard: getSortedAppearancesFromRules_so5Leaderboard;
  }): (
    | T['so5Appearances'][number]
    | NonNullable<
        NonNullable<
          getSortedAppearancesFromRules_so5Leaderboard['rules']
        >['appearances']
      >[number]
  )[] => {
    const appearancesRules = so5Leaderboard.rules?.appearances || [];

    return appearancesRules.map(
      (rule, i) =>
        so5Lineup?.so5Appearances.find(appearance => appearance.index === i) ||
        rule
    );
  },
  {
    so5Lineup: gql`
      fragment getSortedAppearancesFromRules_so5Lineup on So5Lineup {
        id
        so5Appearances {
          id
          index
        }
      }
    ` as TypedDocumentNode<getSortedAppearancesFromRules_so5Lineup>,
    so5Leaderboard: gql`
      fragment getSortedAppearancesFromRules_so5Leaderboard on So5Leaderboard {
        slug
        rules {
          id
          appearances {
            displayName
          }
        }
      }
    ` as TypedDocumentNode<getSortedAppearancesFromRules_so5Leaderboard>,
  }
);

export const getSortedAppearances = <
  T extends { id?: string; position: string },
>(
  appearances: T[]
): T[] => {
  if (!appearances?.length) {
    return [];
  }
  const extraPlayerByNameIndex = appearances.findIndex(
    a => a.position === 'Extra Player'
  );
  const extraPlayerIndex =
    extraPlayerByNameIndex !== -1
      ? extraPlayerByNameIndex
      : appearances.findIndex(
          (item, index, arr) =>
            arr.findIndex(i => i.position === item.position) !== index
        );

  if (extraPlayerIndex === -1) {
    const sorted = [...appearances].sort((a, b) =>
      sortByArrayIndex(
        lineupPositions,
        a.position as PlayablePosition,
        b.position as PlayablePosition
      )
    );
    return sorted;
  }
  const appearancesWithoutExtraPlayer = appearances.filter(
    (_, idx) => idx !== extraPlayerIndex
  );
  const sorted = appearancesWithoutExtraPlayer.sort((a, b) =>
    sortByArrayIndex(
      lineupPositions,
      a.position as PlayablePosition,
      b.position as PlayablePosition
    )
  );
  return [...sorted, appearances[extraPlayerIndex]];
};

export const getMissingAppearances = (
  appearances: { position: string }[],
  total: number
) => {
  return [...Array(total - appearances?.length).keys()].reduce<
    { id?: string; position: string }[]
  >(prev => {
    const missingPosition = lineupPositions.find(
      position =>
        !appearances.some(a => a.position === position) &&
        !prev.some(a => a.position === position)
    );
    return [...prev, { position: missingPosition ?? 'Extra Player' }];
  }, []);
};

export const qualityNames: { [key in CardQuality]: string } = {
  TIER_0: 'Star',
  TIER_1: 'Tier 1',
  TIER_2: 'Tier 2',
  TIER_3: 'Tier 3',
  TIER_4: 'Tier 4',
  TIER_5: 'Tier 5',
};

export const positionNames = defineMessages<Position>({
  Forward: {
    id: 'Player.forward',
    defaultMessage: 'Forward',
  },
  Midfielder: {
    id: 'Player.midfielder',
    defaultMessage: 'Midfielder',
  },
  Defender: {
    id: 'Player.defender',
    defaultMessage: 'Defender',
  },
  Goalkeeper: {
    id: 'Player.goalkeeper',
    defaultMessage: 'Goalkeeper',
  },
  Unknown: {
    id: 'Player.unknown',
    defaultMessage: 'Unknown Position',
  },
  Coach: {
    id: 'Player.coach',
    defaultMessage: 'Coach',
  },
  basketball_center: {
    id: 'Player.basketball_center',
    defaultMessage: 'Center',
  },
  basketball_forward: {
    id: 'Player.basketball_forward',
    defaultMessage: 'Forward',
  },
  basketball_guard: {
    id: 'Player.basketball_guard',
    defaultMessage: 'Guard',
  },
  baseball_catcher: {
    id: 'Player.baseball_catcher',
    defaultMessage: 'Catcher',
  },
  baseball_first_base: {
    id: 'Player.baseball_first_base',
    defaultMessage: 'First Base',
  },
  baseball_second_base: {
    id: 'Player.baseball_second_base',
    defaultMessage: 'Second Base',
  },
  baseball_third_base: {
    id: 'Player.baseball_third_base',
    defaultMessage: 'Third Base',
  },
  baseball_shortstop: {
    id: 'Player.baseball_shortstop',
    defaultMessage: 'Shortstop',
  },
  baseball_outfield: {
    id: 'Player.baseball_outfield',
    defaultMessage: 'Outfield',
  },
  baseball_starting_pitcher: {
    id: 'Player.baseball_pitcher',
    defaultMessage: 'Starting Pitcher',
  },
  baseball_designated_hitter: {
    id: 'Player.baseball_designated_hitter',
    defaultMessage: 'Designated Hitter',
  },
  baseball_relief_pitcher: {
    id: 'Player.baseball_relief_pitcher',
    defaultMessage: 'Relief Pitcher',
  },
});

export const playingStatusNames = defineMessages<PlayerPlayingStatus>({
  [PlayerPlayingStatus.NOT_PLAYING]: {
    id: 'Player.notPlaying',
    defaultMessage: 'Not Playing',
  },
  [PlayerPlayingStatus.STARTER]: {
    id: 'Player.starter',
    defaultMessage: 'Starter',
  },
  [PlayerPlayingStatus.REGULAR]: {
    id: 'Player.regular',
    defaultMessage: 'Regular',
  },
  [PlayerPlayingStatus.SUBSTITUTE]: {
    id: 'Player.substitute',
    defaultMessage: 'Substitute',
  },
  [PlayerPlayingStatus.SUPER_SUBSTITUTE]: {
    id: 'Player.superSub',
    defaultMessage: 'SuperSub',
  },
  [PlayerPlayingStatus.RETIRED]: {
    id: 'Player.retired',
    defaultMessage: 'Retired',
  },
});

export const attributes = defineMessages({
  position: {
    id: 'Information.position',
    defaultMessage: 'Position',
  },
  country: {
    id: 'Information.country',
    defaultMessage: 'Country',
  },
  age: {
    id: 'Information.age',
    defaultMessage: 'Age',
  },
  appearances: {
    id: 'SeasonStats.appearances',
    defaultMessage: 'Appearances',
  },
  assists: {
    id: 'SeasonStats.assists',
    defaultMessage: 'Assists',
  },
  minPlayed: {
    id: 'SeasonStats.minPlayed',
    defaultMessage: 'Min. played',
  },
  goals: {
    id: 'SeasonStats.goals',
    defaultMessage: 'Goals',
  },
  substituteIn: {
    id: 'SeasonStats.substituteIn',
    defaultMessage: 'Substitute In',
  },
  yellowCards: {
    id: 'SeasonStats.yellowCards',
    defaultMessage: 'Yellow Cards',
  },
  substituteOut: {
    id: 'SeasonStats.substituteOut',
    defaultMessage: 'Substitute Out',
  },
  redCards: {
    id: 'SeasonStats.redCards',
    defaultMessage: 'Red Cards',
  },
});

export const getAppearancePercentage = withFragments(
  (
    mode: AveragePlayerScore,
    player: getAppearancePercentage_anyPlayerInterface
  ) => {
    switch (mode) {
      case AveragePlayerScore.LAST_FIVE_SO5_AVERAGE_SCORE:
        if (!player.lastFiveSo5Appearances) {
          return 0;
        }
        return ((player.lastFiveSo5Appearances * 100) / 5.0).toFixed(0);
      case AveragePlayerScore.LAST_FIFTEEN_SO5_AVERAGE_SCORE:
        if (!player.lastFifteenSo5Appearances) {
          return 0;
        }
        return ((player.lastFifteenSo5Appearances * 100) / 15.0).toFixed(0);
      default:
        return 0;
    }
  },
  {
    anyPlayer: gql`
      fragment getAppearancePercentage_anyPlayerInterface on AnyPlayerInterface {
        slug
        lastFiveSo5Appearances
        lastFifteenSo5Appearances
      }
    ` as TypedDocumentNode<getAppearancePercentage_anyPlayerInterface>,
  }
);

export const formatPosition = (
  position: string,
  formatMessage: (message: MessageDescriptor) => string
) => {
  const msg = positionNames[position as PlayablePosition];
  return msg ? formatMessage(msg) : position;
};

export const anyPositionNames = {
  ...positionNames,
  ...nbaPositionNames,
  ...baseballPositionNames,
  ...baseballFixturePositionNames,
};

export const useFormatAnyPosition = () => {
  const { formatMessage } = useIntl();

  return (position: string) => {
    const msg = anyPositionNames[position as PlayablePosition];
    return msg ? formatMessage(msg) : position;
  };
};

export const useFormatAnyPositions = () => {
  const format = useFormatAnyPosition();

  return (positions: string[]) => {
    return positions.map(format).join(' · ');
  };
};

// Uppercase a string and keep ß as is (it's transformed to SS by default)
export const playerNameToUppercase = (name: string) =>
  name.replace('ß', '<ESZETT>').toLocaleUpperCase().replace('<ESZETT>', 'ß');

export const unlicensedLastName = (
  firstName: string,
  lastName: string,
  uppercase: boolean = true
) => {
  const data = { lastName };
  if (uppercase) {
    data.lastName = playerNameToUppercase(lastName);
  }

  return `${data.lastName.substring(0, firstName ? 1 : 3)}.`;
};

export const unlicensedDisplayName = (
  firstName: string,
  lastName: string,
  uppercase: boolean = true
) => {
  const data = { firstName, lastName };
  if (uppercase) {
    data.firstName = playerNameToUppercase(firstName);
    data.lastName = unlicensedLastName(firstName, lastName, uppercase);
  }

  return `${data.firstName} ${
    data.lastName.length > 0 && ` ${data.lastName}.`
  }`;
};

export const anyPositionShortNames = defineMessages<Position>({
  Forward: {
    id: 'Player.shortForward',
    defaultMessage: 'FW',
  },
  Midfielder: {
    id: 'Player.shortMidfielder',
    defaultMessage: 'MD',
  },
  Defender: {
    id: 'Player.shortDefender',
    defaultMessage: 'DF',
  },
  Goalkeeper: {
    id: 'Player.shortGoalkeeper',
    defaultMessage: 'GK',
  },
  Coach: {
    id: 'Player.shortCoach',
    defaultMessage: 'Coach',
  },
  Unknown: {
    id: 'Player.shortUnknown',
    defaultMessage: 'Unknown',
  },
  basketball_center: {
    id: 'Player.short_basketball_center',
    defaultMessage: 'C',
  },
  basketball_forward: {
    id: 'Player.short_basketball_forward',
    defaultMessage: 'F',
  },
  basketball_guard: {
    id: 'Player.short_basketball_guard',
    defaultMessage: 'G',
  },
  baseball_catcher: {
    id: 'Player.short_baseball_catcher',
    defaultMessage: 'C',
  },
  baseball_first_base: {
    id: 'Player.short_baseball_first_base',
    defaultMessage: '1B',
  },
  baseball_second_base: {
    id: 'Player.short_baseball_second_base',
    defaultMessage: '2B',
  },
  baseball_third_base: {
    id: 'Player.short_baseball_third_base',
    defaultMessage: '3B',
  },
  baseball_shortstop: {
    id: 'Player.short_baseball_shortstop',
    defaultMessage: 'SS',
  },
  baseball_outfield: {
    id: 'Player.short_baseball_outfield',
    defaultMessage: 'OF',
  },
  baseball_starting_pitcher: {
    id: 'Player.short_baseball_pitcher',
    defaultMessage: 'SP',
  },
  baseball_designated_hitter: {
    id: 'Player.short_baseball_designated_hitter',
    defaultMessage: 'DH',
  },
  baseball_relief_pitcher: {
    id: 'Player.short_baseball_relief_pitcher',
    defaultMessage: 'RP',
  },
});

export const statusMessages: Record<ScoreStatus, MessageDescriptor> = {
  [ScoreStatus.DID_NOT_PLAY]: playerGameStatusLabels.did_not_play,
  [ScoreStatus.NO_GAME]: playerGameStatusLabels.no_game,
  [ScoreStatus.PENDING]: playerGameStatusLabels.pending,
  [ScoreStatus.REVIEWING]: playerGameStatusLabels.reviewing,
  [ScoreStatus.FINAL]: fantasy.score,
};

export const shortStatusMessages: Partial<
  Record<ScoreStatus, MessageDescriptor>
> = {
  [ScoreStatus.DID_NOT_PLAY]: playerGameStatusLabels.did_not_play_short,
  [ScoreStatus.NO_GAME]: playerGameStatusLabels.no_game_short,
};

export const sportOfPlayerType = {
  Player: Sport.FOOTBALL,
  BaseballPlayer: Sport.BASEBALL,
  NBAPlayer: Sport.NBA,
};

export const averagePlayerScoresFragment = gql`
  fragment AverageScores_anyPlayer on AnyPlayerInterface {
    slug
    seasonAverage: averageScore(type: SEASON_AVERAGE_SCORE)
    lastFiveSo5AverageScore: averageScore(type: LAST_FIVE_SO5_AVERAGE_SCORE)
    lastTenPlayedSo5AverageScore: averageScore(
      type: LAST_TEN_PLAYED_SO5_AVERAGE_SCORE
    )
    lastFifteenSo5AverageScore: averageScore(
      type: LAST_FIFTEEN_SO5_AVERAGE_SCORE
    )
  }
` as TypedDocumentNode<AverageScores_anyPlayer>;

export const averageCardScoresFragment = gql`
  fragment AverageScores_anyCard on AnyCardInterface {
    slug
    lastFiveSo5AverageScore: averageScore(type: LAST_FIVE_SO5_AVERAGE_SCORE)
    lastTenPlayedSo5AverageScore: averageScore(
      type: LAST_TEN_PLAYED_SO5_AVERAGE_SCORE
    )
    lastFifteenSo5AverageScore: averageScore(
      type: LAST_FIFTEEN_SO5_AVERAGE_SCORE
    )
  }
` as TypedDocumentNode<AverageScores_anyCard>;
