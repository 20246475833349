import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import LoadingIndicator from '@sorare/core/src/atoms/loader/LoadingIndicator';

const MySquadLayout = () => {
  return (
    <Suspense fallback={<LoadingIndicator fullHeight />}>
      <Outlet />
    </Suspense>
  );
};
export default MySquadLayout;
