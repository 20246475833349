import { TypedDocumentNode, gql } from '@apollo/client';
import { Suspense, lazy } from 'react';

import { ErrorBoundary } from '@sorare/error-boundary';

import { FrontBoxReward } from 'components/rewards/BoxReward/Front';
import useScreenSize from 'hooks/device/useScreenSize';

import { ProbabilisticBundle_probabilisticBundleReward } from './__generated__/index.graphql';
import { useFormatBoxForDisplay } from './useFormatBoxForDisplay';

const BuyConfirmationRewardBox = lazy(
  async () => import('@sorare/trois/src/components/BuyConfirmationRewardBox')
);

type Props = {
  probabilisticBundleReward: ProbabilisticBundle_probabilisticBundleReward;
  withEnterAnimation?: boolean;
};

export const ProbabilisticBundle = ({
  probabilisticBundleReward,
  withEnterAnimation = false,
}: Props) => {
  const { up } = useScreenSize('laptop');
  const boxes = useFormatBoxForDisplay([
    probabilisticBundleReward.probabilisticBundle,
  ]);
  const box = boxes?.[0];
  if (!box) return null;
  return (
    <Suspense>
      <ErrorBoundary fallback={<FrontBoxReward box={box} />}>
        <BuyConfirmationRewardBox
          box={box}
          panelPosition={up ? [0, -1, 1] : [0, -1, 0.5]}
          withEnterAnimation={withEnterAnimation}
        />
      </ErrorBoundary>
    </Suspense>
  );
};

ProbabilisticBundle.fragments = {
  probabilisticBundleReward: gql`
    fragment ProbabilisticBundle_probabilisticBundleReward on ProbabilisticBundleReward {
      id
      probabilisticBundle {
        id
        config {
          id
          name
          pictureUrl
        }
        ...useFormatBoxForDisplay_ProbabilisticBundle
      }
    }
    ${useFormatBoxForDisplay.fragments.ProbabilisticBundle}
  ` as TypedDocumentNode<ProbabilisticBundle_probabilisticBundleReward>,
};
