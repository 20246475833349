import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Sport } from '__generated__/globalTypes';
import Button from 'atoms/buttons/Button';
import { SorareLogo } from 'atoms/icons/SorareLogo';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { LinkBox, LinkOverlay } from 'atoms/navigation/Box';
import SmallerStarBall from 'atoms/navigation/SmallerStarBall';
import { Text16 } from 'atoms/typography';
import ResponsiveImg from 'atoms/ui/ResponsiveImg';
import { useSportCTAProps } from 'components/landing/FeaturedSport/useSportCTAProps';
import { CARD_BORDER_RADIUS } from 'lib/cards';
import { glossary } from 'lib/glossary';
import { tabletAndAbove } from 'style/mediaQuery';

// If height is not specified, there is a bug on Safari where the content overflow
const SportWrapper = styled(LinkBox)`
  height: 100%;
  padding: var(--double-and-a-half-unit) 0 var(--double-and-a-half-unit)
    var(--double-and-a-half-unit);
  border-radius: var(--unit);
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  aspect-ratio: 0.8;
  position: relative;

  overflow: hidden;
  display: grid;
  grid-template-areas:
    'logos cards'
    'content cards';
  grid-template-columns: 3fr 1fr;
  grid-template-rows: max-content 1fr;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.8) 0%,
      transparent 100%
    );
  }

  & > * {
    z-index: 1;
  }

  @media ${tabletAndAbove} {
    aspect-ratio: 1;
    grid-template-columns: 4fr 1fr;
  }
`;

const Content = styled(Vertical)`
  grid-area: content;
  max-width: 70%;
  align-self: end;
`;

const SportName = styled.p`
  font-size: 28px;
  font-family: 'Druk Wide';
  text-transform: uppercase;
`;

const LogoWrapper = styled(Horizontal)`
  grid-area: logos;
  top: var(--quadruple-unit);
  height: var(--triple-unit);
`;

const StarBall = styled(SmallerStarBall)`
  margin-right: var(--unit);
`;

const CardsArea = styled(Vertical).attrs({ gap: 0.5, center: true })`
  grid-area: cards;
  flex-shrink: 0.18;
  width: 100%;
  padding: 0 var(--unit);
`;

const CardImage = styled(ResponsiveImg).attrs({
  cropWidth: 160,
  draggable: false,
})`
  width: 100%;
  border-radius: ${CARD_BORDER_RADIUS};
  aspect-ratio: var(--card-aspect-ratio);
`;

type Props = {
  sport: Sport;
  text: string;
  title: string;
  bgImage: string;
  logos?: ReactNode;
  cardsUrls: string[];
};

export const DumbContent = ({
  text,
  sport,
  title,
  logos,
  bgImage,
  cardsUrls,
}: Props) => {
  const { formatMessage } = useIntl();
  const CTAProps = useSportCTAProps(sport);

  return (
    <SportWrapper style={{ backgroundImage: `url(${bgImage})` }}>
      <LogoWrapper>
        <div>
          <StarBall />
          <SorareLogo />
        </div>
        {logos}
      </LogoWrapper>
      <Content>
        <SportName>{title}</SportName>
        <Text16>{text}</Text16>
        <div>
          <LinkOverlay {...CTAProps} as={Button} color="tertiary" size="medium">
            {formatMessage(glossary.playNow)}
          </LinkOverlay>
        </div>
      </Content>
      <CardsArea>
        {cardsUrls?.map(cardUrl => (
          <CardImage key={cardUrl} src={cardUrl} alt="" />
        ))}
      </CardsArea>
    </SportWrapper>
  );
};
