// copy of hardcoded data from API
// https://gitlab.com/sorare/backend/-/blob/master/config/so5/campaigns.yaml#L5-7
export const CAMPAIGN_LEADERBOARDS_MAP = {
  'mbappe-fc': [
    'football-13-17-sep-2024-special-weekly-mbappe-fc-division-1',
    'football-17-20-sep-2024-special-weekly-mbappe-fc-division-1',
    'football-20-24-sep-2024-special-weekly-mbappe-fc-division-1',
  ],
};
export const isMbappeLeaderboard = (so5LeaderboardSlug: Nullable<string>) => {
  return (
    !!so5LeaderboardSlug &&
    CAMPAIGN_LEADERBOARDS_MAP['mbappe-fc'].includes(so5LeaderboardSlug)
  );
};
