import { TypedDocumentNode, gql } from '@apollo/client';

import {
  BlueprintRevealStatus,
  Rarity,
} from '@sorare/core/src/__generated__/globalTypes';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import useQuery from '@sorare/core/src/hooks/graphql/useQuery';
import useFeatureFlags from '@sorare/core/src/hooks/useFeatureFlags';

import {
  BlueprintCardsQuery,
  BlueprintCardsQueryVariables,
} from './__generated__/useHasCardsToReveal.graphql';

const BLUEPRINTS_CARDS_QUERY = gql`
  query BlueprintCardsQuery {
    currentUser {
      slug
      id
      blueprintFootballCards: footballCards(
        customCardEditionName: "blueprint"
        first: 100
      ) {
        nodes {
          slug
          blueprint {
            id
            revealStatus
          }
          rarityTyped
        }
        totalCount
      }
    }
  }
` as TypedDocumentNode<BlueprintCardsQuery, BlueprintCardsQueryVariables>;

export const useHasCardsToReveal = () => {
  const {
    flags: { useRevealAnnouncement = false },
  } = useFeatureFlags();

  const { currentUser } = useCurrentUserContext();

  const hasBlueprints =
    (currentUser?.blueprintFootballCards.totalCount || 0) > 0 &&
    useRevealAnnouncement;

  const queryResult = useQuery(BLUEPRINTS_CARDS_QUERY, {
    skip: !hasBlueprints,
  });

  const blueprintsToReveal = (
    queryResult.data?.currentUser?.blueprintFootballCards?.nodes || []
  ).filter(
    bp => bp.blueprint?.revealStatus === BlueprintRevealStatus.REVEALABLE
  );

  let selectedRarity = null;
  for (const rarity of [
    Rarity.limited,
    Rarity.rare,
    Rarity.super_rare,
    Rarity.unique,
  ]) {
    if (blueprintsToReveal.some(bp => bp.rarityTyped === rarity)) {
      selectedRarity = rarity;
      break;
    }
  }

  return {
    hasCardsToReveal: blueprintsToReveal.length > 0,
    rarity: selectedRarity,
  };
};
