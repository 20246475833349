import { TypedDocumentNode, gql } from '@apollo/client';
import qs from 'qs';
import { generatePath } from 'react-router-dom';

import { CardRarity } from '@sorare/core/src/__generated__/globalTypes';
import {
  NBA_COMPOSE_TEAM_LEADERBOARDSLUG,
  NBA_COMPOSE_TEAM_LEADERBOARDSLUG_LINEUPID,
  NBA_PLAY_PRO_COMPETITION_DETAILS_LEADERBOARDSLUG,
  NBA_PLAY_PRO_COMPETITION_DETAILS_LEADERBOARDSLUG_LEAGUE_LEAGUESLUG,
} from '@sorare/core/src/constants/__generated__/routes';
import {
  NBA_INSTANT_BUY_MARKET,
  NBA_ONBOARDING,
  NBA_PRIMARY_MARKET,
} from '@sorare/core/src/constants/routes';
import { withFragments } from '@sorare/core/src/lib/gql';

import { getAlgoliaFiltersForRequirements } from 'components/leaderboard/Requirements';

import { getUnlockLink_NBALeaderboardRequirements } from './__generated__/routes.graphql';

export function getComposeTeamRoute({
  leaderboardSlug,
  lineupId,
  ...rest
}: {
  leaderboardSlug: string;
  lineupId?: string;
  [key: string]: string | undefined;
}) {
  let url;
  if (lineupId) {
    url = generatePath(NBA_COMPOSE_TEAM_LEADERBOARDSLUG_LINEUPID, {
      leaderboardSlug,
      lineupId,
    });
  } else {
    url = generatePath(NBA_COMPOSE_TEAM_LEADERBOARDSLUG, {
      leaderboardSlug,
    });
  }
  return url + qs.stringify(rest, { addQueryPrefix: true });
}

type GetUnlockLinkProps = {
  leaderboard: {
    displayName: string;
    slug: string;
    requirements: getUnlockLink_NBALeaderboardRequirements;
  };
};
export const getUnlockLink = withFragments(
  ({ leaderboard }: GetUnlockLinkProps) => {
    if (leaderboard.requirements.allowedRarities.includes(CardRarity.common)) {
      return generatePath(NBA_ONBOARDING);
    }

    // We offer starter bundles for limited contender, so we handle this
    // case separately
    if (leaderboard.displayName === 'Limited Contender') {
      return generatePath(NBA_INSTANT_BUY_MARKET);
    }

    return `${generatePath(NBA_PRIMARY_MARKET)}${qs.stringify(
      {
        ...getAlgoliaFiltersForRequirements(leaderboard.requirements),
        leaderboard: leaderboard.slug,
      },
      { addQueryPrefix: true }
    )}`;
  },
  {
    NBALeaderboardRequirements: gql`
      fragment getUnlockLink_NBALeaderboardRequirements on NBALeaderboardRequirements {
        allowedConference
        ...getAlgoliaFiltersForRequirements_NBALeaderboardRequirements
      }
      ${getAlgoliaFiltersForRequirements.fragments.NBALeaderboardRequirements}
    ` as TypedDocumentNode<getUnlockLink_NBALeaderboardRequirements>,
  }
);

export function getCompetitionDetailsRoute(
  leaderboardSlug: string,
  {
    leagueSlug = '',
    playerCardIndex,
    prevQueryParams = {},
    prevSearchParams = '',
  }: {
    leagueSlug?: string;
    playerCardIndex?: number;
    prevQueryParams?: { [key: string]: string | undefined };
    prevSearchParams?: string;
  } = {}
) {
  const path = generatePath(
    leagueSlug
      ? NBA_PLAY_PRO_COMPETITION_DETAILS_LEADERBOARDSLUG_LEAGUE_LEAGUESLUG
      : NBA_PLAY_PRO_COMPETITION_DETAILS_LEADERBOARDSLUG,
    { leaderboardSlug, leagueSlug }
  );
  const search =
    playerCardIndex !== undefined
      ? qs.stringify(
          { stats: playerCardIndex + 1, ...prevQueryParams },
          { addQueryPrefix: true }
        )
      : '';
  return `${path}${search}${prevSearchParams}`;
}
