import {
  OperationVariables,
  QueryResult,
  TypedDocumentNode,
  useLazyQuery,
} from '@apollo/client';
import { useCallback, useState } from 'react';

type Cursors = {
  pastEndCursor?: string | null;
  futureEndCursor?: string | null;
  pastFirstCursor?: string | null;
  futureFirstCursor?: string | null;
};

type LoadMore = (
  reload: boolean,
  direction: 'previous' | 'next',
  cursors: Cursors
) => Promise<any>;

type WithRelayPagination = {
  loadMore: LoadMore;
  page: number;
};

type PaginationVariables = {
  firstPast?: number;
  lastPast?: number;
  afterPast?: string | null;
  beforePast?: string | null;
  firstFuture?: number;
  lastFuture?: number;
  afterFuture?: string | null;
  beforeFuture?: string | null;
};

export const useGamesNavigatorQuery = <
  TData = any,
  TVariables = OperationVariables,
>(
  query: TypedDocumentNode<TData, TVariables & PaginationVariables>,
  variables: TVariables,
  pageSize: number
): QueryResult<TData, TVariables & PaginationVariables> &
  WithRelayPagination => {
  const [page, setPage] = useState(0);
  const [fetch, data] = useLazyQuery(query);

  if (!data.called) {
    fetch({
      variables: {
        ...variables,
        firstPast: 1,
        firstFuture: pageSize - 1,
      },
    });
  }

  const loadMore = useCallback(
    async (
      reload: boolean,
      direction: 'previous' | 'next',
      {
        pastEndCursor,
        pastFirstCursor,
        futureEndCursor,
        futureFirstCursor,
      }: Cursors
    ) => {
      const newPage = direction === 'next' ? page + 1 : page - 1;
      setPage(newPage);

      const shouldFetchPastGames = newPage < 0;
      const shouldFetchFutureGames = newPage > 0;

      const paginationVariables: PaginationVariables = {
        ...(newPage === 0 && {
          firstPast: 1,
          firstFuture: pageSize - 1,
        }),
        ...(direction === 'next' &&
          shouldFetchPastGames && {
            lastPast: pageSize,
            beforePast: pastFirstCursor,
          }),
        ...(direction === 'previous' &&
          shouldFetchPastGames && {
            firstPast: pageSize,
            afterPast: pastEndCursor,
          }),
        ...(direction === 'next' &&
          shouldFetchFutureGames && {
            firstFuture: pageSize,
            afterFuture: futureEndCursor,
          }),
        ...(direction === 'previous' &&
          shouldFetchFutureGames && {
            lastFuture: pageSize,
            beforeFuture: futureFirstCursor,
          }),
      };

      return fetch({
        variables: { ...variables, ...paginationVariables },
      });
    },
    [page, pageSize, fetch, variables]
  );

  return {
    ...data,
    page,
    loadMore,
  };
};
