import { faClock } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { differenceInCalendarMonths } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { To } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Skeleton } from 'atoms/animations/Skeleton';
import { FontAwesomeIcon } from 'atoms/icons';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { LabelL, LabelS, LabelXS } from 'atoms/typography';
import { useIntlContext } from 'contexts/intl';
import { useTimeLeft } from 'hooks/useTimeLeft';
import { shortTimeLeftMessages } from 'lib/glossary';
import { Link } from 'routing/Link';

import { FixtureState } from '../../../__generated__/globalTypes';

const StyledLink = styled(Link)<{ disabled?: boolean }>`
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;
const DateRangeLabel = styled(LabelL)`
  white-space: nowrap;
  color: var(--c-neutral-1000);
  &.closed {
    color: var(--c-neutral-500);
  }
`;

const wrapperStyles = css`
  padding: 0 var(--half-unit);
  border-radius: var(--double-unit);
  aspect-ratio: 1;
  /** flex items doesn't respond to width */
  min-width: 67px;
  max-width: 67px;
  height: 67px;
`;

const StyledSkeleton = styled(Skeleton)`
  /* default display: inline-block combining with height: 100%
  will create an extra height on the Skeleton and causes overflow-y */
  display: flex;
  ${wrapperStyles}
`;

const Wrapper = styled(Vertical).attrs({ gap: 0.5 })`
  ${wrapperStyles}
  background: var(--c-neutral-300);
  &.withTag {
    justify-content: flex-end;
  }
  &.selected {
    background: var(--c-neutral-1000);
    & ${DateRangeLabel} {
      color: var(--c-neutral-100);
    }
  }
  cursor: pointer;
`;
const MonthLabel = styled(LabelS)`
  text-transform: uppercase;
`;

const Dot = styled.div`
  background-color: var(--c-neutral-1000);
  border-radius: 50%;
  width: var(--half-unit);
  height: var(--half-unit);
`;

const Tag = styled(Horizontal).attrs({ gap: 0.5, center: true })`
  border-radius: 3px 3px 14px 14px;
  margin-bottom: var(--half-unit);
  padding: 0 var(--half-unit);
  width: 100%;
  white-space: nowrap;
`;

const LiveTag = styled(Tag)`
  background-color: var(--c-red-600);
`;

const LiveLabel = styled(LabelS)`
  text-transform: uppercase;
`;

const NextUpcomingTagWrapper = styled(Tag)`
  background-color: var(--c-yellow-600);
  & > svg {
    font-size: 0.675rem;
  }
`;

const NextUpcomingTag = ({ startDate }: { startDate: Date }) => {
  const { message } = useTimeLeft(startDate, {
    customMessages: shortTimeLeftMessages,
  });
  return (
    <NextUpcomingTagWrapper>
      <FontAwesomeIcon icon={faClock} size="xs" color="var(--c-neutral-300)" />
      <LabelS as="p" bold color="var(--c-neutral-300)">
        {message}
      </LabelS>
    </NextUpcomingTagWrapper>
  );
};

export type Props = {
  fixtureState: Nullable<FixtureState>;
  selected?: boolean;
  startDate?: Date;
  endDate?: Date;
  isUpcoming?: boolean;
  onClick?: () => void;
  loading?: boolean;
  to: To;
  state?: Record<string, unknown>;
  disabled?: boolean;
};

export const FixturePickerItem = ({
  fixtureState,
  startDate,
  endDate,
  isUpcoming,
  selected,
  onClick,
  loading,
  to,
  state,
  disabled,
}: Props) => {
  const { formatDate } = useIntlContext();
  if (loading) {
    return <StyledSkeleton />;
  }
  const getItemTag = () => {
    if (fixtureState === FixtureState.started) {
      return (
        <LiveTag>
          <Dot />
          <LiveLabel as="p" bold color="var(--c-neutral-1000)">
            <FormattedMessage
              id="FixturePickerItem.live"
              defaultMessage="Live"
            />
          </LiveLabel>
        </LiveTag>
      );
    }
    if (isUpcoming && startDate) {
      return <NextUpcomingTag startDate={startDate} />;
    }
    if (
      startDate &&
      // display year if the fixture is more than 11 months in the past
      // it should be enough to distinguish with current year fixtures
      differenceInCalendarMonths(new Date(), startDate) >= 11
    ) {
      return (
        <LabelXS bold as="p" color="var(--c-neutral-500)">
          {startDate.getFullYear()}
        </LabelXS>
      );
    }
    return null;
  };
  const itemTag = getItemTag();
  return (
    <StyledLink
      to={disabled ? '.' : to}
      onClick={onClick}
      replace
      state={state}
      disabled={disabled}
    >
      <Wrapper
        center
        className={classNames({
          withTag: fixtureState === FixtureState.started || isUpcoming,
          selected,
        })}
      >
        <Vertical gap={0} center>
          {startDate && endDate && (
            <>
              <MonthLabel as="p" bold color="var(--c-neutral-500)">
                {formatDate(startDate, { month: 'short' })}
              </MonthLabel>
              <DateRangeLabel
                as="p"
                bold
                className={classNames({
                  closed: fixtureState === FixtureState.closed,
                })}
              >
                {formatDate(startDate, { day: 'numeric' })}-
                {formatDate(endDate, { day: 'numeric' })}
              </DateRangeLabel>
            </>
          )}
        </Vertical>
        {itemTag}
      </Wrapper>
    </StyledLink>
  );
};
