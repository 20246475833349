import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { DivisionChange } from '@sorare/core/src/__generated__/globalTypes';
import { ChevronRightBold } from '@sorare/core/src/atoms/icons/ChevronRightBold';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { HeadlineL } from '@sorare/core/src/atoms/typography';

import { SquadRecapDiamonds } from './SquadRecapDiamonds';
import { SquadRecap_footballRivalsSeasonSquad } from './__generated__/index.graphql';

const Root = styled(Vertical).attrs({ gap: 2, center: true })`
  padding: var(--double-unit);
`;

type Props = {
  seasonSquad: SquadRecap_footballRivalsSeasonSquad;
  extra?: ReactNode;
  withChevron?: boolean;
};
export const SquadRecap = ({ seasonSquad, extra, withChevron }: Props) => {
  const { squad } = seasonSquad;
  const { logo, displayName, currentRanking, divisionConfig } = squad;
  const { pictureUrl } = logo || {};

  return (
    <Root>
      <SquadRecapDiamonds
        ranking={currentRanking?.ranking}
        divisionChange={currentRanking?.divisionChange || DivisionChange.STABLE}
        points={currentRanking?.points}
        pictureUrl={pictureUrl}
        badgeUrl={divisionConfig.badgeUrl}
      />
      <Horizontal>
        <HeadlineL className="text-center">{displayName}</HeadlineL>
        {withChevron && <ChevronRightBold />}
      </Horizontal>
      {extra}
    </Root>
  );
};

SquadRecap.fragments = {
  footballRivalsSeasonSquad: gql`
    fragment SquadRecap_footballRivalsSeasonSquad on FootballRivalsSeasonSquad {
      id
      topManagerScoresCount
      squad {
        id
        slug
        displayName
        divisionConfig {
          id
          badgeUrl
        }
        currentRanking {
          id
          ranking
          points
          divisionChange
        }
        logo {
          id
          pictureUrl
        }
      }
    }
  ` as TypedDocumentNode<SquadRecap_footballRivalsSeasonSquad>,
};
