import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';

import ButtonBase from 'atoms/buttons/ButtonBase';
import { FontAwesomeIcon } from 'atoms/icons';
import { Text16 } from 'atoms/typography';
import { DetailedScoreKey } from 'components/collections/DetailedScoreLine';
import { DetailsDialog } from 'components/collections/DetailsDialog';
import { ProgressBarThreshold } from 'components/collections/ProgressBar';
import howPointsWork from 'components/collections/assets/how-points-work.png';
import { CollectionsTeamShield } from 'lib/collections';

const BannerWrapper = styled(ButtonBase)`
  width: 100%;
  padding: var(--double-unit);
  text-align: left;
  background-color: var(--c-neutral-400);
  background-image: url(${howPointsWork});
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 110px;
  border-color: var(--c-neutral-300);
  border-style: solid;
  border-width: 2px 2px var(--half-unit);
  border-radius: var(--intermediate-unit);
  transition: background-color 0.1s ease-in-out;
  &:hover {
    background-color: rgba(var(--c-rgb-neutral-1000), 0.05);
  }
`;

const CompactWrapper = styled(ButtonBase)`
  display: flex;
  gap: var(--unit);
  align-items: center;
  color: var(--c-neutral-600);
  transition: color 0.1s ease-in-out;
  &:hover,
  &:focus {
    color: var(--c-neutral-700);
  }
`;

const HowPointsWork = styled(Text16)<{ $compact: boolean }>`
  ${({ $compact }) =>
    $compact &&
    css`
      width: 100%;
    `}
`;

type Props = {
  thresholds?: ProgressBarThreshold[];
  teamShield?: CollectionsTeamShield;
  showScoreLines?: DetailedScoreKey[];
  showShieldInfo?: boolean;
  showWarning?: boolean;
  compact?: boolean;
};

export const DetailsDialogBanner = ({ compact, ...rest }: Props) => {
  const [open, setOpen] = useState(false);
  const Wrapper = compact ? CompactWrapper : BannerWrapper;
  return (
    <>
      <Wrapper onClick={() => setOpen(true)} type="button">
        <HowPointsWork $compact={!!compact} bold={!compact}>
          <FormattedMessage
            id="collections.DetailsDialogBanner.cta"
            defaultMessage="How points work"
          />
        </HowPointsWork>
        {compact && <FontAwesomeIcon icon={faInfoCircle} size="xs" />}
      </Wrapper>
      <DetailsDialog open={open} onClose={() => setOpen(false)} {...rest} />
    </>
  );
};
