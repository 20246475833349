import { generatePath } from 'react-router-dom';

import { NBA_S3_PLAY_PRO_FIXTURE } from '@sorare/core/src/constants/__generated__/routes';
import { Navigate } from '@sorare/core/src/routing/Navigate';

const PlayProPage = () => {
  const fixture = 'ongoing';

  return (
    <Navigate
      to={generatePath(NBA_S3_PLAY_PRO_FIXTURE, {
        fixture,
      })}
      replace
    />
  );
};

export default PlayProPage;
