import { faHourglassStart } from '@fortawesome/pro-solid-svg-icons';
import { FormattedNumber, MessageDescriptor, useIntl } from 'react-intl';
import styled from 'styled-components';

import { Badge } from '@sorare/core/src/atoms/badges/Badge';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Clock } from '@sorare/core/src/atoms/icons/Clock';
import { Tooltip } from '@sorare/core/src/atoms/tooltip/Tooltip';
import { LabelM } from '@sorare/core/src/atoms/typography';
import { Scoring } from '@sorare/core/src/components/scoring/Scoring';
import {
  DidNotPlayLabel,
  NoGameLabel,
} from '@sorare/core/src/components/stats/PlayingLabel';
import {
  THRESHOLD_COLORS,
  findThreshold,
} from '@sorare/core/src/lib/footballThresholds';
import { fantasy, playerGameStatusLabels } from '@sorare/core/src/lib/glossary';
import { PlayerScoreStatus } from '@sorare/core/src/lib/so5';

const TooltipTitle = styled.div`
  text-align: center;
  font-weight: var(--t-bold);
`;

const ScoringText = styled(LabelM)`
  display: inline-flex;
  gap: var(--half-unit);
  align-items: center;
`;

const statusMessages = {
  [PlayerScoreStatus.REVIEWING]: playerGameStatusLabels.reviewing,
  [PlayerScoreStatus.DID_NOT_PLAY]: playerGameStatusLabels.did_not_play,
  [PlayerScoreStatus.PENDING]: playerGameStatusLabels.pending,
};

export const useGetPlayerScoreLabel = (
  score: number | null | undefined,
  status: PlayerScoreStatus | null | undefined,
  label?: string
) => {
  if (status === PlayerScoreStatus.PENDING) {
    return <Clock aria-label={label} />;
  }
  if (status === PlayerScoreStatus.NO_GAME) {
    return <NoGameLabel />;
  }
  if (status === PlayerScoreStatus.DID_NOT_PLAY) {
    return <DidNotPlayLabel />;
  }
  if (typeof score === 'number') {
    return <FormattedNumber value={score} maximumFractionDigits={0} />;
  }
  return null;
};

const getCustomChipColors = ({
  capped,
  threshold,
  noGame,
}: {
  capped?: boolean;
  threshold?:
    | 'veryLow'
    | 'low'
    | 'mediumLow'
    | 'medium'
    | 'mediumHigh'
    | 'high';
  noGame: boolean;
}) => {
  if (capped) {
    return 'var(--c-brand-600)';
  }
  if (threshold) {
    return THRESHOLD_COLORS[threshold];
  }
  if (noGame) {
    return 'var(--c-score-veryLow)';
  }
  return 'var(--c-neutral-700)';
};

type Props = {
  score?: number | null;
  status?: PlayerScoreStatus | null;
  showReviewing?: boolean;
  tooltipTitle?: MessageDescriptor;
  capped?: boolean;
  variant?: 'text';
};
export const PlayerScore = ({
  score,
  showReviewing = false,
  status,
  tooltipTitle,
  capped,
  variant,
}: Props) => {
  const threshold =
    typeof score === 'number' ? findThreshold(score) : undefined;
  const reviewing = showReviewing && status === PlayerScoreStatus.REVIEWING;
  const didNotPlay = status === PlayerScoreStatus.DID_NOT_PLAY;
  const pending = status === PlayerScoreStatus.PENDING;
  const noGame = status === PlayerScoreStatus.NO_GAME;

  const showScoring =
    typeof score === 'number' && threshold && !(pending || noGame || capped);
  const noGameVariant = noGame ? 'red' : undefined;
  const cappedVariant = capped ? 'blue' : undefined;
  const { formatMessage } = useIntl();

  const statusMessage =
    reviewing || didNotPlay || pending ? statusMessages[status] : fantasy.score;

  const label = formatMessage(tooltipTitle || statusMessage);
  const playerScoreLabel = useGetPlayerScoreLabel(score, status, label);

  return (
    <Tooltip placement="bottom" title={<TooltipTitle>{label}</TooltipTitle>}>
      {variant === 'text' ? (
        <ScoringText
          bold
          as="div"
          color={getCustomChipColors({ capped, threshold, noGame })}
        >
          {reviewing && <FontAwesomeIcon icon={faHourglassStart} size="xs" />}
          {playerScoreLabel}
        </ScoringText>
      ) : (
        <>
          {showScoring ? (
            <Scoring
              color={THRESHOLD_COLORS[threshold]}
              score={score}
              reviewing={reviewing}
              ariaLabel={label}
            />
          ) : (
            <Badge variant={noGameVariant || cappedVariant} ariaLabel={label}>
              {playerScoreLabel}
            </Badge>
          )}
        </>
      )}
    </Tooltip>
  );
};

export default PlayerScore;
