import { TypedDocumentNode, gql } from '@apollo/client';

import { So5LeaderboardType } from '@sorare/core/src/__generated__/globalTypes';
import { withFragments } from '@sorare/core/src/gql';
import useFeatureFlags from '@sorare/core/src/hooks/useFeatureFlags';

import {
  CAMPAIGN_LEADERBOARDS_MAP,
  isMbappeLeaderboard,
} from 'lib/leaderboard';

import { useDisplayOnboardingElements_so5Leaderboard } from './__generated__/index.graphql';

export const useComposeTeamOnboardFlow = (
  so5LeaderboardSlug: Nullable<string>
) => {
  const {
    flags: { ftueOnboardFlow = 'classic' },
  } = useFeatureFlags();
  if (isMbappeLeaderboard(so5LeaderboardSlug)) {
    return 'mbappe_special_weekly';
  }
  return ftueOnboardFlow as 'classic' | 'semi_pro' | 'f2p';
};

const ONBOARDING_LEADERBOARD_TYPES = [
  So5LeaderboardType.GLOBAL_ONBOARDING_COMMON,
  So5LeaderboardType.GLOBAL_ONBOARDING_PRO,
];

export const useDisplayOnboardingElements = withFragments(
  (so5Leaderboard: Nullable<useDisplayOnboardingElements_so5Leaderboard>) => {
    const { so5LeaderboardType, played, slug } = so5Leaderboard || {};
    return (
      ((!!so5LeaderboardType &&
        ONBOARDING_LEADERBOARD_TYPES.includes(so5LeaderboardType)) ||
        (slug && CAMPAIGN_LEADERBOARDS_MAP['mbappe-fc'].includes(slug))) &&
      !played
    );
  },
  {
    so5Leaderboard: gql`
      fragment useDisplayOnboardingElements_so5Leaderboard on So5Leaderboard {
        slug
        so5LeaderboardType
        played
      }
    ` as TypedDocumentNode<useDisplayOnboardingElements_so5Leaderboard>,
  }
);
