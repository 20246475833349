import { faHeart } from '@fortawesome/pro-solid-svg-icons';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Button, { Props as ButtonProps } from 'atoms/buttons/Button';
import { FontAwesomeIcon } from 'atoms/icons';
import { useCurrentUserContext } from 'contexts/currentUser';
import useSubscribe from 'hooks/subscriptions/useSubscribe';
import useUnsubscribe from 'hooks/subscriptions/useUnsubscribe';
import useLoggedCallback from 'hooks/useLoggedCallback';
import { glossary } from 'lib/glossary';

interface Subscription {
  slug: string;
}

interface Subscribable {
  __typename: string;
  slug: string;
}

interface Props extends ButtonProps {
  subscribable: Subscribable;
  initialSubscription?: Subscription | null;
  withIcon?: boolean;
  renderButton?: (
    props: Omit<ButtonProps, 'size'>,
    subscription: Subscription | null,
    hovered: boolean
  ) => React.JSX.Element;
}

const FollowButton = ({
  subscribable,
  initialSubscription = null,
  withIcon,
  renderButton,
  ...rest
}: Props) => {
  const [subscription, setSubscription] = useState<Subscription | null>(
    initialSubscription
  );

  useEffect(() => {
    setSubscription(initialSubscription);
  }, [initialSubscription]);

  const [hover, setHover] = useState<boolean>(false);
  const unsubscribe = useUnsubscribe();
  const subscribe = useSubscribe();
  const { currentUser } = useCurrentUserContext();

  const loggedHandler = useLoggedCallback<
    React.MouseEvent<HTMLElement, MouseEvent>
  >(event => {
    event.stopPropagation();

    if (subscription) {
      unsubscribe(subscription).then(() => setSubscription(null));
    } else {
      subscribe(subscribable).then(({ data }) => {
        if (!data) return;
        setSubscription({
          slug: data.createSubscription!.subscription!.slug,
        });
      });
    }
  });

  if (
    currentUser?.slug === subscribable.slug &&
    subscribable.__typename === 'User'
  ) {
    return null;
  }

  const color = subscription && hover ? 'red' : 'tertiary';
  const messageIfHover = hover ? (
    <FormattedMessage {...glossary.unfollow} />
  ) : (
    <FormattedMessage {...glossary.followed} />
  );

  const label = subscription ? (
    messageIfHover
  ) : (
    <FormattedMessage {...glossary.follow} />
  );

  const buttonProps = {
    onClick: loggedHandler,
    onMouseEnter: () => {
      if (subscription) setHover(true);
    },
    onMouseLeave: () => {
      if (subscription) setHover(false);
    },
    children: label,
    ...rest,
  };

  if (renderButton) {
    return renderButton(buttonProps, subscription, hover);
  }

  return (
    <Button color={color} {...buttonProps}>
      {withIcon && <FontAwesomeIcon icon={faHeart} />}
      <span>
        {subscription ? (
          messageIfHover
        ) : (
          <FormattedMessage {...glossary.follow} />
        )}
      </span>
    </Button>
  );
};

export default FollowButton;
