import { SpecificNews } from '@sorare/core/src/components/activity/SpecificNews';
import { AppLayout } from '@sorare/core/src/components/navigation/AppLayout';

const ActivityNewsShow = () => {
  return (
    <AppLayout>
      <SpecificNews />
    </AppLayout>
  );
};

export default ActivityNewsShow;
