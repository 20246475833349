import { CSSProperties } from 'react';
import styled from 'styled-components';

import { Tooltip } from 'atoms/tooltip/Tooltip';
import { CLUB_PLACEHOLDER } from 'constants/assets';

type Props = {
  name?: string;
  avatarUrl?: string | null;
  withTooltip?: boolean;
  className?: string;
  size?: number;
  fallbackUrl?: string;
};

const Root = styled.img`
  border-radius: 0;
  width: var(--size, calc(var(--unit) * 5));
  height: var(--size, calc(var(--unit) * 5));
  user-select: none;
  object-fit: contain;
`;

export const Avatar = (props: Props) => {
  const {
    fallbackUrl = CLUB_PLACEHOLDER,
    name,
    avatarUrl,
    withTooltip = false,
    className,
    size,
  } = props;
  const avatar = (
    <Root
      className={className}
      alt={name}
      src={avatarUrl || fallbackUrl}
      onError={e => {
        if (e.target instanceof HTMLImageElement) {
          e.target.src = fallbackUrl;
        }
      }}
      style={size ? ({ '--size': `${size}px` } as CSSProperties) : undefined}
    />
  );

  if (withTooltip && name) {
    return (
      <Tooltip title={name}>
        <div>{avatar}</div>
      </Tooltip>
    );
  }
  return avatar;
};

export default Avatar;
