import { Navigate, Outlet, generatePath } from 'react-router-dom';

import { FOOTBALL_PLAY_RIVALS_LEADERBOARD_SEASONSLUG } from '@sorare/core/src/constants/__generated__/routes';
import { DEFAULT_SEASON_SLUG } from '@sorare/core/src/constants/routes';
import { useTypedParams } from '@sorare/core/src/lib/routing';

import { LayoutParams } from './__generated__/routeParams';

const SeasonsLeaderboardLayout = () => {
  const { seasonSlug } = useTypedParams<LayoutParams>();

  if (!seasonSlug) {
    return (
      <Navigate
        to={generatePath(FOOTBALL_PLAY_RIVALS_LEADERBOARD_SEASONSLUG, {
          seasonSlug: DEFAULT_SEASON_SLUG,
        })}
        replace
      />
    );
  }

  return <Outlet />;
};
export default SeasonsLeaderboardLayout;
