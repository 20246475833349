import styled from 'styled-components';

import { DivisionChange } from '@sorare/core/src/__generated__/globalTypes';

import { RankDiamond } from 'components/rivals/ArenaPage/ArenaOverview/RankDiamond';
import { RepDiamond } from 'components/rivals/ArenaPage/ArenaOverview/RepDiamond';

const DiamondArrangementWrapper = styled.div`
  --width: 80px;
  --separation: 16px;
  --large-offset: calc(var(--width) + var(--separation));
  --small-offset: calc(0.5 * var(--large-offset));
  position: relative;
  overflow: visible;
  height: calc(2 * var(--width) + var(--separation));
  width: calc(2 * var(--width) + var(--separation));
`;
const Logo = styled.img`
  width: var(--width);
  height: var(--width);
  object-fit: contain;
`;
const SquadLogo = styled(Logo)`
  position: absolute;
  top: 0;
  left: var(--small-offset);
`;
const RepDiamondWrapper = styled.div`
  position: absolute;
  left: var(--large-offset);
  top: var(--small-offset);
`;
const DivisionLogo = styled(Logo)`
  position: absolute;
  top: var(--large-offset);
  left: var(--small-offset);
`;
const RankDiamondWrapper = styled.div`
  position: absolute;
  left: 0;
  top: var(--small-offset);
`;

type Props = {
  points?: number;
  pictureUrl?: string;
  badgeUrl?: string;
  ranking?: number;
  divisionChange: DivisionChange;
};
export const SquadRecapDiamonds = ({
  points,
  pictureUrl,
  badgeUrl,
  ranking,
  divisionChange,
}: Props) => {
  return (
    <DiamondArrangementWrapper>
      {pictureUrl && <SquadLogo src={pictureUrl} alt="" />}
      <RepDiamondWrapper>
        <RepDiamond points={points} />
      </RepDiamondWrapper>
      {badgeUrl && <DivisionLogo src={badgeUrl} alt="" />}
      <RankDiamondWrapper>
        <RankDiamond ranking={ranking} state={divisionChange} />
      </RankDiamondWrapper>
    </DiamondArrangementWrapper>
  );
};
