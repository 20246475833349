import { FormattedNumber } from 'react-intl';
import styled from 'styled-components';

import { Tag } from '@sorare/core/src/atoms/ui/Tag';

function isNumber(value: string | number | undefined | null): value is number {
  return typeof value === 'number';
}

interface Props {
  score?: number | string | null;
  className?: string;
  round?: boolean;
  final?: boolean;
}

const NonFinalTag = styled(Tag)`
  background-color: var(--c-neutral-100);
  color: var(--c-neutral-800);
  border: 1px solid currentColor;
`;

export const PlayerScore = ({
  score,
  className,
  round = false,
  final = true,
}: Props) => {
  const noGame = !isNumber(score);

  if (noGame) {
    return (
      <Tag color="grey" round={round} className={className}>
        <div>{score}</div>
      </Tag>
    );
  }

  if (!final) {
    return (
      <NonFinalTag round={round} className={className}>
        <FormattedNumber value={score} maximumFractionDigits={0} />
      </NonFinalTag>
    );
  }

  return (
    <Tag color="black" round={round} className={className}>
      <FormattedNumber value={score} maximumFractionDigits={0} />
    </Tag>
  );
};

export default PlayerScore;
