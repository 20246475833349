import { TypedDocumentNode, gql } from '@apollo/client';
import { Navigate, Outlet, generatePath } from 'react-router-dom';

import LoadingIndicator from '@sorare/core/src/atoms/loader/LoadingIndicator';
import {
  FOOTBALL_COMPOSE_TEAM_LEADERBOARDSLUG_LINEUP_LINEUPID,
  FOOTBALL_PLAY_PRO,
} from '@sorare/core/src/constants/__generated__/routes';
import idFromObject from '@sorare/core/src/gql/idFromObject';
import useQuery from '@sorare/core/src/hooks/graphql/useQuery';
import { useTitleAndDescription } from '@sorare/core/src/hooks/useTitleAndDescription';
import { useTypedParams } from '@sorare/core/src/lib/routing';
import { metadatas } from '@sorare/core/src/lib/seo/football';
import RequireAuth from '@sorare/core/src/routing/RequireAuth';

import {
  ComposeTeamLayoutQuery,
  ComposeTeamLayoutQueryVariables,
} from './__generated__/layout.graphql';
import { LayoutParams } from './__generated__/routeParams';

const COMPOSE_TEAM_LAYOUT_QUERY = gql`
  query ComposeTeamLayoutQuery($leaderboardSlug: String!) {
    so5 {
      so5Leaderboard(slug: $leaderboardSlug) {
        slug
        teamsCap
        composeBuy
        displayName
        canCompose {
          value
        }
        so5League {
          slug
          name
        }
        so5Fixture {
          slug
          mySo5LineupsPaginated(
            so5LeaderboardSlug: $leaderboardSlug
            first: 1
          ) {
            nodes {
              id
            }
          }
        }
      }
    }
  }
` as TypedDocumentNode<ComposeTeamLayoutQuery, ComposeTeamLayoutQueryVariables>;
const ComposeTeamLayout = () => {
  const { leaderboardSlug, lineupId } = useTypedParams<LayoutParams>();

  const { data: leaderboardData, loading } = useQuery(
    COMPOSE_TEAM_LAYOUT_QUERY,
    {
      variables: {
        leaderboardSlug,
      },
    }
  );

  const { so5Leaderboard } = leaderboardData?.so5 || {};
  const { so5Fixture, teamsCap, composeBuy } = so5Leaderboard || {};
  const { mySo5LineupsPaginated } = so5Fixture || {};
  const firstValidLineupId = idFromObject(
    mySo5LineupsPaginated?.nodes?.[0]?.id
  );
  const navigateToValidLineup =
    firstValidLineupId && firstValidLineupId !== lineupId && teamsCap === 1;

  useTitleAndDescription(metadatas.composeTeam.title, undefined, {
    leaderboardName: so5Leaderboard?.displayName,
  });

  if (!leaderboardData && loading) {
    return <LoadingIndicator fullScreen />;
  }

  if (navigateToValidLineup) {
    return (
      <Navigate
        to={generatePath(
          FOOTBALL_COMPOSE_TEAM_LEADERBOARDSLUG_LINEUP_LINEUPID,
          {
            leaderboardSlug,
            lineupId: firstValidLineupId,
          }
        )}
        replace
      />
    );
  }

  if (!leaderboardData?.so5.so5Leaderboard.canCompose.value && !composeBuy) {
    return <Navigate to={FOOTBALL_PLAY_PRO} replace />;
  }

  return (
    <RequireAuth>
      <Outlet />
    </RequireAuth>
  );
};

export default ComposeTeamLayout;
