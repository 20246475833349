import styled, { css } from 'styled-components';

import { laptopAndAbove } from '@sorare/core/src/style/mediaQuery';

import textureAvif from './assets/texture.avif';
import texture from './assets/texture.jpg';

export const RigidSquareTitle = styled.h1`
  display: inline-flex;
  flex-direction: column;
  font-family: var(--rigid-square);
  font-size: clamp(43px, 4.8vw, 80px);
  text-transform: uppercase;
  font-weight: bold;
  line-height: 0.8;
  max-width: 100%;
  text-align: center;
  @supports (background-clip: text) {
    background-size: contain;
    background-clip: text;
    background-image: url(${texture});
    background-image: image-set(
      url(${textureAvif}) type('image/avif'),
      url(${texture})
    );
    color: transparent;
  }
  @media ${laptopAndAbove} {
    font-size: 92px;
    letter-spacing: -0.04em;
    &.left {
      text-align: left;
    }
  }
`;
const rigidSquare = css`
  font-family: var(--rigid-square);
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1;
`;
export const RigidSquareTitleS = styled.h2`
  ${rigidSquare}
  font-size: clamp(27px, 4.8vw, 35px);
`;
export const RigidSquareTitleM = styled.h2`
  ${rigidSquare}
  font-size: clamp(27px, 4.8vw, 55px);
`;
export const RigidSquareTitleL = styled.h2`
  ${rigidSquare}
  font-size: clamp(37px, 4.8vw, 68px);
`;
