import classNames from 'classnames';
import { CSSProperties, Children, ReactNode, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import ButtonBase from 'atoms/buttons/ButtonBase';
import { ChevronDownBold } from 'atoms/icons/ChevronDownBold';
import { LabelS } from 'atoms/typography';
import { useResizeObserver } from 'hooks/ui/useResizeObserver';
import { glossary } from 'lib/glossary';
import { unitMapping } from 'lib/style';

import { Horizontal, Vertical } from '../flex';

const Root = styled(Vertical)`
  position: relative;
  isolation: isolate;
  gap: var(--gap);
  &.collapsed {
    cursor: pointer;
  }
  &.collapsed > * {
    pointer-events: none;
  }
`;
const Trigger = styled.div`
  position: absolute;
  inset: 0;
  z-index: calc(var(--listSize) + 1);
`;
const ItemWrapper = styled.div`
  z-index: calc(var(--listSize) - var(--index));
  transition: all 0.5s;
  position: relative;
  .collapsed &:nth-child(3) {
    margin-top: calc(-1 * var(--firstItemHeight));
    scale: 0.95;
  }
  .collapsed &:nth-child(4) {
    margin-top: calc(-1 * var(--firstItemHeight));
    scale: 0.9;
  }
  .collapsed &:nth-child(n + 5) {
    margin-top: calc(-1 * var(--firstItemHeight) - var(--gap));
    scale: 0.9;
  }
`;
const StyledButtonBase = styled(ButtonBase)`
  position: relative;
  transition: all 0.5s;
  opacity: 1;
  z-index: 0;
  .collapsed & {
    opacity: 0;
    margin-top: calc(-2 * var(--double-unit));
  }
`;
const StyledHorizontal = styled(Horizontal)`
  align-self: flex-end;
  justify-content: flex-end;
`;
const ChevronUp = styled(ChevronDownBold)`
  transform: rotate(180deg);
`;

type ShowLessProps = {
  onClick: () => void;
};
const ShowLess = ({ onClick }: ShowLessProps) => {
  return (
    <StyledButtonBase onClick={onClick}>
      <StyledHorizontal gap={0.5}>
        <ChevronUp />
        <LabelS color="var(--c-neutral-800)">
          <FormattedMessage {...glossary.showLess} />
        </LabelS>
      </StyledHorizontal>
    </StyledButtonBase>
  );
};

type Props = {
  children: ReactNode;
  gap?: keyof typeof unitMapping;
  startsCollapsed?: boolean;
};
export const CollapsibleTileContainer = ({
  children,
  gap = 2,
  startsCollapsed = true,
}: Props) => {
  const [collapsed, setCollapsed] = useState(startsCollapsed);
  const firstItemRef = useRef<HTMLDivElement | null>();
  const [firstItemRefState, setFirstItemRefState] =
    useState<HTMLDivElement | null>(null);
  const [firstItemHeight, setFirstItemHeight] = useState<number | null>(null);
  if (firstItemRefState?.clientHeight && firstItemHeight === null) {
    setFirstItemHeight(firstItemRefState.clientHeight);
  }
  useResizeObserver(firstItemRef, () => {
    setFirstItemHeight(firstItemRef.current?.clientHeight || 0);
  });
  const childrenArray = Children.toArray(children);

  if (childrenArray.length === 1) {
    return children;
  }

  return (
    <Root
      className={classNames({ collapsed })}
      style={{ '--gap': unitMapping[gap] } as CSSProperties}
      onClick={() => {
        if (collapsed) {
          setCollapsed(false);
        }
      }}
    >
      <ShowLess
        onClick={() => {
          setCollapsed(true);
        }}
      />
      {childrenArray.map((child, index) => (
        <ItemWrapper
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          ref={
            index === 0
              ? ref => {
                  firstItemRef.current = ref;
                  setFirstItemRefState(ref);
                }
              : undefined
          }
          style={
            {
              '--index': index,
              '--listSize': childrenArray.length,
              '--firstItemHeight': `${firstItemHeight}px`,
            } as CSSProperties
          }
        >
          {child}
        </ItemWrapper>
      ))}
      <ShowLess
        onClick={() => {
          setCollapsed(true);
        }}
      />
      {collapsed && <Trigger onClick={() => setCollapsed(false)} />}
    </Root>
  );
};
