import { TypedDocumentNode, gql } from '@apollo/client';
import { ComponentProps } from 'react';

import { FixturePickerItem } from '@sorare/core/src/components/lobby/FixturePickerItem';

import { NBAFixturePickerItem_nbaFixture } from './__generated__/index.graphql';

type Props = {
  nbaFixture: Nullable<NBAFixturePickerItem_nbaFixture>;
} & Omit<ComponentProps<typeof FixturePickerItem>, 'fixtureState'>;

export const NBAFixturePickerItem = ({ nbaFixture, ...otherProps }: Props) => {
  return (
    <FixturePickerItem
      {...otherProps}
      fixtureState={nbaFixture?.fixtureState}
      startDate={nbaFixture?.startDate}
      endDate={nbaFixture?.endDate}
    />
  );
};

NBAFixturePickerItem.fragments = {
  nbaFixture: gql`
    fragment NBAFixturePickerItem_nbaFixture on NBAFixture {
      slug
      endDate
      startDate
      fixtureState
    }
  ` as TypedDocumentNode<NBAFixturePickerItem_nbaFixture>,
};
