import classnames from 'classnames';
import { ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDebounce } from 'react-use';
import styled from 'styled-components';

import Lightning from 'atoms/icons/Lightning';
import { Horizontal } from 'atoms/layout/flex';
import completed from 'components/collections/assets/completed.svg';
import { CollectionsTeamShield } from 'lib/collections';
import { fantasy } from 'lib/glossary';
import { absCenter } from 'lib/style';
import { tabletAndAbove } from 'style/mediaQuery';

import { ClubIcon } from './ClubIcon';

export const DEFAULT_THRESHOLDS: ProgressBarThreshold[] = [
  { value: 0, bonus: '-' },
  { value: 35, bonus: '+1%' },
  { value: 100, bonus: '+2%' },
  { value: 250, bonus: '+3%' },
  { value: 500, bonus: '+4%' },
  { value: 750, bonus: '+5%' },
];

export type ProgressBarThreshold = {
  value: number;
  bonus?: ReactNode;
};

const getProgress = (score: number, thresholds: ProgressBarThreshold[]) => {
  const stepLength = 100 / (thresholds.length - 1);
  const values = thresholds.map(({ value }) => value);
  const lastIndex = values.length - 1;
  const closestNextStep =
    score > values[lastIndex] ? lastIndex : values.findIndex(x => score <= x);
  const closestPreviousStep = closestNextStep === 0 ? 0 : closestNextStep - 1;
  const delta = score - values[closestPreviousStep];
  const maxDelta = values[closestNextStep] - values[closestPreviousStep] || 1;

  return Math.min(stepLength * (closestPreviousStep + delta / maxDelta), 100);
};

const insertIconScoreIntoThresholds = (
  thresholds: ProgressBarThreshold[],
  value: number
): ProgressBarThreshold[] => {
  let index = 0;
  for (let i = 1; i < thresholds.length; i += 1) {
    if (value > thresholds[i].value) {
      index = i + 1;
    }
  }

  return [...thresholds.slice(0, index), { value }, ...thresholds.slice(index)];
};

const DURATION = 1;
const DOT_SIZE = 13;
const Root = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5px;
  margin: var(--triple-unit) var(--double-unit) var(--triple-unit) 0;
  &:before {
    position: absolute;
    z-index: 0;
    top: 0;
    right: ${DOT_SIZE / 2}px;
    bottom: 0;
    left: ${DOT_SIZE / 2}px;
    color: var(--c-static-neutral-600);
    background: currentcolor;
    border-radius: 2em;
    content: '';
  }
  &.extraMargin {
    margin: calc(6 * var(--unit)) var(--double-unit) calc(6 * var(--unit)) 0;
  }
`;
const Bar = styled.div`
  position: absolute;
  left: ${DOT_SIZE / 2}px;
  top: 0;
  border-radius: 2em;
  background: var(--c-green-600);
  height: 100%;
  width: 0;
  transition-timing-function: ease-in-out;
  transition-property: width;
`;
const Completed = styled.div`
  ${absCenter}
  display: block;
  width: 100px;
  height: 100px;
  background: url(${completed});
  background-position: 0 0;
  transition: background-position 1s steps(28);
  transition-duration: 0;
  transition-delay: ${DURATION}s;
  &.active {
    transition-duration: 1s;
    background-position: -2800px 0;
  }
`;
const StepContainer = styled.div`
  position: relative;
`;
const Top = styled(Horizontal).attrs({ gap: 0.5 })`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  bottom: 100%;
  &.left {
    transform: none;
    left: 0;
  }
`;
const Bottom = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  top: 100%;
  &.left {
    transform: none;
    left: 0;
  }
`;
const Step = styled.div`
  position: relative;
  width: ${DOT_SIZE}px;
  height: ${DOT_SIZE}px;
  border-radius: 50%;
  background-color: var(--c-static-neutral-600);
  transition: 0.1s ease background-color;
  &.completed {
    background-color: var(--c-green-600);
  }
  &.hide {
    opacity: 0;
  }
  &.hideLastStep {
    background-color: transparent;
  }
`;
const Text = styled.div`
  font: var(--t-bold) var(--t-14);
  @media ${tabletAndAbove} {
    font: var(--t-bold) var(--t-16);
  }
`;
const BonusText = styled(Text)`
  color: var(--c-neutral-600);
  &.isCompleted {
    color: var(--c-green-600);
  }
`;
const LightningWrapper = styled.span`
  width: var(--intermediate-unit);
  display: flex;
  justify-content: center;
`;
const ScoreLabel = styled.div`
  position: absolute;
  left: 0;
  top: calc(-6 * var(--unit));
  color: var(--c-neutral-1000);
`;
const BonusLabel = styled.div`
  position: absolute;
  left: 0;
  top: var(--triple-unit);
  color: var(--c-neutral-600);
`;

type Props = {
  disableAnimation?: boolean;
  thresholds?: ProgressBarThreshold[];
  collectionEventStep?: ProgressBarThreshold | null;
  score?: number;
  showLabel?: boolean;
  teamShield?: CollectionsTeamShield;
};

export const ProgressBar = ({
  disableAnimation = false,
  thresholds: thresholdsProp = DEFAULT_THRESHOLDS,
  collectionEventStep,
  score: scoreProp,
  showLabel = false,
  teamShield,
}: Props) => {
  const { score: teamShieldScore, name, pictureUrl } = teamShield || {};

  const collectionThresholds =
    teamShieldScore && pictureUrl
      ? insertIconScoreIntoThresholds(thresholdsProp, teamShieldScore)
      : thresholdsProp;

  const thresholds = [...collectionThresholds, collectionEventStep]
    .filter(p => p)
    .sort((a, b) => a.value - b.value);

  const score = scoreProp ?? thresholdsProp[thresholdsProp.length - 1].value;
  const progress = getProgress(score, thresholds);
  const [debouncedProgress, setDebouncedProgress] = useState(
    disableAnimation ? progress : 0
  );
  const completedSteps =
    score === 0
      ? []
      : thresholds.filter(
          (_, i) => (i / (thresholds.length - 1)) * 100 <= debouncedProgress
        );

  useDebounce(() => setDebouncedProgress(progress), 0, [progress]);

  return (
    <Root className={classnames({ extraMargin: showLabel })}>
      <Bar
        style={
          {
            width: `calc((100% - ${thresholds.length * DOT_SIZE}px) * ${
              progress / 100
            } + ${Math.max(0, completedSteps.length - 0.5) * DOT_SIZE}px )`,
            transitionDuration: disableAnimation ? 'none' : `${DURATION}s`,
          } as React.CSSProperties
        }
      />
      {showLabel && (
        <>
          <ScoreLabel>
            <Text>
              <FormattedMessage {...fantasy.score} />
            </Text>
          </ScoreLabel>
          <BonusLabel>
            <Text>
              <FormattedMessage {...fantasy.bonus} />
            </Text>
          </BonusLabel>
        </>
      )}
      {thresholds.map(({ value, bonus }, i) => {
        const isLastThreshold = i === thresholds.length - 1;
        const isCompleted = !!completedSteps[i];

        return (
          // eslint-disable-next-line react/no-array-index-key
          <StepContainer key={i}>
            <Top className={classnames({ left: i === 0 })}>
              <Text>
                {value}
                {isLastThreshold && '+'}
              </Text>
              <LightningWrapper>
                <Lightning />
              </LightningWrapper>
            </Top>
            <Step
              className={classnames({
                completed: isCompleted,
                hideLastStep: !disableAnimation && i + 1 === thresholds.length,
              })}
              style={{
                transitionDelay: disableAnimation
                  ? 'none'
                  : `${
                      (DURATION /
                        Math.ceil(
                          (thresholds.length * debouncedProgress) / 100
                        )) *
                      (i + 1)
                    }s`,
                transitionDuration: disableAnimation ? 'none' : `.3s`,
              }}
            >
              {i >= thresholds.length - 1 && !disableAnimation && (
                <Completed
                  className={classnames({ active: debouncedProgress === 100 })}
                />
              )}
            </Step>
            <Bottom className={classnames({ left: i === 0 })}>
              <BonusText className={classnames({ isCompleted })}>
                {bonus || (
                  <ClubIcon
                    clubIconUrl={pictureUrl || ''}
                    isCompleted={isCompleted}
                    alt={name || ''}
                  />
                )}
              </BonusText>
            </Bottom>
          </StepContainer>
        );
      })}
    </Root>
  );
};
