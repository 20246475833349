import { useEffect, useMemo, useRef } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { Sport } from '__generated__/globalTypes';
import { MLB } from 'constants/__generated__/routes';

import { FOOTBALL_WILDCARD, NBA_WILDCARD, catchAll } from '../constants/routes';

const getSportFromPathname = (pathname: string) => {
  if (matchPath(NBA_WILDCARD, pathname)) {
    return Sport.NBA;
  }
  if (matchPath(catchAll(MLB), pathname)) {
    return Sport.BASEBALL;
  }
  if (matchPath(FOOTBALL_WILDCARD, pathname)) {
    return Sport.FOOTBALL;
  }
  return undefined;
};

export const useSport = () => {
  const { pathname } = useLocation();
  const pathnameRef = useRef(pathname);

  useEffect(() => {
    pathnameRef.current = pathname;
  }, [pathname]);

  return useMemo(() => {
    return getSportFromPathname(pathnameRef.current);
  }, []);
};
