import {
  Navigate as BaseNavigate,
  NavigateProps as BaseNavigateProps,
  generatePath,
  useParams,
  useSearchParams,
} from 'react-router-dom';

const concatPath = (to: string, searchParams: string) => {
  const containsSearchParams = to.includes('?');
  if (!searchParams) {
    return to;
  }
  return `${to}${containsSearchParams ? '&' : '?'}${searchParams}`;
};

export type NavigateProps = BaseNavigateProps & {
  keepParams?: boolean;
  keepSearchParams?: boolean;
};
export const Navigate = ({
  keepParams,
  keepSearchParams,
  to,
  ...otherProps
}: NavigateProps) => {
  const [searchParams] = useSearchParams();
  const params = useParams();

  const getTo = () => {
    if (typeof to !== 'string') {
      return to;
    }
    const toWithParams = keepParams ? generatePath(to, params) : to;

    const toWithSearchParams = keepSearchParams
      ? concatPath(toWithParams, searchParams.toString())
      : toWithParams;
    return toWithSearchParams;
  };

  return <BaseNavigate {...otherProps} to={getTo()} />;
};
