import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Vertical } from 'atoms/layout/flex';
import { LabelM, Text16, Title3, Title4 } from 'atoms/typography';
import DetailedScoreLine, {
  DetailedScoreKey,
  detailedScores,
} from 'components/collections/DetailedScoreLine';
import shieldUrl from 'components/collections/DetailsDialog/assets/shield.svg';
import {
  ProgressBar,
  ProgressBarThreshold,
} from 'components/collections/ProgressBar';
import Warning from 'components/collections/Warning';
import Dialog from 'components/dialog';
import { CollectionsTeamShield } from 'lib/collections';

const Root = styled(Vertical).attrs({ gap: 4 })`
  padding: var(--unit) var(--triple-unit) var(--triple-unit) var(--triple-unit);
`;
const List = styled(Vertical).attrs({ gap: 0 })`
  border-radius: var(--double-unit);
  background: var(--c-neutral-300);
`;

const ClubBadgeWrapper = styled.div`
  display: flex;
  gap: var(--unit);
`;

type Props = {
  onClose: () => void;
  open: boolean;
  thresholds?: ProgressBarThreshold[];
  teamShield?: CollectionsTeamShield;
  showScoreLines?: DetailedScoreKey[];
  showShieldInfo?: boolean;
  showWarning?: boolean;
};

export const DetailsDialog = ({
  onClose,
  open,
  thresholds,
  teamShield,
  showScoreLines,
  showShieldInfo,
  showWarning,
}: Props) => {
  const { score: shieldScore } = teamShield || {};

  const scoreLineKeys = (
    Object.keys(detailedScores) as DetailedScoreKey[]
  ).filter(key => !showScoreLines || showScoreLines.includes(key));

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      onClose={onClose}
      title={
        <Title4 className="text-center">
          <FormattedMessage
            id="collections.DetailsDialog.title"
            defaultMessage="The Collection Game"
          />
        </Title4>
      }
    >
      <Root>
        <div>
          <Title3>
            <FormattedMessage
              id="collections.DetailsDialog.section1.title"
              defaultMessage="The Collection Scoring Matrix"
            />
          </Title3>
          <Text16 color="var(--c-neutral-600)">
            <FormattedMessage
              id="collections.DetailsDialog.section1.description"
              defaultMessage="Each card has a collection score based on the following"
            />
          </Text16>
        </div>
        <List>
          {scoreLineKeys.map(id => {
            return (
              <DetailedScoreLine
                key={id}
                {...detailedScores[id as DetailedScoreKey]}
              />
            );
          })}
        </List>
        {showWarning && <Warning />}
        <div>
          <Title3>
            <FormattedMessage
              id="collections.DetailsDialog.section2.title"
              defaultMessage="The Collection Bonus"
            />
          </Title3>
          <Vertical>
            <Text16 color="var(--c-neutral-600)">
              <FormattedMessage
                id="collections.DetailsDialog.section2.description"
                defaultMessage="An additional scoring bonus on a player’s in-game score – will apply in competitions where XP applies"
              />
            </Text16>
            <ProgressBar
              disableAnimation
              showLabel
              teamShield={teamShield}
              thresholds={thresholds}
            />
            {showShieldInfo && (
              <ClubBadgeWrapper>
                <img src={shieldUrl} alt="" />
                <LabelM bold color="var(--c-neutral-500)">
                  {shieldScore ? (
                    <FormattedMessage
                      id="DetailsDialog.clubBadge"
                      defaultMessage="Score {shieldScore} points to get an exclusive club badge"
                      values={{ shieldScore }}
                    />
                  ) : (
                    <FormattedMessage
                      id="DetailsDialog.noScoreClubBadge"
                      defaultMessage="You can earn an exclusive club badge by reaching a specific score threshold, which varies based on the album's scarcity. This only applies to the latest season albums and licensed clubs."
                    />
                  )}
                </LabelM>
              </ClubBadgeWrapper>
            )}
          </Vertical>
        </div>
      </Root>
    </Dialog>
  );
};
