import { FormattedNumber } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { Rarity, Sport } from '__generated__/globalTypes';
import Button from 'atoms/buttons/Button';
import { Box } from 'atoms/icons/Box';
import { Cards } from 'atoms/icons/Cards';
import { Shards } from 'atoms/icons/Shards';
import { LabelL } from 'atoms/typography';
import { MUIBadge } from 'atoms/ui/MUIBadge';
import {
  FOOTBALL_PLAY_INVENTORY_BOXES_TYPE,
  FOOTBALL_PLAY_INVENTORY_REVEALABLE_CARDS_TYPE,
  FOOTBALL_PLAY_INVENTORY_SHARDS_TYPE,
} from 'constants/__generated__/routes';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useSportContext } from 'contexts/sport';
import useFeatureFlags from 'hooks/useFeatureFlags';

type Props = {
  onClick?: () => void;
};

export const InventoryBalance = ({ onClick }: Props) => {
  const {
    flags: { useRevealCeremony = false },
  } = useFeatureFlags();
  const { currentUser } = useCurrentUserContext();
  const { sport } = useSportContext();

  if (sport !== Sport.FOOTBALL) {
    return null;
  }

  const chests = currentUser?.shardsChestsOfFootball || [];
  const totalShards = chests.reduce(
    (acc, chest) => acc + chest.cardShardsCount,
    0
  );

  const chestWithThresholdUnlocked = chests.find(
    chest => chest.newThresholdUnlocked
  );

  const totalBlueprints = currentUser?.blueprintFootballCards.totalCount ?? 0;

  return (
    <>
      {useRevealCeremony && totalBlueprints > 0 && (
        <Button
          size="medium"
          color="opa"
          to={generatePath(FOOTBALL_PLAY_INVENTORY_REVEALABLE_CARDS_TYPE, {
            type: Rarity.limited,
          })}
          onClick={onClick}
        >
          <Cards />
          <LabelL as="span" bold>
            <FormattedNumber value={totalBlueprints} />
          </LabelL>
        </Button>
      )}
      <Button
        size="medium"
        color="opa"
        to={generatePath(FOOTBALL_PLAY_INVENTORY_BOXES_TYPE, {
          type: Rarity.limited,
        })}
        onClick={onClick}
      >
        <Box />
        <LabelL as="span" bold>
          <FormattedNumber
            value={
              currentUser?.unopenedBoxesOfFootball.nodes.filter(b => !b.opened)
                .length || 0
            }
          />
        </LabelL>
      </Button>
      <Button
        size="medium"
        color="opa"
        to={generatePath(FOOTBALL_PLAY_INVENTORY_SHARDS_TYPE, {
          type: chestWithThresholdUnlocked?.rarity || Rarity.limited,
        })}
        onClick={onClick}
      >
        <MUIBadge variant="dot" invisible={!chestWithThresholdUnlocked}>
          <Shards />
        </MUIBadge>
        <LabelL as="span" bold>
          <FormattedNumber value={totalShards} />
        </LabelL>
      </Button>
    </>
  );
};
