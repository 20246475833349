import { Reference, TypePolicies } from '@apollo/client';
import { SafeReadonly } from '@apollo/client/cache/core/types/common';
import { mergeDeep } from '@apollo/client/utilities';

import { defaultTypePolicies } from '__generated__/defaultTypePolicies';
import {
  BaseballCollectionConnection,
  NBACollectionConnection,
} from '__generated__/globalTypes';
import { scalarTypePolicies } from '__generated__/typePolicies';
import { cardTypes } from 'lib/cards';
import { mergeArrayOfUnnormalizedObjects, replaceByIncoming } from 'lib/gql';

import mySo5LineupsPaginated from './mySo5LineupsPaginated';

type WithRefNodes<T> = SafeReadonly<
  Omit<T, 'nodes'> & {
    nodes: Reference[];
  }
>;

// TODO(haoliang): consolidate similar usage as to what's being done for:
// getPositionInitials() in baseball/src/components/onboarding/utils.tsx
const selectionIndexPositionArg = (index: number): string => {
  switch (true) {
    case index <= 2:
      return 'sp';
    case index <= 3:
      return 'rp';
    case index <= 5:
      return 'ci';
    case index <= 7:
      return 'mi';
    default:
      return 'of';
  }
};

/* eslint sort-keys: "error" */
export const typePolicies: TypePolicies = mergeDeep(
  defaultTypePolicies,
  {
    Age: {
      merge: true,
    },
    BaseballCollection: {
      fields: {
        bestByScore: {
          merge(
            existing: WithRefNodes<BaseballCollectionConnection> | undefined,
            incoming: WithRefNodes<BaseballCollectionConnection>
          ): WithRefNodes<BaseballCollectionConnection> {
            const refs = new Set(existing?.nodes.map(({ __ref }) => __ref));
            return {
              ...incoming,
              nodes: [
                ...(existing?.nodes ?? []),
                ...incoming.nodes.filter(({ __ref }) => !refs.has(__ref)),
              ],
            };
          },
        },
      },
      keyFields: ['id', 'slug'],
    },
    BaseballCommonDraftAutofillSuggestionsResponse: {
      keyFields: false,
    },
    BaseballCommonDraftConfig: {
      fields: {
        baseballCommonDraftCardSamples: {
          keyArgs: args => {
            const exclude = ['selectedPlayerSlugs', 'first', 'after'];

            return Object.entries(args!)
              .reduce((acc, entry) => {
                const [k, v] = entry;

                if (exclude.includes(k)) {
                  return acc;
                }

                if (k === 'selectionIndex') {
                  acc.push(selectionIndexPositionArg(v));
                } else {
                  acc.push(v);
                }

                return acc;
              }, [] as any[])
              .join(',');
          },
          merge(
            existing: { nodes: { __ref: string }[] } = { nodes: [] },
            incoming: { nodes: { __ref: string }[] }
          ) {
            const existingRefs = existing.nodes.map(
              // eslint-disable-next-line no-underscore-dangle
              node => node.__ref
            );
            return {
              ...incoming,
              nodes: [
                ...existing.nodes,
                ...incoming.nodes.filter(
                  // eslint-disable-next-line no-underscore-dangle
                  node => !existingRefs.includes(node.__ref)
                ),
              ],
            };
          },
        },
        commonDraftAutofillSuggestions: {
          keyArgs: ['selectedPlayersSlugs'],
          merge: true,
        },
      },

      // Singleton object
      // cf https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-cache-ids
      keyFields: false,
      merge: true,
    },
    BaseballFixture: {
      fields: {
        leaderboards: {
          merge(_, incoming: any[] = []) {
            return [...incoming];
          },
        },
      },
      keyFields: ['slug'],
    },
    BaseballLeaderboard: {
      fields: {
        myLineups: {
          merge(_, incoming: any[] = []) {
            return [...incoming];
          },
        },
        prizePool: {
          merge: true,
        },
        // Fix apollo merge
        // https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
        requirements: {
          merge: true,
        },
      },
      keyFields: ['slug'],
    },
    BaseballLineup: {
      fields: {
        cards: {
          merge(existing = [], incoming, { mergeObjects }) {
            // CardInLineup does not have a ref
            // and CardInLineup.playerInFixture does not have a ref
            // and CardInLineup.playerInFixture.status does not have a rf
            // To merge Lineup objects effectively in the cache, we need to
            // do a deep merge, otherwise Apollo does not know what to do
            // and overrides everything with the latest object, causing
            // redundant and conflicting fetches, potentially nested loops
            const merged = [...incoming].map((incomingCardObject, i) => {
              const existingCardObject = existing[i] || {};
              const mergedCardObject = {
                ...mergeObjects(existingCardObject, incomingCardObject),
                playerInFixture: {
                  ...mergeObjects(
                    existingCardObject.playerInFixture || {},
                    incomingCardObject.playerInFixture || {}
                  ),
                  status: {
                    ...mergeObjects(
                      existingCardObject.playerInFixture?.status || {},
                      incomingCardObject.playerInFixture?.status || {}
                    ),
                  },
                },
              };
              return mergedCardObject;
            });
            return merged;
          },
        },
        projectedReward: {
          merge: true,
        },
      },
      keyFields: ['id'],
    },
    BaseballSubmitCommonDraftResponse: {
      keyFields: false,
    },
    Card: {
      fields: {
        availableCardBoosts: {
          merge: false,
        },
      },
      keyFields: ['slug'],
    },
    CardBoost: {
      keyFields: ['shopItem', ['id']],
    },
    CardCount: {
      merge: true,
    },
    CurrentUser: {
      fields: {
        baseballCardCounts: { merge: true },
        baseballCurrentUserData: { merge: true },
        baseballUnclaimedBoxRewards: { merge: false },
        baseballUnclaimedCardRewards: { merge: false },
        baseballUnclaimedCashRewards: { merge: false },
        baseballUnclaimedLineupRewards: { merge: false },
        cardShardsChests: {
          keyArgs: ['sport'],
          merge: replaceByIncoming,
        },
        connectedOauths: {
          merge: replaceByIncoming,
        },
        footballCards: {
          keyArgs: args =>
            Object.keys(args || {}).filter(
              arg => arg !== 'ownedSinceAfter' && arg !== 'first'
            ),
        },
        managerProgressionTasksCount: {
          keyArgs: ['sport', 'type', 'state'],
        },
        nbaCardCounts: { merge: true },
        nbaUnclaimedCardRewards: { merge: false },
        nbaUnclaimedCashRewards: { merge: false },
        nbaUnclaimedConversionCreditRewards: { merge: false },
        nbaUnclaimedLineupRewards: { merge: false },
        refereeRewards: {
          merge: replaceByIncoming,
        },
        referrals: {
          merge: replaceByIncoming,
        },
        unclaimedActionRewards: {
          merge: replaceByIncoming,
        },
        unclaimedSo5Rewards: {
          keyArgs: ['sport'],
          merge: replaceByIncoming,
        },
        unopenedProbabilisticBundles: {
          keyArgs: ['sport'],
        },
        userSettings: {
          merge: true,
        },
      },
      keyFields: ['slug'],
    },
    FootballRivalsCurrentManager: {
      fields: { unreadStories: { merge: replaceByIncoming } },
    },
    FootballRivalsDivisionLeaderboard: {
      fields: { leaderboardConfig: { merge: true } },
    },
    FootballRivalsGame: {
      fields: {
        myFriendsPlaying: { merge: replaceByIncoming },
        userGroupLineups: { merge: true },
      },
      keyFields: ['slug'],
    },
    FootballRoot: {
      keyFields: false,
      merge: true,
    },
    ForYouRoot: {
      keyFields: false,
      merge: true,
    },
    LeaderboardInterface: {
      fields: {
        myComposeLineupCards: {
          keyArgs: [
            'query',
            'lineupid',
            'includeUsed',
            'indexInLineup',
            'cardsInLineupPartial',
            'includeOverTenGameAverageTotalLimit',
          ],
        },
        myLineups(lineups, { canRead }) {
          return lineups ? lineups.filter(canRead) : [];
        },
        // Fix apollo merge
        // https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
        requirements: {
          merge: true,
        },
      },
      keyFields: ['slug'],
    },
    LeaderboardRewardsConfig: {
      fields: {
        conditional: {
          merge: mergeArrayOfUnnormalizedObjects,
        },
        ranking: {
          merge: mergeArrayOfUnnormalizedObjects,
        },
      },
      keyFields: false,
    },
    League: {
      fields: {
        members: {
          keyArgs: false,
          merge: (existing: any[], incoming: any[], _a) => {
            const args = { limit: 100, offset: 0, ...(_a.args || {}) };
            const merged = [...(existing || [])];
            const { offset } = args;
            incoming.forEach((member, index) => {
              merged[offset + index] = member;
            });
            return merged;
          },
        },
      },
      keyFields: ['slug'],
    },
    LeagueLeaderboard: {
      fields: {
        lineups: {
          keyArgs: false,
          merge: (existing: any[], incoming: any[], _a) => {
            const args = { limit: 100, offset: 0, ...(_a.args || {}) };
            const merged = [...(existing || [])];
            const { offset } = args;
            incoming.forEach((member, index) => {
              merged[offset + index] = member;
            });
            return merged;
          },
        },
      },
      keyFields: ['leaderboard', ['slug'], 'league', ['slug']],
    },
    MangopayRoot: {
      fields: {
        bankAccountType: {
          keyArgs: ['countryCode'],
        },
        ownerRegionRequired: {
          keyArgs: ['countryCode'],
        },
      },
    },
    NBACollection: {
      fields: {
        bestByScore: {
          merge(
            existing: WithRefNodes<NBACollectionConnection> | undefined,
            incoming: WithRefNodes<NBACollectionConnection>
          ): WithRefNodes<NBACollectionConnection> {
            const refs = new Set(existing?.nodes.map(({ __ref }) => __ref));
            return {
              ...incoming,
              nodes: [
                ...(existing?.nodes ?? []),
                ...incoming.nodes.filter(({ __ref }) => !refs.has(__ref)),
              ],
            };
          },
        },
      },
      keyFields: ['id'],
    },
    NBAFixture: {
      fields: {
        leaderboards: {
          merge(_, incoming: any[] = []) {
            return [...incoming];
          },
        },
        myLineups: {
          merge(existing, incoming: any[] = []) {
            return [...incoming];
          },
        },
        playerFixtureStats: {
          keyArgs: ['hideUnownedPlayers', 'order'],
          merge(
            existing: { nodes: { player: Reference }[] } = { nodes: [] },
            incoming: { nodes: { player: Reference }[] }
          ) {
            const existingRefs = existing.nodes.map(
              // eslint-disable-next-line no-underscore-dangle
              node => node.player.__ref
            );
            const result = {
              ...incoming,
              nodes: [
                ...existing.nodes,
                ...incoming.nodes.filter(
                  // eslint-disable-next-line no-underscore-dangle
                  node => !existingRefs.includes(node.player.__ref)
                ),
              ],
            };
            return result;
          },
        },
      },
      keyFields: ['slug'],
    },
    NBALeaderboard: {
      fields: {
        beginnerLeaderboardDetails: {
          merge: true,
        },
        myLineups: {
          merge(_existing, incoming: any[] = []) {
            return [...incoming];
          },
        },
        prizePool: {
          merge: true,
        },
        // Fix apollo merge
        // https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
        requirements: {
          merge: true,
        },
      },
      keyFields: ['slug'],
    },
    NBALineup: {
      fields: {
        cards: {
          merge(existing = [], incoming, { mergeObjects }) {
            // CardInLineup does not have a ref
            // and CardInLineup.playerInFixture does not have a ref
            // and CardInLineup.playerInFixture.status does not have a rf
            // To merge Lineup objects effectively in the cache, we need to
            // do a deep merge, otherwise Apollo does not know what to do
            // and overrides everything with the latest object, causing
            // redundant and conflicting fetches, potentially nested loops
            const merged = [...incoming].map((incomingCardObject, i) => {
              const existingCardObject = existing[i] || {};
              const mergedCardObject = {
                ...mergeObjects(existingCardObject, incomingCardObject),
                playerInFixture: {
                  ...mergeObjects(
                    existingCardObject.playerInFixture || {},
                    incomingCardObject.playerInFixture || {}
                  ),
                  status: {
                    ...mergeObjects(
                      existingCardObject.playerInFixture?.status || {},
                      incomingCardObject.playerInFixture?.status || {}
                    ),
                  },
                },
              };
              return mergedCardObject;
            });
            return merged;
          },
        },
        projectedReward: {
          merge: true,
        },
      },
      keyFields: ['id'],
    },
    NBARivals: {
      merge: true,
    },
    Player: {
      fields: {
        cardSupply: { merge: mergeArrayOfUnnormalizedObjects },
      },
      keyFields: ['slug'],
    },
    PlayerInjury: {
      merge: true,
    },
    Query: {
      fields: {
        anyCard: {
          read(existing, { args, toReference, canRead }) {
            if (existing) return existing;
            if (!args?.slug) return undefined;

            const footballCardRef = toReference({
              __typename: cardTypes.FOOTBALL,
              slug: args.slug,
            });
            const baseballCardRef = toReference({
              __typename: cardTypes.BASEBALL,
              slug: args.slug,
            });
            const nbaCardRef = toReference({
              __typename: cardTypes.NBA,
              slug: args.slug,
            });

            return [footballCardRef, baseballCardRef, nbaCardRef].find(ref =>
              canRead(ref)
            );
          },
        },
      },
    },
    Referral: {
      fields: {
        referrerRewards: {
          merge: replaceByIncoming,
        },
      },
    },
    ReferralPaginated: {
      keyFields: false,
    },
    RewardsOverview: {
      fields: {
        experiencesDetails: {
          read(value) {
            return value || [];
          },
        },
      },
      keyFields: false,
    },
    Season: {
      keyFields: ['startYear'],
    },
    So5Fixture: {
      fields: { mySo5LineupsPaginated },
      keyFields: ['slug'],
    },
    So5Leaderboard: {
      fields: {
        canCompose: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
        mySo5Lineups: {
          merge: mergeArrayOfUnnormalizedObjects,
        },
        rewardsConfig: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
        totalRewards: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
      },
      keyFields: ['slug'],
    },
    So5League: {
      fields: {
        mySo5LeaderboardContenders: { merge: replaceByIncoming },
      },
    },
    So5Ranking: {
      fields: {
        eligibleRewards: {
          merge: mergeArrayOfUnnormalizedObjects,
        },
      },
    },
    So5Root: {
      keyFields: false,
      merge: true,
    },
    SocialPictureDerivatives: {
      merge(existing, incoming, { mergeObjects }) {
        return mergeObjects(existing, incoming);
      },
    },
    Token: {
      keyFields: ['assetId'],
    },
    TokenRoot: {
      merge: true,
    },
    UserBaseballCollection: {
      keyFields: ['collection', ['id']],
    },
  } as TypePolicies,
  scalarTypePolicies
);
