import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { Cost } from '@sorare/core/src/components/player/Cost';
import { isType } from '@sorare/core/src/gql';

import USSportsCardProperties from 'components/card/USSports/CardProperties';

import CardBonus from '../CardBonus';
import { IneligibleIndicator } from './IneligibleIndicator';
import { NBACardProperties_NBACard } from './__generated__/index.graphql';

type Props = {
  card: NBACardProperties_NBACard;
  withTransferMalus?: boolean;
  extras?: ReactNode;
  stats?: ReactNode;
  supensionsAndInjuries?: ReactNode;
};

export const NBACardProperties = ({
  card,
  withTransferMalus = false,
  extras,
  ...rest
}: Props) => {
  const { anyPlayer } = card;
  if (!isType(anyPlayer, 'NBAPlayer')) return null;
  return (
    <USSportsCardProperties
      {...rest}
      score={anyPlayer.tenGameAverageGameStats.score}
      renderScore={score => <Cost score={score} />}
      scoreTootip={
        <FormattedMessage
          id="USSportsCardProperties.averageScore.ten"
          defaultMessage="Average score over the last 10 games"
        />
      }
      bonusNode={
        <CardBonus card={card} withTransferMalus={withTransferMalus} />
      }
      extra={
        <Horizontal center>
          {extras}
          <IneligibleIndicator player={anyPlayer} />
        </Horizontal>
      }
    />
  );
};

NBACardProperties.fragments = {
  NBACard: gql`
    fragment NBACardProperties_NBACard on NBACard {
      slug
      powerMalusAfterTransfer
      anyPlayer {
        slug
        ... on NBAPlayer {
          slug
          # eslint-disable-next-line @graphql-eslint/no-deprecated
          tenGameAverageGameStats {
            score
          }
          ...IneligibleIndicator_NBAPlayer
        }
      }
      ...CardBonus_anyCard
    }
    ${IneligibleIndicator.fragments.NBAPlayer}
    ${CardBonus.fragments.anyCard}
  ` as TypedDocumentNode<NBACardProperties_NBACard>,
};

export default NBACardProperties;
