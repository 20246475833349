import { faClock } from '@fortawesome/pro-solid-svg-icons';
import { ReactNode } from 'react';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import styled from 'styled-components';

import { FontAwesomeIcon } from 'atoms/icons';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { Caption } from 'atoms/typography';
import { fantasy, playerGameStatusLabels } from 'lib/glossary';

const Bar = styled.div`
  transition: height ease-in-out 0.3s;
  height: calc(
    max(
      var(--max-bar-height) * (var(--score) / var(--max-score)),
      calc(var(--figure-height) / 2)
    )
  );
  background: linear-gradient(
    180deg,
    var(--background-color, var(--c-neutral-500)) 0%,
    var(--c-neutral-100) 100%
  );
  width: 100%;
  border-radius: var(--half-unit) var(--half-unit) 0 0;
  position: relative;
  mask: linear-gradient(rgba(0, 0, 0, 0.8) 40px, transparent);
`;

const Box = styled(Vertical).attrs({ gap: 0, center: true })`
  height: var(--max-bar-height);
  justify-content: flex-end;
`;

const Wrapper = styled.div`
  --game-width: 28px;
  width: var(--game-width);

  --team-size: var(--quadruple-unit);
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: var(--triple-unit);
  &[role='button'] {
    cursor: pointer;
    &:hover {
      ${Bar} {
        mask: linear-gradient(rgba(0, 0, 0, 0.9) 40px, transparent);
      }
    }
  }
`;

const BarContainer = styled.div`
  width: 100%;
  position: relative;
`;

const NegativeBar = styled.div`
  height: calc(
    max(
      var(--max-bar-height) * (-1 * var(--score) / var(--max-score)),
      calc(var(--figure-height) / 2)
    )
  );
  background: linear-gradient(
    180deg,
    var(--c-neutral-100),
    var(--background-color, var(--c-neutral-500))
  );
  width: 100%;
  border-radius: var(--half-unit);
  position: relative;
`;
const Score = styled.div`
  text-align: center;
  position: absolute;
  color: var(--c-static-neutral-1000);
  left: 0;
  right: 0;
  font: var(--t-bold) var(--t-14);
  border-radius: var(--half-unit);
  height: var(--figure-height);
`;

const PositiveScore = styled(Score)`
  top: calc(-0.5 * var(--figure-height));
  background-color: var(--background-color, var(--c-neutral-500));
`;

const NegativeScore = styled(Score)`
  bottom: calc(-0.5 * var(--figure-height));
  background-color: var(--background-color, var(--c-neutral-500));
`;

const LogoWrapper = styled(Horizontal).attrs({ gap: 0, center: true })`
  height: var(--team-size);
  margin-top: var(--logo-spacing, var(--unit));
  margin-bottom: var(--logo-spacing, var(--unit));
`;

const DNPLabelWrapper = styled.span`
  display: flex;
  flex-direction: column;
  gap: var(--half-unit);
  margin-bottom: var(--half-unit);
  align-items: center;
`;

export type Game = {
  id: string;
  playerScore?: number;
  upcoming?: boolean;
  gameLabel?: ReactNode;
  startDate: Date;
  color?: string;
  barLabel?: MessageDescriptor;
  dnpLabel?: ReactNode;
  onClick?: () => void;
};

type Props = {
  game: Game;
};

const FixtureScoreBar = ({ game }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <Wrapper
      key={game.id}
      onClick={game.onClick}
      {...(game.onClick ? { role: 'button' } : {})}
    >
      {game.playerScore === undefined && (
        <>
          <Box>
            <Caption bold color="var(--c-neutral-600)">
              {game.upcoming ? (
                <FontAwesomeIcon
                  icon={faClock}
                  aria-label={formatMessage(fantasy.upcoming)}
                />
              ) : (
                <DNPLabelWrapper>
                  {game.dnpLabel || (
                    <FormattedMessage
                      {...playerGameStatusLabels.did_not_play_short}
                    />
                  )}
                </DNPLabelWrapper>
              )}
            </Caption>
          </Box>
          <LogoWrapper>{game.gameLabel}</LogoWrapper>
        </>
      )}
      {game.playerScore !== undefined &&
        (game.playerScore >= 0 ? (
          <>
            <Box>
              <BarContainer
                aria-label={game.barLabel ? formatMessage(game.barLabel) : ''}
                style={
                  {
                    '--score': game.playerScore,
                    '--background-color': game.color,
                  } as React.CSSProperties
                }
              >
                <Bar />
                <PositiveScore>{Math.floor(game.playerScore)}</PositiveScore>
              </BarContainer>
            </Box>
            {game.gameLabel && <LogoWrapper>{game.gameLabel}</LogoWrapper>}
          </>
        ) : (
          <>
            <Box />
            <LogoWrapper>{game.gameLabel}</LogoWrapper>
            <BarContainer
              aria-label={game.barLabel ? formatMessage(game.barLabel) : ''}
              style={
                {
                  '--score': game.playerScore,
                  '--background-color': game.color,
                  marginBottom: 'calc(0.5 * var(--figure-height))',
                } as React.CSSProperties
              }
            >
              <NegativeBar />
              <NegativeScore>{Math.round(game.playerScore)}</NegativeScore>
            </BarContainer>
          </>
        ))}
    </Wrapper>
  );
};

export default FixtureScoreBar;
