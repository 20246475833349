import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import styled, { CSSProperties, keyframes } from 'styled-components';

import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

import { lineupLayout } from 'lib/styles';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }`;

const slideIn = keyframes`
  from {
    transform: translateY(calc(var(--y, 0%) + 10%));
    opacity: 0;
  }
  to {
    transform: translateY(var(--y, 0%));
    opacity: 1;
  }
`;

const Root = styled.div`
  --delay: 0s;
  ${lineupLayout}

  > * {
    margin: var(--quadruple-unit) min(4%, var(--quadruple-unit));
    opacity: 0;

    &.withProperties {
      max-width: calc(9 * var(--unit));
      margin: var(--intermediate-unit) min(4%, var(--quadruple-unit));
      @media ${tabletAndAbove} {
        margin: var(--double-unit) min(4%, var(--quadruple-unit));
        max-width: 145px;
      }
    }
    /* HACK animation-fill-mode forwards breaks CSS variables on Webkit browsers
       We need to split the animation into two parts
     */
    animation:
      ${slideIn} 0.3s ease-in-out none var(--delay),
      ${fadeIn} 0.3s ease-in-out forwards var(--delay);

    &:nth-child(2) {
      animation-delay: calc(0.1s + var(--delay)), calc(0.1s + var(--delay));
    }
    &:nth-child(3) {
      animation-delay: calc(0.15s + var(--delay)), calc(0.15s + var(--delay));
    }

    &:nth-child(4) {
      animation-delay: calc(0.2s + var(--delay)), calc(0.2s + var(--delay));
    }
    &:nth-child(5) {
      animation-delay: calc(0.25s + var(--delay)), calc(0.25s + var(--delay));
    }
  }
  &.compactOnMobile > * {
    margin: var(--double-unit) min(4%, var(--quadruple-unit));
    @media ${tabletAndAbove} {
      margin: var(--quadruple-unit) min(4%, var(--quadruple-unit));
    }
  }
  @media (max-height: 650px) {
    flex-wrap: nowrap;
    overflow: auto;
    justify-content: start;
    padding: var(--double-unit) 0 var(--triple-unit);

    &::before,
    &::after {
      content: '';
      order: unset;
      width: unset;
      margin: auto;
    }

    /** > *:nth-child(n) is similar to > * but with less specificty, so we need to keep it  */
    > *:nth-child(n) {
      --y: 0;
      order: unset;
      margin: var(--double-unit);
      width: 20vmin;
      min-width: 100px;
    }
  }
  flex-grow: 1;
`;

type Props = {
  noDelay?: boolean;
  className?: string;
  compactOnMobile?: boolean;
};
const CardsLayout = ({
  noDelay,
  className,
  compactOnMobile,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <Root
      style={noDelay ? ({ '--delay': '0s' } as CSSProperties) : undefined}
      className={classNames(className, { compactOnMobile })}
    >
      {children}
    </Root>
  );
};

export default CardsLayout;
