import { useIntl } from 'react-intl';

import { Color } from 'atoms/buttons/Button';
import IconButton from 'atoms/buttons/IconButton';
import { useCurrentUserContext } from 'contexts/currentUser';
import useToggleSubscription from 'hooks/subscriptions/useToggleSubscription';
import { glossary } from 'lib/glossary';

import FavoriteCardIcon from '../FavoriteHeartIcon';

interface Props {
  currentUserSubscription?: { slug: string } | null;
  subscribable: { __typename: string; slug: string };
  small?: boolean;
  color?: Color;
  solid?: boolean;
  onAddToFavoritesSuccess?: () => void;
  onToggled?: () => void;
}

export const FavoriteHeartButton = ({
  currentUserSubscription,
  subscribable,
  small,
  color = 'transparent',
  solid = true,
  onAddToFavoritesSuccess = () => {},
  onToggled,
}: Props) => {
  const { formatMessage } = useIntl();
  const { currentUser } = useCurrentUserContext();

  const toggleSubscription = useToggleSubscription(
    currentUserSubscription,
    subscribable,
    onToggled
  );

  if (!currentUser) return null;

  return (
    <IconButton
      small={small}
      color={color}
      onClick={() => {
        toggleSubscription();

        if (!currentUserSubscription) {
          onAddToFavoritesSuccess();
        }
      }}
      aria-label={formatMessage(
        currentUserSubscription ? glossary.unfollow : glossary.follow
      )}
    >
      <FavoriteCardIcon
        hasCurrentUserSubscription={!!currentUserSubscription}
        solid={solid}
      />
    </IconButton>
  );
};

export default FavoriteHeartButton;
