import { TypedDocumentNode, gql } from '@apollo/client';

import {
  CardQuality,
  CustomRewardExperience,
  IRLPrizeType,
  PaymentCurrency,
  Rarity,
  Rates,
  So5LeaderboardType,
} from '__generated__/globalTypes';
import { FRONTEND_ASSET_HOST } from 'constants/assets';
import { Selection, TreeSchema } from 'routing/Tree';

import {
  getRankSimpleRewards_so5RewardConfig,
  getScoreSimpleRewards_so5RewardConfig,
  getTreeSchemaFromSo5Fixture_so5Fixture,
  getTreeSelection_so5LeaderboardGroupInterface,
  rewardsOverviewToSimpleRewards_rewardsOverview,
  so5RewardConfigToFlattenRewards_so5RewardConfig,
  so5RewardConfigsToSimpleRewards_so5RewardConfig,
  so5RewardToFlattenReward_so5Reward,
  so5RewardsToSimpleRewards_so5Reward,
} from './__generated__/rewards.graphql';
import { groupBy } from './arrays';
import { scarcityNames } from './cards';
import { withFragments } from './gql';
import MonetaryAmount from './monetaryAmount';
import { qualityNames } from './players';
import { sortByRarity } from './scarcity';

export const getUnclaimedRewards = (so5Fixture: {
  mySo5Rewards: { aasmState: string }[];
}) => {
  return so5Fixture.mySo5Rewards.filter(r => r.aasmState === 'ready');
};

export type Experiences = { type?: CustomRewardExperience[] };

export type ExperienceType = CustomRewardExperience | IRLPrizeType;

export type RewardExperience = {
  type: IRLPrizeType | CustomRewardExperience;
  description: string;
  count: number;
};

export type TotalRewards = {
  prizePool: number;
  conversionCreditReward?: number;
  prizePoolCurrency: PaymentCurrency;
  cards: Json;
  experiences?: RewardExperience[] | null;
  coins?: number;
};

type CardsPerRarity = {
  [key in Rarity]?: number;
};

export interface CardsInRewards extends CardsPerRarity {
  rarities?: Rarity[];
}

export type CardReward = {
  rarity: Rarity;
  nb: number;
};

export const getCardRewards = (cards: CardsInRewards): CardReward[] => {
  if (!cards) {
    return [];
  }
  const result: CardReward[] = [];

  const raritiesFromKeys = Object.keys(cards).filter(
    k => k !== 'rarities'
  ) as Rarity[];

  const cardRarities = raritiesFromKeys.length
    ? raritiesFromKeys
    : (cards.rarities as Rarity[]);

  if (!cardRarities) {
    return result;
  }

  cardRarities.forEach(rarity => {
    result.push({ rarity, nb: cards[rarity] || 0 });
  });

  return result;
};

interface So5RewardWithCoin {
  __typename: 'So5Reward';
  coinAmount: number;
}

export const mergeCoinRewards = <T extends So5RewardWithCoin>(rewards: T[]) => {
  if (!rewards.length) {
    return [];
  }

  let totalCoinAmount = 0;
  const otherRewards: T[] = [];
  rewards.forEach(r => {
    if (r.coinAmount > 0) {
      totalCoinAmount += r.coinAmount;
    } else {
      otherRewards.push(r);
    }
  });

  const syntheticReward = { ...rewards[0], coinAmount: totalCoinAmount };

  return totalCoinAmount > 0
    ? [...otherRewards, syntheticReward]
    : otherRewards;
};

export const boxIconUrl = (rarity: Rarity) =>
  `${FRONTEND_ASSET_HOST}/football/probabilistic_bundles/rarities/${rarity}/icon.png`;

export const shardsIconUrl = (rarity: Rarity) =>
  `${FRONTEND_ASSET_HOST}/football/shards/${rarity}.png`;

export enum RewardType {
  CARD = 'CARD',
  CASH = 'CASH',
  PROBABILISTIC_BUNDLE = 'PROBABILISTIC_BUNDLE',
  COIN = 'COIN',
  CREDIT = 'CREDIT',
  ARENA_TICKET = 'ARENA_TICKET',
  SHARD = 'SHARD',
}

export type RewardWithExperiencesType =
  | CustomRewardExperience
  | IRLPrizeType
  | RewardType;

export type SimpleReward = {
  type: RewardWithExperiencesType;
  rarity: Rarity | null;
  quality?: CardQuality | null;
  count: number;
  title?: Nullable<string>;
  description?: Nullable<string>;
};

type FlattenRewards = {
  cash?: SimpleReward | null;
  coins?: SimpleReward | null;
  credits?: SimpleReward | null;
  cards?: SimpleReward[] | null;
  boxes?: SimpleReward[] | null;
  jerseys?: SimpleReward[] | null;
  experiences?: SimpleReward[] | null;
  merch?: SimpleReward[] | null;
  tickets?: SimpleReward[] | null;
  arenaTickets?: SimpleReward | null;
  shards?: SimpleReward | null;
};

export const flattenRewardsToSimpleRewards = (
  flattenRewards: FlattenRewards
): SimpleReward[] => {
  return [
    ...(flattenRewards.experiences || []),
    flattenRewards.cash?.count &&
      flattenRewards.cash?.count >= 3500 &&
      flattenRewards.cash,
    ...(flattenRewards.jerseys || []),
    ...(flattenRewards.tickets || []),
    ...(flattenRewards.cards || []),
    flattenRewards.cash?.count &&
      flattenRewards.cash?.count < 3500 &&
      flattenRewards.cash,
    flattenRewards.credits,
    ...(flattenRewards.merch || []),
    ...(flattenRewards.boxes || []),
    flattenRewards.shards,
    flattenRewards.arenaTickets,
    flattenRewards.coins,
  ].filter(Boolean);
};

export const rewardsOverviewToSimpleRewards = withFragments(
  ({
    rewardsOverview,
    rarity,
    onePerCategoryOnly,
  }: {
    rewardsOverview: rewardsOverviewToSimpleRewards_rewardsOverview;
    rarity: Rarity | null;
    onePerCategoryOnly?: boolean;
  }): SimpleReward[] => {
    const cash =
      rewardsOverview.prizePool > 0
        ? {
            type: RewardType.CASH,
            count: rewardsOverview.prizePool,
            rarity,
          }
        : null;

    const cardRewards = getCardRewards(rewardsOverview.cards as CardsInRewards);
    const cards = sortByRarity(
      cardRewards.map(cardReward => ({
        type: RewardType.CARD,
        count: cardReward.nb,
        rarity: cardReward.rarity,
      })),
      cardReward => cardReward.rarity
    ).reverse();

    const boxes = sortByRarity(
      rewardsOverview.probabilisticBundles.rarities.map(
        ({ rarity: boxRarity, count }) => ({
          type: RewardType.PROBABILISTIC_BUNDLE,
          count,
          rarity: boxRarity,
        })
      ),
      pb => pb.rarity
    );

    const jerseys =
      rewardsOverview.deliverableItems.jersey > 0
        ? {
            type: CustomRewardExperience.JERSEY,
            count: rewardsOverview.deliverableItems.jersey,
            rarity,
          }
        : null;

    const experiences =
      rewardsOverview.deliverableItems.experience > 0
        ? {
            type: CustomRewardExperience.EVENT,
            count: rewardsOverview.deliverableItems.experience,
            rarity,
          }
        : null;

    const tickets =
      rewardsOverview.deliverableItems.tickets > 0
        ? {
            type: CustomRewardExperience.TICKET,
            count: rewardsOverview.deliverableItems.tickets,
            rarity,
          }
        : null;

    const merch =
      rewardsOverview.deliverableItems.wearable > 0
        ? {
            type: CustomRewardExperience.MERCH,
            count: rewardsOverview.deliverableItems.wearable,
            rarity,
          }
        : null;

    return flattenRewardsToSimpleRewards({
      cash,
      cards: onePerCategoryOnly ? cards.slice(0, 1) : cards,
      boxes: onePerCategoryOnly ? boxes.slice(0, 1) : boxes,
      jerseys: jerseys ? [jerseys] : null,
      tickets: tickets ? [tickets] : null,
      experiences: experiences ? [experiences] : null,
      merch: merch ? [merch] : null,
    });
  },
  {
    rewardsOverview: gql`
      fragment rewardsOverviewToSimpleRewards_rewardsOverview on RewardsOverview {
        cards
        probabilisticBundles {
          rarities {
            rarity
            count
          }
        }
        prizePool
        deliverableItems {
          jersey
          experience
          tickets
          wearable
        }
      }
    ` as TypedDocumentNode<rewardsOverviewToSimpleRewards_rewardsOverview>,
  }
);

export const so5RewardConfigToFlattenRewards = withFragments(
  ({
    so5RewardConfig,
    rarity,
    rates,
  }: {
    so5RewardConfig: so5RewardConfigToFlattenRewards_so5RewardConfig;
    rarity: Rarity | null;
    rates: Rates;
  }): FlattenRewards => {
    const cash = so5RewardConfig.usdAmount
      ? {
          type: RewardType.CASH,
          count: so5RewardConfig.usdAmount,
          rarity,
        }
      : undefined;

    const cards = sortByRarity(
      (so5RewardConfig.cards || []).map(
        ({ quantity, rarity: cardRarity, quality, title, description }) => ({
          type: RewardType.CARD,
          count: quantity,
          rarity: cardRarity,
          quality,
          title,
          description,
        })
      ),
      cardReward => cardReward.rarity
    ).reverse();

    const boxes = (so5RewardConfig.probabilisticBundleConfigs || []).map(
      pb => ({
        type: RewardType.PROBABILISTIC_BUNDLE,
        count: 1,
        rarity: pb.rarity,
      })
    );

    const deliverableItemsPerType = groupBy(
      so5RewardConfig.deliverableItems || [],
      item => item.deliverableItem?.__typename || ''
    );

    const {
      JerseyDeliverableItem: jerseys,
      WearableDeliverableItem: merch,
      TicketsDeliverableItem: tickets,
      ExperienceDeliverableItem: experiences,
    } = Object.fromEntries(
      Object.entries(deliverableItemsPerType).map(([type, items]) => {
        return [
          type,
          items.map(item => ({
            type: {
              JerseyDeliverableItem: CustomRewardExperience.JERSEY,
              WearableDeliverableItem: CustomRewardExperience.MERCH,
              TicketsDeliverableItem: CustomRewardExperience.TICKET,
              ExperienceDeliverableItem: CustomRewardExperience.EVENT,
            }[type],
            count: item.quantity,
            rarity,
          })),
        ];
      })
    ) as Record<keyof typeof deliverableItemsPerType, SimpleReward[]>;

    const extraExperiences = (so5RewardConfig.experiences || []).map(
      ({ type, title, description }) => ({
        type,
        count: 1,
        rarity,
        title,
        description,
      })
    );

    const coins = so5RewardConfig.coinAmount
      ? {
          type: RewardType.COIN,
          count: so5RewardConfig.coinAmount,
          rarity,
        }
      : undefined;

    const credits = so5RewardConfig.conversionCredit?.maxDiscount
      ? {
          type: RewardType.CREDIT,
          count:
            new MonetaryAmount(
              so5RewardConfig.conversionCredit?.maxDiscount
            ).inCurrencies(rates).usd / 100,
          rarity,
        }
      : undefined;
    const shards = so5RewardConfig.cardShards
      ? {
          type: RewardType.SHARD,
          count: so5RewardConfig.cardShards,
          rarity,
        }
      : undefined;

    const arenaTickets = so5RewardConfig.arenaTickets
      ? {
          type: RewardType.ARENA_TICKET,
          count: so5RewardConfig.arenaTickets,
          rarity,
        }
      : undefined;

    return {
      cash,
      cards,
      boxes,
      merch,
      tickets,
      experiences: [...(experiences || []), ...extraExperiences],
      jerseys,
      coins,
      credits,
      arenaTickets,
      shards,
    };
  },
  {
    so5RewardConfig: gql`
      fragment so5RewardConfigToFlattenRewards_so5RewardConfig on So5RewardConfig {
        coinAmount
        arenaTickets
        deliverableItems {
          deliverableItem {
            slug
            id
          }
          quantity
        }
        probabilisticBundleConfigs {
          id
          rarity
        }
        cards {
          quantity
          rarity
          quality
          title
          description
        }
        conversionCredit {
          maxDiscount {
            referenceCurrency
            eur
            gbp
            usd
            wei
          }
        }
        usdAmount
        experiences {
          type
          title
          description
        }
        cardShards
      }
    ` as TypedDocumentNode<so5RewardConfigToFlattenRewards_so5RewardConfig>,
  }
);

const aggregateFlattenRewards = (
  flattenRewardsArray: FlattenRewards[]
): FlattenRewards => {
  return flattenRewardsArray.reduce((acc, flattenRewards) => {
    (['cash', 'coins', 'credits', 'shards', 'arenaTickets'] as const).forEach(
      singleKey => {
        const existingValue = acc[singleKey];
        acc[singleKey] = existingValue
          ? {
              ...existingValue,
              count:
                existingValue.count + (flattenRewards[singleKey]?.count || 0),
            }
          : flattenRewards[singleKey];
      }
    );

    (
      ['tickets', 'merch', 'jerseys', 'experiences', 'cards', 'boxes'] as const
    ).forEach(listKey => {
      const existingSimpleRewards = acc[listKey] || [];

      flattenRewards[listKey]?.forEach(reward => {
        const matchingSimpleRewardIndex = existingSimpleRewards.findIndex(
          r =>
            r.type === reward.type &&
            r.rarity === reward.rarity &&
            r.quality === reward.quality
        );

        if (matchingSimpleRewardIndex >= 0) {
          existingSimpleRewards[matchingSimpleRewardIndex].count +=
            reward.count;
        } else {
          existingSimpleRewards.push(reward);
        }
      });

      const newList = sortByRarity(
        existingSimpleRewards,
        item => item.rarity as Rarity
      );
      acc[listKey] = listKey === 'cards' ? newList.reverse() : newList;
    });

    return acc;
  }, {});
};

export const so5RewardConfigsToSimpleRewards = withFragments(
  ({
    so5RewardConfigs,
    rarity,
    rates,
  }: {
    so5RewardConfigs: so5RewardConfigsToSimpleRewards_so5RewardConfig[];
    rarity: Rarity | null;
    rates: Rates;
  }): SimpleReward[] => {
    const flattenRewardsArray = so5RewardConfigs.map(config =>
      so5RewardConfigToFlattenRewards({
        so5RewardConfig: config,
        rarity,
        rates,
      })
    );

    const aggregatedFlattenRewards =
      aggregateFlattenRewards(flattenRewardsArray);

    return flattenRewardsToSimpleRewards(aggregatedFlattenRewards);
  },
  {
    so5RewardConfig: gql`
      fragment so5RewardConfigsToSimpleRewards_so5RewardConfig on So5RewardConfig {
        ...so5RewardConfigToFlattenRewards_so5RewardConfig
      }
      ${so5RewardConfigToFlattenRewards.fragments.so5RewardConfig}
    ` as TypedDocumentNode<so5RewardConfigsToSimpleRewards_so5RewardConfig>,
  }
);

const so5RewardToFlattenReward = withFragments(
  ({
    so5Reward,
    rarity,
    rates,
  }: {
    so5Reward: so5RewardToFlattenReward_so5Reward;
    rarity: Rarity | null;
    rates: Rates;
  }): FlattenRewards => {
    const coins =
      so5Reward.coins > 0
        ? {
            type: RewardType.COIN,
            count: so5Reward.coins,
            rarity,
          }
        : undefined;

    const usdAmount = so5Reward.amount
      ? new MonetaryAmount(so5Reward.amount).inCurrencies(rates).usd / 100
      : 0;
    const cash =
      usdAmount > 0
        ? {
            type: RewardType.CASH,
            count: usdAmount,
            rarity,
          }
        : undefined;

    const creditUsdAmount = so5Reward.conversionCredit?.maxDiscount
      ? new MonetaryAmount(
          so5Reward.conversionCredit?.maxDiscount
        ).inCurrencies(rates).usd / 100
      : 0;
    const credits =
      creditUsdAmount > 0
        ? {
            type: RewardType.CREDIT,
            count: creditUsdAmount,
            rarity,
          }
        : undefined;

    const rewardCard = so5Reward.rewardCards[0];
    const card = rewardCard
      ? {
          type: RewardType.CARD,
          count: 1,
          rarity: rewardCard.anyCard?.rarityTyped
            ? rewardCard.anyCard.rarityTyped
            : rarity,
          quality:
            rewardCard.quality &&
            rewardCard.quality.toUpperCase() in CardQuality
              ? (rewardCard.quality.toUpperCase() as CardQuality)
              : undefined,
        }
      : undefined;

    const box = so5Reward.probabilisticBundle
      ? {
          type: RewardType.PROBABILISTIC_BUNDLE,
          count: 1,
          rarity: so5Reward.probabilisticBundle.config.rarity,
        }
      : undefined;

    const deliverableItemsPerType = groupBy(
      so5Reward.deliverableItems || [],
      item => item.deliverableItem?.__typename || ''
    );

    const {
      JerseyDeliverableItem: jerseys,
      WearableDeliverableItem: merch,
      TicketsDeliverableItem: tickets,
      ExperienceDeliverableItem: experiences,
    } = Object.fromEntries(
      Object.entries(deliverableItemsPerType).map(([type, items]) => {
        return [
          type,
          items.map(item => ({
            type: {
              JerseyDeliverableItem: CustomRewardExperience.JERSEY,
              WearableDeliverableItem: CustomRewardExperience.MERCH,
              TicketsDeliverableItem: CustomRewardExperience.TICKET,
              ExperienceDeliverableItem: CustomRewardExperience.EVENT,
            }[type],
            count: item.quantity,
            rarity,
          })),
        ];
      })
    ) as Record<keyof typeof deliverableItemsPerType, SimpleReward[]>;

    const firstDeliverableRewards = [experiences, jerseys, tickets, merch]
      .filter(Boolean)
      .find(arr => arr.length > 0);
    const deliverableItem = firstDeliverableRewards?.[0];

    return {
      cash,
      cards: card ? [card] : [],
      boxes: box ? [box] : [],
      merch,
      tickets,
      experiences: deliverableItem ? [deliverableItem] : [],
      jerseys,
      coins,
      credits,
    };
  },
  {
    so5Reward: gql`
      fragment so5RewardToFlattenReward_so5Reward on So5Reward {
        slug
        coins: coinAmount
        amount {
          referenceCurrency
          usd
          eur
          gbp
          wei
        }
        conversionCredit {
          maxDiscount {
            referenceCurrency
            usd
            eur
            gbp
            wei
          }
        }
        probabilisticBundle {
          id
          config {
            id
            rarity
          }
        }
        rewardCards {
          id
          quality
          anyCard {
            slug
            rarityTyped
          }
        }
        deliverableItems {
          quantity
          deliverableItem {
            slug
            id
          }
        }
      }
    ` as TypedDocumentNode<so5RewardToFlattenReward_so5Reward>,
  }
);

export const so5RewardsToSimpleRewards = withFragments(
  ({
    so5Rewards,
    rarity,
    rates,
  }: {
    so5Rewards: so5RewardsToSimpleRewards_so5Reward[];
    rarity: Rarity | null;
    rates: Rates;
  }): SimpleReward[] => {
    const flattenRewardsArray = so5Rewards.map(reward =>
      so5RewardToFlattenReward({
        so5Reward: reward,
        rarity,
        rates,
      })
    );

    const aggregatedFlattenRewards =
      aggregateFlattenRewards(flattenRewardsArray);

    return flattenRewardsToSimpleRewards(aggregatedFlattenRewards);
  },
  {
    so5Reward: gql`
      fragment so5RewardsToSimpleRewards_so5Reward on So5Reward {
        slug
        ...so5RewardToFlattenReward_so5Reward
      }
      ${so5RewardToFlattenReward.fragments.so5Reward}
    ` as TypedDocumentNode<so5RewardsToSimpleRewards_so5Reward>,
  }
);

export type WithRewardRange<T> = T & {
  startRank?: number | null;
  startPct?: number | null;
  endRank?: number | null;
  endPct?: number | null;
};

export const parseRankingRewards = <
  T extends {
    ranks?: number | null;
    rankPct?: number | null;
  },
>(
  rewards: T[] | null
): WithRewardRange<T>[] => {
  if (!rewards) return [];

  const rangeRewards = rewards.reduce((sum, cur) => {
    const prev = sum[sum.length - 1] || undefined;

    let rewardRange: WithRewardRange<T>;
    if (!prev) {
      rewardRange = {
        startRank: 1,
        endRank: cur.ranks || null,
        startPct: null,
        endPct: cur.rankPct || null,
        ...cur,
      };
    } else if (cur.ranks) {
      const startRank = prev.endRank ? prev.endRank + 1 : null;
      const endRank = prev.endRank ? prev.endRank + cur.ranks : null;

      rewardRange = {
        startRank,
        endRank,
        startPct: null,
        endPct: null,
        ...cur,
      };
    } else if (cur.rankPct) {
      const startRank = prev.endRank ? prev.endRank + 1 : null;
      const startPct = prev.endPct || null;

      const endPctBasedOnRank = prev.endRank ? cur.rankPct : null;
      const endPct = prev.endPct ? cur.rankPct : endPctBasedOnRank;

      rewardRange = {
        startRank,
        endRank: null,
        startPct,
        endPct,
        ...cur,
      };
    } else {
      return sum;
    }

    sum.push(rewardRange);
    return sum;
  }, [] as WithRewardRange<T>[]);

  return rangeRewards;
};

export const getRankSimpleRewards = withFragments(
  <T extends getRankSimpleRewards_so5RewardConfig>({
    so5RewardConfigs,
    rarity,
    rates,
  }: {
    so5RewardConfigs: T[];
    rarity: Rarity | null;
    rates: Rates;
  }): (WithRewardRange<T> & { simpleRewards: SimpleReward[] })[] => {
    const parsedRankings = parseRankingRewards(so5RewardConfigs);

    return parsedRankings.map(rankReward => {
      return {
        ...rankReward,
        simpleRewards: so5RewardConfigsToSimpleRewards({
          so5RewardConfigs: [rankReward],
          rarity,
          rates,
        }),
      };
    });
  },
  {
    so5RewardConfig: gql`
      fragment getRankSimpleRewards_so5RewardConfig on So5RewardConfig {
        ranks
        rankPct
        ...so5RewardConfigsToSimpleRewards_so5RewardConfig
      }
      ${so5RewardConfigsToSimpleRewards.fragments.so5RewardConfig}
    ` as TypedDocumentNode<getRankSimpleRewards_so5RewardConfig>,
  }
);

export const getScoreSimpleRewards = withFragments(
  <T extends getScoreSimpleRewards_so5RewardConfig>({
    so5RewardConfigs,
    rarity,
    rates,
  }: {
    so5RewardConfigs: T[];
    rarity: Rarity | null;
    rates: Rates;
  }): (T & { simpleRewards: SimpleReward[] })[] => {
    return so5RewardConfigs.map(scoreReward => ({
      ...scoreReward,
      simpleRewards: so5RewardConfigsToSimpleRewards({
        so5RewardConfigs: [scoreReward],
        rarity,
        rates,
      }),
    }));
  },
  {
    so5RewardConfig: gql`
      fragment getScoreSimpleRewards_so5RewardConfig on So5RewardConfig {
        score
        ...so5RewardConfigsToSimpleRewards_so5RewardConfig
      }
      ${so5RewardConfigsToSimpleRewards.fragments.so5RewardConfig}
    ` as TypedDocumentNode<getScoreSimpleRewards_so5RewardConfig>,
  }
);

export const getTreeSchemaFromSo5Fixture = withFragments(
  (so5Fixture: getTreeSchemaFromSo5Fixture_so5Fixture) => {
    const schema = so5Fixture.so5LeaderboardGroups
      .filter(so5LeaderboardGroup =>
        so5LeaderboardGroup.so5Leaderboards.every(
          so5Leaderboard =>
            so5Leaderboard.so5TournamentType.so5LeaderboardType !==
            So5LeaderboardType.SPECIAL_TRAINING_CENTER
        )
      )
      .reduce<{
        [key: string]: { [key: string]: string[] };
      }>((sum, cur) => {
        const cardRewards = cur.so5Leaderboards
          .flatMap(so5Leaderboard => [
            ...(so5Leaderboard.rewardsConfig.ranking || []),
            ...(so5Leaderboard.rewardsConfig.conditional || []),
          ])
          .flatMap(r => r.cards)
          .filter(Boolean);

        sum[cur.displayName] = Object.values(CardQuality).reduce(
          (acc, quality) => {
            const rarities = sortByRarity([
              ...new Set(
                cardRewards
                  .filter(rewards => rewards.quality === quality)
                  .map(rewards => rewards.rarity)
              ),
            ]).reverse();

            if (!rarities.length) {
              return acc;
            }

            return {
              ...acc,
              [qualityNames[quality]]: rarities.map(
                rarity => scarcityNames[rarity]
              ),
            };
          },
          {}
        );

        return sum;
      }, {});

    return schema;
  },
  {
    so5Fixture: gql`
      fragment getTreeSchemaFromSo5Fixture_so5Fixture on So5Fixture {
        slug
        so5LeaderboardGroups {
          slug
          displayName
          so5Leaderboards {
            slug
            so5TournamentType {
              id
              so5LeaderboardType
            }
            rewardsConfig {
              ranking {
                cards {
                  rarity
                  quality
                }
              }
              conditional {
                cards {
                  rarity
                  quality
                }
              }
            }
          }
        }
      }
    ` as TypedDocumentNode<getTreeSchemaFromSo5Fixture_so5Fixture>,
  }
);

export const getTreeSelection = withFragments(
  ({
    schema,
    so5LeaderboardGroupInterfaces,
    league,
    quality,
    rarity,
  }: {
    schema: TreeSchema;
    so5LeaderboardGroupInterfaces: getTreeSelection_so5LeaderboardGroupInterface[];
    league: string | undefined;
    quality: string | undefined;
    rarity: string | undefined;
  }): Selection => {
    const so5LeaderboardGroupInterface = so5LeaderboardGroupInterfaces.find(
      l => l.slug === league
    );

    const leagueKey =
      so5LeaderboardGroupInterface?.displayName || Object.keys(schema)[0];
    const qualityKey =
      quality && quality.toUpperCase() in qualityNames
        ? qualityNames[quality.toUpperCase() as keyof typeof qualityNames]
        : undefined;
    const rarityKey = rarity ? scarcityNames[rarity.toLowerCase()] : undefined;

    return [leagueKey, qualityKey, rarityKey];
  },
  {
    so5LeaderboardGroupInterface: gql`
      fragment getTreeSelection_so5LeaderboardGroupInterface on So5LeaderboardGroupInterface {
        slug
        displayName
      }
    ` as TypedDocumentNode<getTreeSelection_so5LeaderboardGroupInterface>,
  }
);
