import { TypedDocumentNode, gql } from '@apollo/client';

import {
  ClaimNBAReferralRewardsMutation,
  ClaimNBAReferralRewardsMutationVariables,
  NBAReferralRewardsQuery,
  NBAReferralRewardsQueryVariables,
  Nickname_usSportsUser,
} from './__generated__/queries.graphql';

export const NBA_REFERRAL_REWARDS_QUERY = gql`
  query NBAReferralRewardsQuery($referralIDs: [UUID!]!) {
    usSportReferralRewards: nbaReferralRewards(referralIDs: $referralIDs) {
      id
      state
      card {
        slug
        anyPositions
        rarityTyped
        anyPlayer {
          slug
          anyPositions
        }
        anyTeam {
          slug
          pictureUrl
        }
      }
    }
  }
` as TypedDocumentNode<
  NBAReferralRewardsQuery,
  NBAReferralRewardsQueryVariables
>;

export const CLAIM_NBA_REFERRAL_REWARDS_MUTATION = gql`
  mutation ClaimNBAReferralRewardsMutation($referralIDs: [UUID!]!) {
    claimNBAReferralRewards(referralIDs: $referralIDs) {
      id
      state
    }
  }
` as TypedDocumentNode<
  ClaimNBAReferralRewardsMutation,
  ClaimNBAReferralRewardsMutationVariables
>;

export const US_SPORTS_USER_FRAGMENTS = {
  nickname: gql`
    fragment Nickname_usSportsUser on User {
      nickname
      suspended
      slug
    }
  ` as TypedDocumentNode<Nickname_usSportsUser>,
};
